import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal as AntdModal, Space, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
const Modal = styled(AntdModal) `
	.ant-modal-body {
		padding: 24px 32px;
	}

	.ant-modal-footer {
		border-top: none;
		padding: 24px 32px;
	}
`;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${(props) => props.theme.colorWarning};
	margin-top: 2px;
	font-size: 22px;
`;
const Box = styled.div `
	padding: 16px;
	border: 1px solid rgba(0, 0, 0, 0.1);
`;
const SubmitToSignatureModal = ({ signatories, finalInterestRate, modalProps, }) => {
    const { t } = useTranslation();
    const { decimalToPercent } = useFormatter();
    return (_jsx(Modal, Object.assign({}, modalProps, { okText: t("common.button_label.submitPrice"), cancelText: t("dashboard.documents.cancel"), destroyOnClose: true, closable: false }, { children: _jsxs(Space, Object.assign({ align: "start" }, { children: [_jsx(ExclamationCircleOutlinedIcon, {}), _jsxs("div", { children: [_jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("common.messages.confirmPricingAndSignatories") })), _jsxs(Space, Object.assign({ direction: "vertical", size: 20, style: { width: "100%" } }, { children: [_jsx(Typography.Text, { children: t("common.messages.confirmPricingAndSignatoriesSubtitle") }), finalInterestRate && (_jsx(Box, { children: _jsxs(Space, Object.assign({ direction: "vertical", size: 2 }, { children: [_jsx(Typography.Text, { children: t("dashboard.loan_information.finalInterestRate") }), _jsx(Typography.Text, Object.assign({ strong: true }, { children: decimalToPercent({ percent: finalInterestRate, showPercentSymbol: true }) }))] })) })), _jsxs(Box, { children: [_jsx(Typography.Text, { children: t("dashboard.signature.signatories") }), _jsx("ol", Object.assign({ style: { padding: "10px 20px" } }, { children: signatories.map((signatory) => {
                                                var _a, _b;
                                                return (_jsxs("li", { children: [_jsx("strong", { children: `${signatory.first_name} ${signatory.last_name}` }), " ", t("dashboard.signature.toSign"), ": ", ((_a = signatory.requirements) === null || _a === void 0 ? void 0 : _a.is_loan_signature_required) &&
                                                            `${t("dashboard.signature.loanContract")}, `, ((_b = signatory.requirements) === null || _b === void 0 ? void 0 : _b.is_ubo_signature_required) &&
                                                            t("dashboard.signature.personalGuarantee")] }, signatory.signatory_id));
                                            }) }))] })] }))] })] })) })));
};
export default SubmitToSignatureModal;
