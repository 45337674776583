import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { App, Col, Form, Modal, Row, Space } from "antd";
import { ApplicationStatuses } from "@teylor-tools/Api";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";
import RepresentativeCard from "@ui/representative-card/RepresentativeCard";
import { Axios } from "src/utils/Axios";
import DeleteRepresentativeModal from "../modals/DeleteRepresentativeModal";
const TIN_FORM = "tin-form";
const CompanyRepresentatives = ({ applicationId, representatives, applicationStatus, onEdit, onUpdate, companyTin, }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [deleting, setDeleting] = useState(false);
    const [deleteRep, setDeleteRep] = useState();
    const [editRepresentativeTin, setEditRepresentativeTin] = useState();
    const [form] = Form.useForm();
    const showEditTinButton = [
        ApplicationStatuses.InternalReview,
        ApplicationStatuses.InternalPricing,
        ApplicationStatuses.ExternalPricing,
        ApplicationStatuses.Signature,
    ].includes(applicationStatus);
    const showCardExtra = [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(applicationStatus);
    const handleUpdateTin = ({ taxid }) => {
        if (!editRepresentativeTin)
            return;
        Axios.patch(`/partner/applications/${applicationId}/signatories/${editRepresentativeTin.signatory_id}`, Object.assign(Object.assign({}, editRepresentativeTin), { taxid: parseTin(taxid) })).then(() => {
            setEditRepresentativeTin(undefined);
            form.resetFields();
            void message.success(t("dashboard.overview.tin-added-successfully"));
            onUpdate();
        }, (err) => void Axios.error(err, t("common.errors.error-updating-tin")));
    };
    const deleteRepresentative = (repId) => {
        setDeleting(true);
        Axios.delete(`/partner/applications/${applicationId}/signatories/${repId}`)
            .then(onUpdate)
            .catch((err) => {
            void Axios.error(err, t("common.errors.deleteData"));
        })
            .finally(() => {
            setDeleteRep(undefined);
            setDeleting(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Row, Object.assign({ gutter: [24, 24] }, { children: representatives.map((rep) => (_jsx(Col, Object.assign({ xs: 24, md: 12 }, { children: _jsx(RepresentativeCard, { representative: rep, showExtra: showCardExtra, showEditTinButton: showEditTinButton, onDeleteRepresentative: () => setDeleteRep(rep), onEditRepresentative: () => onEdit(rep), onEditTin: () => setEditRepresentativeTin(rep) }) }), `rep-${rep.signatory_id}`))) })), _jsx(DeleteRepresentativeModal, { name: `${deleteRep === null || deleteRep === void 0 ? void 0 : deleteRep.first_name} ${deleteRep === null || deleteRep === void 0 ? void 0 : deleteRep.last_name}`, modalProps: {
                    open: !!deleteRep,
                    onCancel: () => setDeleteRep(undefined),
                    onOk: () => deleteRep && deleteRepresentative(deleteRep.signatory_id),
                    okButtonProps: { loading: deleting },
                    cancelButtonProps: { disabled: deleting },
                } }), _jsx(Modal, Object.assign({ title: (editRepresentativeTin === null || editRepresentativeTin === void 0 ? void 0 : editRepresentativeTin.taxid) ? t("common.editTin") : t("common.addTin"), open: !!editRepresentativeTin, onCancel: () => {
                    setEditRepresentativeTin(undefined);
                    form.resetFields();
                }, cancelText: t("dashboard.documents.cancel"), okText: t("common.button_label.save"), okButtonProps: { htmlType: "submit", form: TIN_FORM } }, { children: _jsx(Form, Object.assign({ id: TIN_FORM, form: form, layout: "vertical", onFinish: handleUpdateTin }, { children: _jsx(TinFormItem, { type: "personal", name: "taxid", initialValue: editRepresentativeTin === null || editRepresentativeTin === void 0 ? void 0 : editRepresentativeTin.taxid, rules: [
                            { required: true, message: t("common.errors.fieldRequired") },
                            {
                                validator: (_, value) => validateValueDifferentThan({
                                    value: parseTin(value),
                                    differentThan: companyTin,
                                    error: t("common.errors.personalTinMustBeDifferentThanCompanyTin"),
                                }),
                            },
                        ], label: _jsx(Space, Object.assign({ size: 4 }, { children: _jsx(Trans, Object.assign({ i18nKey: "dashboard.company_representatives.tinForPerson" }, { children: {
                                    name: `${editRepresentativeTin === null || editRepresentativeTin === void 0 ? void 0 : editRepresentativeTin.first_name} ${editRepresentativeTin === null || editRepresentativeTin === void 0 ? void 0 : editRepresentativeTin.last_name}`,
                                } })) })), inputProps: { placeholder: t("application_process.company_details.placeholder.tin") } }) })) }))] }));
};
export default CompanyRepresentatives;
