import { AllBanksLoanPurposes } from "@teylor-tools/Api";
export const LOAN_AMOUNT = {
    min: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 15000,
        [AllBanksLoanPurposes.ObjectFinancing]: 15000,
        [AllBanksLoanPurposes.Other]: 15000,
        [AllBanksLoanPurposes.Whitelabel]: 50000,
        [AllBanksLoanPurposes.PurposeAkfBike]: 10000,
        [AllBanksLoanPurposes.Material]: 50000,
        [AllBanksLoanPurposes.Project]: 50000,
        [AllBanksLoanPurposes.WorkingCapital]: 50000,
    },
    max: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 100000000,
        [AllBanksLoanPurposes.ObjectFinancing]: 100000000,
        [AllBanksLoanPurposes.Other]: 100000000,
        [AllBanksLoanPurposes.Whitelabel]: 1500000,
        [AllBanksLoanPurposes.PurposeAkfBike]: 50000000,
        [AllBanksLoanPurposes.Material]: 500000,
        [AllBanksLoanPurposes.Project]: 500000,
        [AllBanksLoanPurposes.WorkingCapital]: 500000,
    },
};
