import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { CardEditKeys } from "../../ApplicationDrilldownPage";
import OverviewCard from "./OverviewCard";
const BankDataCard = ({ application, setEditKey }) => {
    const { t } = useTranslation();
    const bankInfoData = useMemo(() => [
        ...(application.bankName
            ? [
                {
                    label: t("dashboard.overview.bankName"),
                    values: [application.bankName],
                },
            ]
            : []),
        ...(application.iban
            ? [
                {
                    label: t("dashboard.overview.iban"),
                    values: [application.iban],
                },
            ]
            : []),
    ], [application, t]);
    return (_jsx(OverviewCard, { data: bankInfoData, cardTitle: t("dashboard.overview.bankInformation"), cardEditKey: CardEditKeys.BankInfo, editHidden: ![ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(application.status), setEditKey: setEditKey }));
};
export default BankDataCard;
