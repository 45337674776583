import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card as AntdCard, App, Button, Form, Select, Skeleton, Space } from "antd";
import styled from "styled-components";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";
import DataItem from "src/components/shared-modules/data-item/DataItem";
import PasswordFormInput from "src/components/shared-modules/form-items/password-form-input/PasswordFormInput";
import resources from "src/i18n/translations.json";
import { Axios } from "src/utils/Axios";
const Card = styled(AntdCard) `
	max-width: 580px;
	width: 100%;
	margin: 0 auto;
`;
const languages = Object.keys(resources);
const AccountTab = () => {
    const { t, i18n } = useTranslation();
    const { message } = App.useApp();
    const [changePasswordForm] = Form.useForm();
    const [languageForm] = Form.useForm();
    const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);
    const [email, setEmail] = useState();
    const { interfaceLanguages } = useSelector((state) => state.configState);
    const listOfLanguages = useMemo(() => {
        if (!(interfaceLanguages === null || interfaceLanguages === void 0 ? void 0 : interfaceLanguages.length))
            return languages;
        return languages
            .filter((lang) => interfaceLanguages.includes(lang))
            .sort((a, b) => interfaceLanguages.indexOf(a) - interfaceLanguages.indexOf(b));
    }, [interfaceLanguages]);
    useEffect(() => {
        Axios.get("partner/account/profile")
            .then(({ data }) => {
            setEmail(data.email);
        })
            .catch((err) => {
            void Axios.error(err, t("common.errors.failedFetchUserInfo"));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onLanguageChange = ({ language }) => {
        Axios.patch(`/partner/account/profile`, { interface_language: language }).then(() => void i18n.changeLanguage(language), (err) => Axios.error(err));
    };
    const onChangePassword = ({ old_password, new_password, }) => {
        setIsLoadingChangePassword(true);
        Axios.post(`/partner/account/change_password`, {
            old_password,
            new_password,
        })
            .then(() => {
            changePasswordForm.resetFields();
            void message.success(t("settings.password-change-success"));
        })
            .catch((error) => Axios.error(error))
            .finally(() => {
            setIsLoadingChangePassword(false);
        });
    };
    return (_jsxs(Space, Object.assign({ direction: "vertical", size: "middle", style: { display: "flex" } }, { children: [_jsx(Card, Object.assign({ title: t("dashboard.overview.email") }, { children: email ? (_jsx(DataItem, { label: `${t("dashboard.overview.email")} :`, values: [email] })) : (_jsx(Skeleton, { paragraph: { rows: 1, width: "100%" }, title: false })) })), _jsx(Card, Object.assign({ title: t("settings.resetPassword") }, { children: _jsxs(Form, Object.assign({ form: changePasswordForm, layout: "vertical", onFinish: onChangePassword }, { children: [_jsx(PasswordFormInput, { children: _jsx(PasswordFormItem, { name: "old_password", label: t("settings.currentPassword"), inputProps: {
                                    size: "large",
                                }, checkPattern: false }) }), _jsx(PasswordFormInput, { children: _jsx(PasswordFormItem, { name: "new_password", label: t("authentication.register.password"), inputProps: {
                                    size: "large",
                                } }) }), _jsx(PasswordFormInput, { children: _jsx(PasswordFormItem, { name: "repeatPassword", label: t("authentication.register.repeatPassword"), rules: [
                                    { required: true, message: t("common.errors.fieldRequired") },
                                    {
                                        validator: (_, repeatPassword) => repeatPassword === changePasswordForm.getFieldValue("new_password")
                                            ? Promise.resolve()
                                            : Promise.reject(t("common.errors.password-repeat-invalid")),
                                    },
                                ], inputProps: {
                                    size: "large",
                                } }) }), _jsx(Button, Object.assign({ type: "primary", size: "large", htmlType: "submit", "data-cy": "change-password-button", loading: isLoadingChangePassword }, { children: t("common.button_label.changePassword") }))] })) })), _jsx(Card, Object.assign({ title: t("common.language") }, { children: _jsxs(Form, Object.assign({ form: languageForm, layout: "vertical", onFinish: onLanguageChange }, { children: [_jsx(Form.Item, Object.assign({ label: t("common.language"), name: "language", initialValue: i18n.language }, { children: _jsx(Select, { children: listOfLanguages.map((langCode) => (_jsx(Select.Option, Object.assign({ value: langCode }, { children: t(`common.language.${langCode}`) }), langCode))) }) })), _jsx(Button, Object.assign({ type: "primary", size: "large", "data-cy": "change-language-button", htmlType: "submit" }, { children: t("common.button_label.save") }))] })) }))] })));
};
export default AccountTab;
