var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tabs as AntdTabs, App, Button, Space, Tooltip, Typography } from "antd";
import styled from "styled-components";
import { AllBanksLoanPurposes, ApplicationStatuses, ApplicationUpdateConstraintsAllowedFieldsEnum, PendingActionType, } from "@teylor-tools/Api";
import MainHeader from "@ui/main-header/MainHeader";
import DocumentsTab from "@ui/modules/DocumentsTab";
import AntdAlert from "src/components/Alert";
import Error from "src/components/Error";
import FullPageSpinner from "src/components/FullPageSpinner";
import DataItem from "src/components/shared-modules/data-item/DataItem";
import { usePageTitle } from "src/hooks/usePageTitle";
import ObjectsTab from "src/pages/application-drilldown/tabs/objects/ObjectsTab";
import { Axios } from "src/utils/Axios";
import { getDisplayedStatus } from "src/utils/applicationStatus";
import RejectModal from "./modals/RejectModal";
import SubmitToInternalReviewModal from "./modals/SubmitToInternalReviewModal";
import SubmitToPreCheckModal from "./modals/SubmitToPreCheckModal";
import SubmitToSignatureModal from "./modals/SubmitToSignatureModal";
import { FactoringTab } from "./tabs/factoring/FactoringTab";
import OverviewTab from "./tabs/overview/OverviewTab";
import PendingItemsCard from "./tabs/overview/PendingItemsCard";
import usePendingItems from "./tabs/overview/usePendingItems";
import RepresentativesTab from "./tabs/representatives/RepresentativesTab";
import SignatureRequestsTab from "./tabs/signature-requests/SignatureRequestsTab";
const Wrapper = styled.div `
	background: ${({ theme }) => theme.background_light};
	height: 100%;
`;
const Alert = styled(AntdAlert) `
	margin-bottom: 24px;
`;
const HeaderInfo = styled(Space) `
	padding: 0 24px 32px;
	width: 100%;
	background: #fff;
`;
const Tabs = styled(AntdTabs) `
	background: ${({ theme }) => theme.background_light};

	.ant-tabs-nav-wrap {
		background: #fff;
		padding: 0 24px;
	}

	.ant-tabs-content-holder {
		padding: 12px 24px;
		display: flex;
		justify-content: center;
	}

	.ant-tabs-content {
		max-width: 1400px;
	}
`;
export var TabPaneKeys;
(function (TabPaneKeys) {
    TabPaneKeys["Overview"] = "overview";
    TabPaneKeys["CompanyRepresentatives"] = "company_representatives";
    TabPaneKeys["Documents"] = "documents";
    TabPaneKeys["SignatureRequests"] = "signature_requests";
    TabPaneKeys["Objects"] = "objects";
    TabPaneKeys["Factoring"] = "factoring";
})(TabPaneKeys || (TabPaneKeys = {}));
export var CardEditKeys;
(function (CardEditKeys) {
    CardEditKeys["BankInfo"] = "bank_info";
    CardEditKeys["Pricing"] = "pricing";
    CardEditKeys["Tin"] = "tin";
    CardEditKeys["CustomFields"] = "custom_fields";
    CardEditKeys["LoanData"] = "loan_data";
    CardEditKeys["LeasingDetails"] = "leasing_details";
})(CardEditKeys || (CardEditKeys = {}));
const ApplicationDrilldownPage = () => {
    var _a;
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { application_id } = useParams();
    const [error, setError] = useState();
    const [application, setApplication] = useState();
    const [representatives, setRepresentatives] = useState([]);
    const [activeTabPane, setActiveTabPane] = useState(TabPaneKeys.Overview);
    const [editKey, setEditKey] = useState();
    const [loading, setLoading] = useState(true);
    const [rawPendingItems, setRawPendingItems] = useState([]);
    const { pendingItemExists } = usePendingItems(rawPendingItems);
    const [sendingApplication, setSendingApplication] = useState(false);
    const [submitToPreCheckModal, setSubmitToPreCheckModal] = useState(false);
    const [submitToInternalReviewModal, setSubmitToInternalReviewModal] = useState(false);
    const [submitToSignatureModal, setSubmitToSignatureModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [nextStatus, setNextStatus] = useState();
    const [editableFields, setEditableFields] = useState([]);
    const [applicationEvents, setApplicationEvents] = useState();
    const signatories = representatives.filter(({ requirements }) => (requirements === null || requirements === void 0 ? void 0 : requirements.is_loan_signature_required) || (requirements === null || requirements === void 0 ? void 0 : requirements.is_ubo_signature_required));
    const representativesTins = useMemo(() => representatives.map((rep) => rep.taxid), [representatives]);
    const displayedStatus = (application === null || application === void 0 ? void 0 : application.status) && getDisplayedStatus(application.status);
    usePageTitle((application === null || application === void 0 ? void 0 : application.companyName)
        ? `${application.companyName} - ${t("common.page_title.applicationList")}`
        : `${t("common.page_title.applicationList")}`);
    const canDeleteDocs = useMemo(() => !!((application === null || application === void 0 ? void 0 : application.status) &&
        [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(application.status)), [application === null || application === void 0 ? void 0 : application.status]);
    const showInReviewAlert = useMemo(() => (application === null || application === void 0 ? void 0 : application.status) &&
        [
            ApplicationStatuses.SalesPreCheck,
            ApplicationStatuses.InternalReview,
            ApplicationStatuses.InternalPricing,
            ApplicationStatuses.FinalReview,
            ApplicationStatuses.AwaitingPayout,
            ApplicationStatuses.Pricing,
        ].includes(application.status), [application === null || application === void 0 ? void 0 : application.status]);
    const submitApplication = (reject = false) => {
        if (!application) {
            return message.error("error.generic");
        }
        setSendingApplication(true);
        const newStatus = reject ? ApplicationStatuses.Rejected : nextStatus;
        Axios.patch(`/partner/applications/${application === null || application === void 0 ? void 0 : application.applicationId}/status`, {
            status: newStatus,
        })
            .then(() => {
            void getApplication();
            void getPendingItems();
        })
            .catch((err) => void Axios.error(err))
            .finally(() => setSendingApplication(false));
    };
    const submitBtns = useMemo(() => {
        if ((application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.Open) {
            return [
                {
                    text: t("common.button_label.submitApplication"),
                    onSubmit: () => setSubmitToPreCheckModal(true),
                },
            ];
        }
        if ((application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.PreCheck) {
            return [
                ...(application.possible_states.includes(ApplicationStatuses[ApplicationStatuses.Rejected])
                    ? [
                        {
                            text: t("common.button_label.rejectApplication"),
                            danger: true,
                            icon: _jsx(CloseOutlined, {}),
                            onSubmit: () => setRejectModal(true),
                        },
                    ]
                    : []),
                {
                    text: t("common.button_label.confirmApplication"),
                    icon: _jsx(CheckOutlined, {}),
                    onSubmit: () => setSubmitToInternalReviewModal(true),
                },
            ];
        }
        if ((application === null || application === void 0 ? void 0 : application.status) === ApplicationStatuses.ExternalPricing) {
            return [
                {
                    text: t("common.button_label.submitPrice"),
                    onSubmit: () => setSubmitToSignatureModal(true),
                },
            ];
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application === null || application === void 0 ? void 0 : application.status]);
    const getRepresentatives = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/partner/applications/${application_id}/signatories`)
            .then((resp) => {
            setRepresentatives(resp.data.signatories);
        })
            .catch((err) => setError(err));
    });
    const getPendingItems = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/partner/applications/${application_id}/next_status_validate`).then(({ data }) => {
            setRawPendingItems(data.pending_actions);
            setNextStatus(data.next_status);
        });
    });
    const getApplication = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`/partner/applications/${application_id}`).then((resp) => {
            setApplication(resp.data);
        });
    });
    const getApplicationEvents = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`partner/applications/${application_id}/logs`, {
            events: true,
        }).then(({ data }) => setApplicationEvents(data.logs), (err) => {
            void Axios.error(err);
        });
    }), [application_id]);
    const getEditableFields = useCallback(() => {
        Axios.get(`partner/applications/${application_id}/application_update_constraints`).then(({ data }) => {
            const editableFields = data.reduce((acc, constraint) => {
                var _a;
                (_a = constraint.allowed_fields) === null || _a === void 0 ? void 0 : _a.forEach((field) => {
                    var _a;
                    // @ts-ignore
                    if (acc.has[field] && ((_a = constraint.actions) === null || _a === void 0 ? void 0 : _a.length)) {
                        const currentActions = 
                        // @ts-ignore
                        acc.get[field] || [];
                        acc.set(field, [...currentActions, ...constraint.actions]);
                    }
                    else {
                        acc.set(field, constraint.actions || []);
                    }
                });
                return acc;
            }, new Map());
            setEditableFields(Array.from(editableFields).map(([key, value]) => ({ field: key, actions: value })));
        }, (err) => setError(err));
    }, [application_id]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            yield getApplication();
            yield getRepresentatives();
            yield getPendingItems();
            yield getApplicationEvents();
        }))()
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!(application === null || application === void 0 ? void 0 : application.status))
            return;
        void getEditableFields();
    }, [application === null || application === void 0 ? void 0 : application.status, getEditableFields]);
    // @ts-ignore - issue with the type because of conditional rendering
    const tabs = application
        ? [
            {
                key: TabPaneKeys.Overview,
                label: t("dashboard.overview.overview"),
                children: (_jsxs(_Fragment, { children: [showInReviewAlert && _jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true }), _jsx(PendingItemsCard, { rawPendingItems: rawPendingItems, setEditKey: setEditKey, setActiveTabPane: setActiveTabPane }), _jsx(OverviewTab, { application: application, editKey: editKey, setEditKey: setEditKey, onUpdate: () => {
                                void getApplication();
                                void getPendingItems();
                                void getApplicationEvents();
                            }, representativesTins: representativesTins, editableFields: editableFields, applicationEvents: applicationEvents })] })),
            },
            {
                key: TabPaneKeys.CompanyRepresentatives,
                label: t("dashboard.overview.companyRepresentatives"),
                children: (_jsxs(_Fragment, { children: [showInReviewAlert && _jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true }), _jsx(RepresentativesTab, { applicationStatus: application.status, representatives: representatives, onUpdate: () => {
                                void getPendingItems();
                                void getRepresentatives();
                            }, ownershipPercentageExceeded: pendingItemExists(PendingActionType.SignatoriesSharesLe100), noSoleSignatory: pendingItemExists(PendingActionType.SignatoriesSoleOrAtleast2), companyTin: application.taxIdNumber || "" })] })),
            },
            {
                key: TabPaneKeys.Documents,
                label: t("dashboard.overview.documents"),
                children: (_jsxs(_Fragment, { children: [showInReviewAlert && _jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true }), _jsx(DocumentsTab, { appType: "partner", axios: Axios, apiPath: `partner/applications/${application.applicationId}/documents`, canUpload: !!editableFields.find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.Document), canDelete: canDeleteDocs, categoriesConfig: application.document_configurations.document_categories || [], onUpdate: () => void getPendingItems(), canChangeClientVisibility: true, translations: {
                                errorGeneric: t("common.errors.generic"),
                                fileUploadFail: t("common.errors.file-upload-fail"),
                                fileUploadSuccess: t("dashboard.documents.file-upload-success"),
                                fileDeleteSuccess: t("dashboard.documents.file-delete-success"),
                                fileDeleteFail: t("common.errors.file-delete-fail"),
                                errorTryAgain: t("common.errors.error-try-again"),
                                errorUploadInProgress: t("common.errors.file-upload-in-progress"),
                                errorFileNotAvailable: t("common.errors.file-not-available"),
                                dragDrop: t("dashboard.documents.drag-drop-below"),
                                uploadBtn: t("common.button_label.upload"),
                                downloadTemplate: t("dashboard.documents.click-here-download-template"),
                                // @ts-ignore dynamic values for categories and types
                                getTranslation: (key) => t(key),
                            } })] })),
            },
            application.status === ApplicationStatuses.Signature
                ? {
                    key: TabPaneKeys.SignatureRequests,
                    label: t("dashboard.overview.signatureRequests"),
                    children: (_jsxs(_Fragment, { children: [showInReviewAlert && (_jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true })), _jsx(SignatureRequestsTab, { signatories: signatories, getRepresentatives: getRepresentatives })] })),
                }
                : {},
            application.allBanksLoanPurpose === AllBanksLoanPurposes.ObjectFinancing
                ? {
                    key: TabPaneKeys.Objects,
                    label: t("dashboard.objects.objects"),
                    children: (_jsxs(_Fragment, { children: [showInReviewAlert && (_jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true })), _jsx(ObjectsTab, { applicationStatus: application.status, applicationId: application.applicationId, onUpdate: () => void getPendingItems(), currency: application.currency })] })),
                }
                : {},
            application.allBanksLoanPurpose === AllBanksLoanPurposes.Factoring
                ? {
                    key: TabPaneKeys.Factoring,
                    label: t("common.loan_purpose.factoring"),
                    children: (_jsxs(_Fragment, { children: [showInReviewAlert && (_jsx(Alert, { message: t("common.messages.inReviewInfo"), showIcon: true })), _jsx(FactoringTab, { application: application, getApplication: getApplication, updatePendingItems: getPendingItems, pendingFactoringData: pendingItemExists(PendingActionType.FactoringDetailsAdded) })] })),
                }
                : {},
        ]
        : [];
    if (loading)
        return _jsx(FullPageSpinner, {});
    if (error)
        return _jsx(Error, { error: error });
    if (!application)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { withBorderBottom: false, title: _jsx(_Fragment, { children: _jsxs(Space, { children: [_jsxs(Typography.Text, Object.assign({ strong: true, style: { fontSize: 16 }, type: "secondary" }, { children: [t("application_process.applications"), " ", " /"] })), _jsx(Typography.Text, Object.assign({ strong: true, style: { fontSize: 24 } }, { children: application === null || application === void 0 ? void 0 : application.companyName }))] }) }), extra: _jsx(Space, Object.assign({ size: 20 }, { children: submitBtns.map((options, index) => (_jsx(Tooltip, Object.assign({ title: !options.danger && !!rawPendingItems.length
                            ? t("dashboard.pending_items.applDisabledSubmitPendingItems")
                            : "" }, { children: _jsx(Button, Object.assign({ type: "primary", danger: !!options.danger, "data-cy": "submit-application-button", size: "large", icon: options.icon, disabled: !options.danger && !!rawPendingItems.length, loading: sendingApplication, onClick: options.onSubmit }, { children: options.text })) }), `submit-btn-${index}`))) })) }), _jsxs(Wrapper, { children: [_jsx(HeaderInfo, { children: _jsxs(Space, Object.assign({ size: 30 }, { children: [_jsx(DataItem, { label: `${t("dashboard.overview.applicationId")}:`, values: [String(application.shortApplicationId).toUpperCase()], labelStyles: { fontWeight: "bold" } }), _jsx(DataItem, { label: `${t("dashboard.overview.status")}:`, values: [displayedStatus ? t(`common.application_status.${displayedStatus}`) : ""], labelStyles: { fontWeight: "bold" } })] })) }), _jsx(Tabs, { items: tabs, activeKey: activeTabPane, onChange: (key) => setActiveTabPane(key) })] }), _jsx(SubmitToPreCheckModal, { representatives: representatives, modalProps: {
                    open: submitToPreCheckModal,
                    onCancel: () => {
                        setSubmitToPreCheckModal(false);
                        setActiveTabPane(TabPaneKeys.CompanyRepresentatives);
                    },
                    onOk: () => {
                        setSubmitToPreCheckModal(false);
                        void submitApplication();
                    },
                } }), _jsx(SubmitToInternalReviewModal, { modalProps: {
                    open: submitToInternalReviewModal,
                    onCancel: () => setSubmitToInternalReviewModal(false),
                    onOk: () => {
                        setSubmitToInternalReviewModal(false);
                        void submitApplication();
                    },
                } }), _jsx(SubmitToSignatureModal, { signatories: signatories, finalInterestRate: Number((_a = application === null || application === void 0 ? void 0 : application.pricing) === null || _a === void 0 ? void 0 : _a.total_interest_rate), modalProps: {
                    open: submitToSignatureModal,
                    onCancel: () => {
                        setSubmitToSignatureModal(false);
                    },
                    onOk: () => {
                        setSubmitToSignatureModal(false);
                        void submitApplication();
                    },
                } }), _jsx(RejectModal, { modalProps: {
                    open: rejectModal,
                    onCancel: () => setRejectModal(false),
                    onOk: () => {
                        setRejectModal(false);
                        void submitApplication(true);
                    },
                } })] }));
};
export default ApplicationDrilldownPage;
