import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import { AllBanksLoanPurposes, ApplicationUpdateConstraintsActions, ApplicationUpdateConstraintsAllowedFieldsEnum, } from "@teylor-tools/Api";
import { CardEditKeys, } from "src/pages/application-drilldown/ApplicationDrilldownPage";
import TinNumberModal from "../../modals/TinNumberModal";
import AkfCards from "./AkfCards";
import ApplicationEvents from "./ApplicationEvents";
import BankDataCard from "./BankDataCard";
import CustomerDataCard from "./CustomerDataCard";
import LoanInformationCard from "./LoanInformationCard";
import { StyledCard } from "./OverviewComponents";
import BankInfoModal from "./modals/BankInfoModal";
import CustomFieldsModal from "./modals/CustomFieldsModal";
import LeasingDetailsModal from "./modals/LeasingDetails";
import LoanDataModal from "./modals/LoanData";
import PricingModal from "./modals/PricingModal";
const OverviewTab = ({ application, editKey, setEditKey, onUpdate, representativesTins, editableFields, applicationEvents, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const isAkf = application.allBanksLoanPurpose === AllBanksLoanPurposes.PurposeAkfBike;
    const shouldValidateAmount = !((_b = (_a = editableFields
        .find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.LoanSize)) === null || _a === void 0 ? void 0 : _a.actions) === null || _b === void 0 ? void 0 : _b.includes(ApplicationUpdateConstraintsActions.ReassignProduct));
    const canUpdateAmount = !!editableFields.find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.LoanSize);
    const canUpdateDuration = !!editableFields.find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.LoanDuration);
    const canUpdatePurpose = !!editableFields.find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.PurposeDescription);
    return (_jsxs(_Fragment, { children: [_jsxs(Row, Object.assign({ gutter: [24, 12] }, { children: [_jsx(Col, Object.assign({ lg: 12, xs: 24 }, { children: _jsxs(Row, { children: [_jsx(CustomerDataCard, { application: application, setEditKey: setEditKey }), _jsx(BankDataCard, { application: application, setEditKey: setEditKey }), _jsx(Col, Object.assign({ xs: 24 }, { children: _jsx(StyledCard, Object.assign({ title: t("dashboard.overview.application_events") }, { children: _jsx(ApplicationEvents, { applicationEvents: applicationEvents }) })) }))] }) })), _jsx(Col, Object.assign({ lg: 12, xs: 24 }, { children: _jsx(Row, { children: isAkf ? (_jsx(AkfCards, { application: application, setEditKey: setEditKey, editableFields: editableFields })) : (_jsx(LoanInformationCard, { application: application, setEditKey: setEditKey, editableFields: editableFields, onUpdate: onUpdate })) }) }))] })), editKey === CardEditKeys.Pricing && (_jsx(PricingModal, { baseInterestRate: (_c = application.pricing) === null || _c === void 0 ? void 0 : _c.interest_rate, externalInterestRate: application.pricing.external_interest_rate, baseTransactionFee: application.pricing.transaction_fee, externalTransactionFee: application.pricing.external_transaction_fee, currency: application.currency, modalProps: {
                    open: editKey === CardEditKeys.Pricing,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                } })), editKey === CardEditKeys.LoanData && (_jsx(LoanDataModal, { application: application, modalProps: {
                    open: editKey === CardEditKeys.LoanData,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                }, canUpdateAmount: canUpdateAmount, canUpdateDuration: canUpdateDuration, canUpdatePurpose: canUpdatePurpose, shouldValidateAmount: shouldValidateAmount })), editKey === CardEditKeys.LeasingDetails && (_jsx(LeasingDetailsModal, { pricing: application.pricing, applicationCurrency: application.currency, modalProps: {
                    open: editKey === CardEditKeys.LeasingDetails,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                } })), editKey === CardEditKeys.CustomFields && application.custom_fields_data && (_jsx(CustomFieldsModal, { customFieldsData: application.custom_fields_data, applicationCurrency: application.currency, modalProps: {
                    open: editKey === CardEditKeys.CustomFields,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                } })), _jsx(BankInfoModal, { bankName: application.bankName, iban: application.iban, modalProps: {
                    open: editKey === CardEditKeys.BankInfo,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                } }), _jsx(TinNumberModal, { tin: application.taxIdNumber, inputLabel: _jsxs(Typography.Text, { children: [t("dashboard.company_representatives.tinFor"), " ", _jsx("b", { children: application.companyName })] }), modalProps: {
                    title: t("dashboard.overview.companyTin"),
                    open: editKey === CardEditKeys.Tin,
                    onCancel: () => setEditKey(undefined),
                }, onSend: () => {
                    setEditKey(undefined);
                    onUpdate();
                }, representativesTins: representativesTins })] }));
};
export default OverviewTab;
