import { getCountries } from "@teylor-tools/utils/getCountries";
import { ConfigAction } from "src/store/actions/configAction";
const defaultState = {
    loaded: false,
    features: [],
    loanTypes: undefined,
    companyTypes: undefined,
    defaultCompanyType: undefined,
    customFields: undefined,
    currencies: undefined,
    defaultCurrency: undefined,
    entitlements: undefined,
    preferredCountries: undefined,
    countries: [],
    documentCategories: [],
    interfaceLanguages: undefined,
    loanPurposes: [],
};
const configReducer = function (state = defaultState, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    switch (action.type) {
        case ConfigAction.SET_CONFIG:
            return Object.assign(Object.assign({}, state), { loaded: true, features: action.payload.features || [], loanTypes: ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.applicationTypes) || [], companyTypes: ((_c = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.companyTypes) === null || _c === void 0 ? void 0 : _c.value.supported) || [], defaultCompanyType: ((_e = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.companyTypes) === null || _e === void 0 ? void 0 : _e.value.default) || undefined, customFields: (_f = action.payload) === null || _f === void 0 ? void 0 : _f.customFields, currencies: (_g = action.payload) === null || _g === void 0 ? void 0 : _g.currencies, defaultCurrency: (_j = (_h = action.payload) === null || _h === void 0 ? void 0 : _h.currencies) === null || _j === void 0 ? void 0 : _j.value.default, entitlements: (_k = action.payload) === null || _k === void 0 ? void 0 : _k.entitlements, preferredCountries: (_m = (_l = action.payload) === null || _l === void 0 ? void 0 : _l.preferredCountries) === null || _m === void 0 ? void 0 : _m.value.preferred, interfaceLanguages: (_p = (_o = action.payload) === null || _o === void 0 ? void 0 : _o.interfaceLanguages) === null || _p === void 0 ? void 0 : _p.value.supported, loanPurposes: (((_r = (_q = action.payload) === null || _q === void 0 ? void 0 : _q.loanPurposes) === null || _r === void 0 ? void 0 : _r.value.items) || []) });
        case ConfigAction.SET_COUNTRY_LIST:
            return Object.assign(Object.assign({}, state), { countries: getCountries({
                    preferredCountries: (_s = action.payload) === null || _s === void 0 ? void 0 : _s.preferredCountries,
                    countries: action.payload.countries,
                }) });
        case ConfigAction.SET_DOCUMENT_CATEGORIES:
            return Object.assign(Object.assign({}, state), { documentCategories: action.payload.documentCategories || [] });
        default:
            return Object.assign({}, state);
    }
};
export default configReducer;
