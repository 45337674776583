import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { ShopOutlined } from "@ant-design/icons";
import { Divider as AntdDivider, AutoComplete, Input, Spin, Tag, Typography } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import useDebounce from "@teylor-tools/hooks/debounce";
import { RoutePaths } from "src/Routes";
import { Axios } from "src/utils/Axios";
const { Search } = Input;
const { Text } = Typography;
const NotFound = styled.div `
	padding: 12px 4px;
	text-align: center;
	white-space: normal;
`;
const CompanyListItem = styled.div `
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 16px;
	cursor: pointer;
	padding: 4px;
`;
const CompanyDetails = styled.div `
	display: flex;
	flex-direction: column;
	white-space: normal;
`;
const CompanyDetailsHeader = styled.div `
	display: flex;
	justify-content: space-between;
`;
const Divider = styled(AntdDivider) `
	margin: 0;
`;
const GlobalStyle = createGlobalStyle `
  .ant-select-dropdown .ant-select-item {
    min-height: 0;
  }
`;
const CompanySearchWrapper = styled.div `
	margin: 0 24px;

	> .ant-select .ant-select-clear {
		inset-inline-end: 45px;
	}
`;
export const CompanySearchAutocomplete = ({ setSelectedCompany }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noMatches, setNoMatches] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const navigate = useNavigate();
    const handleFetch = () => {
        setLoading(true);
        setNoMatches(false);
        void Axios.get(`/sabre/partner/companies?text=${encodeURIComponent(searchQuery)}`)
            .then(({ data }) => {
            if (data.companies && data.companies.length > 0) {
                setOptions(data.companies);
            }
            else {
                setOptions([]);
                setNoMatches(true);
            }
        })
            .catch((error) => Axios.error(error))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (debouncedSearchQuery.length > 3) {
            handleFetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery]);
    const renderNotFoundContent = () => {
        if (loading)
            return _jsx(Spin, { size: "small" });
        if (noMatches)
            return _jsx(NotFound, { children: t("company.search.not-found") });
        return null;
    };
    const renderCompanyOption = (option) => ({
        key: option.north_data_id,
        value: `${option.north_data_id}_${option.company_name}`,
        label: (_jsxs(CompanyListItem, { children: [_jsx(ShopOutlined, { style: { fontSize: 25 } }), _jsxs(CompanyDetails, { children: [option.in_portfolio ? (_jsxs(CompanyDetailsHeader, { children: [_jsx(Text, { children: option.company_name }), _jsx(Tag, Object.assign({ color: "gold" }, { children: t("company.search.your_portfolio") }))] })) : (_jsx(Text, { children: option.company_name })), _jsx(Text, Object.assign({ type: "secondary" }, { children: [
                                option.company_street,
                                option.company_house_number,
                                option.company_postcode,
                                option.company_location,
                                t(`common.countries.${option.company_country}`),
                            ]
                                .filter(Boolean)
                                .join(", ") }))] })] }, `item-${option.north_data_id}`)),
    });
    const renderOptions = () => {
        const { portfolioOptions, otherOptions } = options.reduce((acc, option) => {
            const companyOption = renderCompanyOption(option);
            if (option.in_portfolio) {
                acc.portfolioOptions.push(companyOption);
            }
            else {
                acc.otherOptions.push(companyOption);
            }
            return acc;
        }, {
            portfolioOptions: [],
            otherOptions: [],
        });
        const dividerOption = {
            key: "divider",
            disabled: true,
            label: _jsx(Divider, {}),
        };
        return portfolioOptions.length > 0
            ? [...portfolioOptions, dividerOption, ...otherOptions]
            : [...otherOptions];
    };
    const handleSelectCompany = (value, option) => {
        const selectedCompany = options.find((o) => o.north_data_id === option.key);
        if (selectedCompany && !selectedCompany.in_portfolio) {
            setSelectedCompany(selectedCompany);
            setInputValue(selectedCompany.company_name);
        }
        else if (selectedCompany) {
            navigate(generatePath(RoutePaths.CompanyPortfolioOverview, {
                companyId: selectedCompany.partner_portfolio_item_id,
            }));
        }
    };
    const handleInputChange = (value) => {
        setInputValue(value);
        setSearchQuery(value);
        if (!value) {
            handleClear();
        }
    };
    const handleClear = () => {
        setOptions([]);
    };
    return (_jsxs(CompanySearchWrapper, { children: [_jsx(GlobalStyle, {}), _jsx(AutoComplete, Object.assign({ value: inputValue, style: { width: "100%" }, options: renderOptions(), onSearch: handleInputChange, notFoundContent: renderNotFoundContent(), onSelect: handleSelectCompany, onClear: handleClear, allowClear: true }, { children: _jsx(Search, { placeholder: t("company.search.placeholder") }) }))] }));
};
