import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { MarkerType, ReactFlow, applyNodeChanges, useReactFlow, } from "reactflow";
import { Spin } from "antd";
import { useTheme } from "styled-components";
import { Edge } from "./Edge";
import { Node } from "./Node";
export const ActiveNodeContext = createContext(null);
const nodeMargin = 10; // between nodes
const nodeWidth = 35;
const nodeHeight = 35;
const maxAttempts = 100; // max count attempt for set node position
const options = { hideAttribution: true };
export const InnerRelationshipGraph = ({ nodesData, edgesData, containerWidth, containerHeight, }) => {
    const { fitView } = useReactFlow();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [activeNodeId, setActiveNodeId] = useState(null);
    const theme = useTheme();
    const [positioning, setPositioning] = useState(true);
    const transformNodes = useMemo(() => {
        const placedNodes = [];
        // check if node is overlap
        const isOverlapping = (nodeA, position) => {
            return (nodeA.position.x < position.x + nodeWidth + nodeMargin &&
                nodeA.position.x + nodeWidth + nodeMargin > position.x &&
                nodeA.position.y < position.y + nodeHeight + nodeMargin &&
                nodeA.position.y + nodeHeight + nodeMargin > position.y);
        };
        (nodesData !== null && nodesData !== void 0 ? nodesData : []).forEach((node) => {
            let position;
            let attempts = 0;
            let overlapFound;
            do {
                position = {
                    x: Math.random() * (containerWidth - nodeWidth),
                    y: Math.random() * (containerHeight - nodeHeight),
                };
                overlapFound = placedNodes.some((placedNode) => isOverlapping(placedNode, position));
                attempts++;
            } while (overlapFound && attempts < maxAttempts);
            placedNodes.push({
                id: node.node_id,
                type: "customNode",
                data: {
                    label: node.node_title,
                    type: node.node_type,
                },
                position: overlapFound ? { x: 0, y: 0 } : position, // if attempt is more than maxAttempt and nodes are overlap set default position
            });
        });
        return placedNodes;
    }, [containerWidth, containerHeight, nodesData]);
    const onNodesChange = useCallback((changes) => {
        setNodes((nds) => applyNodeChanges(changes, nds));
    }, [setNodes]);
    const transformEdges = useMemo(() => {
        return edgesData === null || edgesData === void 0 ? void 0 : edgesData.map((edge) => ({
            id: edge.edge_id,
            source: edge.node_from,
            target: edge.node_to,
            label: edge.edge_title,
            type: "customEdge",
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: theme.color_primary,
            },
            style: {
                stroke: theme.color_primary,
            },
        }));
    }, [edgesData, theme]);
    useEffect(() => {
        if (!transformNodes || !transformEdges) {
            return;
        }
        setNodes([...transformNodes]);
        setEdges([...transformEdges]);
        window.requestAnimationFrame(() => {
            setTimeout(() => {
                fitView();
                setPositioning(false);
            }, 100);
        });
    }, [fitView, transformNodes, transformEdges]);
    const nodeTypes = useMemo(() => {
        return {
            customNode: (props) => {
                return _jsx(Node, Object.assign({}, props));
            },
        };
    }, []);
    const edgeTypes = useMemo(() => {
        return {
            customEdge: ({ id, source, target, markerEnd, style, label }) => {
                return (_jsx(Edge, { id: id, source: source, target: target, style: style, markerEnd: markerEnd, label: label }));
            },
        };
    }, []);
    const handleNodeMouseEnter = useCallback((_, node) => {
        setActiveNodeId(node.id);
        setNodes((currentNodes) => currentNodes.map((n) => {
            if (n.id === node.id) {
                return Object.assign(Object.assign({}, n), { selected: true });
            }
            return n;
        }));
    }, [setNodes]);
    const handleNodeMouseLeave = useCallback((_, node) => {
        setActiveNodeId(null);
        setNodes((currentNodes) => currentNodes.map((n) => {
            if (n.id === node.id) {
                return Object.assign(Object.assign({}, n), { selected: false });
            }
            return n;
        }));
    }, [setNodes]);
    return (_jsx(ActiveNodeContext.Provider, Object.assign({ value: activeNodeId }, { children: positioning ? (_jsx(Spin, { spinning: positioning })) : (_jsx(ReactFlow, { nodes: nodes, edges: edges, nodeTypes: nodeTypes, edgeTypes: edgeTypes, onNodesChange: onNodesChange, onNodeMouseEnter: handleNodeMouseEnter, onNodeMouseLeave: handleNodeMouseLeave, snapToGrid: true, snapGrid: [15, 15], proOptions: options, fitView: true })) })));
};
