import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { EditOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import styled from "styled-components";
export const NotAvailableJsx = () => {
    const { t } = useTranslation();
    return (_jsx(Typography.Text, Object.assign({ type: "secondary", style: { opacity: 0.5 } }, { children: t("common.notAvailable") })));
};
export const StyledCard = styled(Card) `
	margin-top: 15px;
	width: 100%;

	.ant-card-head-wrapper {
		height: 64px;
	}
`;
export const EditIcon = styled(EditOutlined) `
	color: ${(props) => props.theme.color_primary};
	margin-left: 8px;
`;
