import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BankOutlined, ContainerOutlined, LogoutOutlined, SettingOutlined, ShopOutlined, } from "@ant-design/icons";
import { Menu as AntdMenu, Button, Popover, Tooltip } from "antd";
import AntdSider from "antd/lib/layout/Sider";
import Layout, { Content } from "antd/lib/layout/layout";
import styled, { useTheme } from "styled-components";
import { EntitlementType } from "@teylor-tools/Api";
import Avatar from "@ui/data-display/Avatar";
import { RoutePaths } from "src/Routes";
import { logout } from "src/store/actions/userActions";
import { Axios } from "src/utils/Axios";
const StyledContent = styled(Content) `
	height: 100vh;
	overflow: auto;
`;
const Wrapper = styled.div `
	padding: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100vh;
`;
const Sider = styled(AntdSider) `
	background: ${({ theme }) => theme.main_menu_bg_color} !important;
`;
const Menu = styled(AntdMenu) `
	background: ${({ theme }) => theme.main_menu_bg_color};

	.ant-menu-item {
		color: ${({ theme }) => theme.main_menu_color};
	}

	.ant-menu-item-selected {
		color: ${({ theme }) => theme.color_cta_text};
		background: ${({ theme }) => theme.color_primary};
	}
`;
const AvatarWrapper = styled.div `
	position: absolute;
	bottom: 30px;
	display: flex;
	width: 100%;
	justify-content: center;
`;
const UserAvatar = styled(Avatar) `
	width: 46px;
	height: 46px;
	line-height: 46px;
	font-size: 16px !important;
	cursor: pointer;
	font-weight: 600;
`;
const LogoWrapper = styled.div `
	margin: 24px 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 40px;
	}
`;
const UserActions = styled.div `
	display: flex;
	flex-direction: column;
	align-items: start;
`;
const UserActionButton = styled(Button) `
	color: ${(props) => props.theme.colorPrimary};
`;
const MenuLayout = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { entitlements } = useSelector((state) => state.configState);
    const [selectedKey, setSelectedKey] = useState();
    const [showPopover, setShowPopover] = useState(false);
    const { email } = useSelector(({ userState }) => userState);
    const showPortfolioPages = (entitlements === null || entitlements === void 0 ? void 0 : entitlements.length) && entitlements.includes(EntitlementType.CompanyResearch);
    const showApplicationsPages = (entitlements === null || entitlements === void 0 ? void 0 : entitlements.length) && entitlements.includes(EntitlementType.PartnerLoanApplications);
    const logOutCall = () => {
        Axios.post("/partner/account/logout").then(() => {
            dispatch(logout(true));
            navigate(RoutePaths.Login);
        }, (error) => {
            void Axios.error(error, t("common.errors.logout"));
        });
    };
    const menuItems = useMemo(() => {
        return [
            ...(showApplicationsPages
                ? [
                    {
                        icon: (_jsx(Tooltip, Object.assign({ title: t("common.label.applications"), placement: "right" }, { children: _jsx(ContainerOutlined, {}) }))),
                        key: RoutePaths.Index,
                        ["data-cy"]: "menu-applications",
                    },
                ]
                : []),
            ...(showPortfolioPages
                ? [
                    {
                        icon: (_jsx(Tooltip, Object.assign({ title: t("common.label.company-search"), placement: "right" }, { children: _jsx(BankOutlined, {}) }))),
                        key: RoutePaths.CompanySearch,
                        ["data-cy"]: "menu-company-search",
                    },
                    {
                        icon: (_jsx(Tooltip, Object.assign({ title: t("common.label.companies"), placement: "right" }, { children: _jsx(ShopOutlined, {}) }))),
                        key: RoutePaths.CompaniesPortfolio,
                        ["data-cy"]: "menu-portfolio",
                    },
                ]
                : []),
        ];
    }, [t, showApplicationsPages, showPortfolioPages]);
    useEffect(() => {
        const selectedKeyFound = menuItems.find(({ key }) => location.pathname === key);
        if (selectedKeyFound) {
            setSelectedKey(selectedKeyFound.key);
        }
    }, [location, menuItems]);
    const onClick = (e) => {
        navigate(e.key);
    };
    return (_jsxs(Layout, { children: [_jsxs(Sider, Object.assign({ width: 80 }, { children: [theme.logo_collapsed_web_path && (_jsx(LogoWrapper, { children: _jsx("img", { src: theme.logo_collapsed_web_path, alt: "Logo" }) })), _jsx(Menu, { mode: "inline", onClick: onClick, inlineCollapsed: true, items: menuItems, selectedKeys: [selectedKey || ""] }), _jsx(Popover, Object.assign({ placement: "right", open: showPopover, content: _jsxs(UserActions, Object.assign({ onMouseLeave: () => setShowPopover(false) }, { children: [_jsx(UserActionButton, Object.assign({ type: "link", "data-cy": "settings-button", icon: _jsx(SettingOutlined, {}), onClick: () => {
                                        setSelectedKey(undefined);
                                        navigate(RoutePaths.Settings);
                                    } }, { children: t("common.label.profile") })), _jsx(UserActionButton, Object.assign({ type: "link", icon: _jsx(LogoutOutlined, {}), onClick: logOutCall }, { children: t("common.label.signOut") }))] })), trigger: "click" }, { children: email && (_jsx(AvatarWrapper, Object.assign({ "data-cy": "avatar", onClick: () => setShowPopover(!showPopover) }, { children: _jsx(UserAvatar, { name: `${email.charAt(0)} ${email.charAt(1)}` }) }))) }))] })), _jsx(StyledContent, { children: _jsx(Wrapper, { children: _jsx(Outlet, {}) }) })] }));
};
export default MenuLayout;
