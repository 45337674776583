import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { App, Col, Form, Modal, Row } from "antd";
import Title from "antd/es/typography/Title";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import CustomField from "@ui/custom-field/CustomField";
import { createAkfFields, isAkfApp } from "src/pages/apply/akf";
import { Axios } from "src/utils/Axios";
const LeasingDetailsModal = ({ pricing, applicationCurrency, modalProps, onSend, }) => {
    const { application_id } = useParams();
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [sending, setSending] = useState(false);
    const { decimalToPercent, percentToDecimal } = useFormatter();
    const { customFields } = useSelector((state) => state.configState);
    const isAkf = useMemo(() => isAkfApp(customFields), [customFields]);
    const akfFields = useMemo(() => (isAkf && customFields ? createAkfFields(customFields, t) : undefined), [customFields, isAkf, t]);
    const initialValues = useMemo(() => {
        var _a;
        return ({
            leasing_factor: pricing.leasing_factor,
            service_rate: pricing.service_rate,
            remaining_value: pricing.remaining_value,
            insurance_rate: decimalToPercent({
                percent: pricing.insurance_rate,
                decimalPlaces: (_a = akfFields === null || akfFields === void 0 ? void 0 : akfFields.insurance_rate) === null || _a === void 0 ? void 0 : _a.config.ui.precision,
                withLocale: false,
            }),
        });
    }, [pricing, decimalToPercent, akfFields]);
    const handleSubmit = (values) => {
        setSending(true);
        const updateValues = Object.assign(Object.assign({}, values), (isValidNumericValue(values.insurance_rate) && {
            insurance_rate: percentToDecimal(values.insurance_rate),
        }));
        Axios.patch(`/partner/applications/${application_id}/pricing/custom_fields`, Object.assign({}, updateValues))
            .then(() => {
            onSend();
            void message.success(t("common.saved"));
        })
            .catch((error) => void Axios.error(error, t("common.errors.sendData")))
            .finally(() => setSending(false));
    };
    return (_jsx(Modal, Object.assign({ okText: t("common.button_label.save"), onOk: () => form.submit(), okButtonProps: { loading: sending }, destroyOnClose: true }, modalProps, { width: 650 }, { children: _jsxs(Form, Object.assign({ form: form, layout: "vertical", initialValues: initialValues, onFinish: handleSubmit, preserve: false }, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: t("dashboard.loan_information.custom_fields_modal.title.edit_leasing_details") })), akfFields && (_jsx(_Fragment, { children: _jsx(Row, Object.assign({ gutter: 24 }, { children: [
                            akfFields.leasing_factor,
                            akfFields.service_rate,
                            akfFields.remaining_value,
                            akfFields.insurance_rate,
                        ].map((field, i) => (_jsx(Fragment, { children: field && (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(CustomField, Object.assign({}, field, { defaultCurrency: applicationCurrency })) }))) }, i))) })) }))] })) })));
};
export default LeasingDetailsModal;
