import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
const StyledWrapper = styled.div `
	background-color: #f5f5f5;
	position: relative;
	overflow: auto;
	display: flex;
	height: 100%;
`;
const Grow = styled.div `
	flex-grow: 1;
	overflow: auto;
`;
const CompanyOutlet = (props) => {
    const ref = useRef(null);
    const { pathname } = useLocation();
    useEffect(() => {
        var _a;
        (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
    }, [pathname]);
    return (_jsx(StyledWrapper, { children: _jsx(Grow, Object.assign({ ref: ref }, { children: _jsx(Outlet, { context: Object.assign({}, props) }) })) }));
};
export default CompanyOutlet;
