import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import { TranslationsProvider as TranslationsProviderUI, } from "@ui/translations/translations";
import { financialTableRowsToTranslate, } from "@ui/translations/translations-financial-table";
export const TranslationsProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const translationsUI = useMemo(() => ({
        add: t("common.button_label.add"),
        save: t("common.button_label.save"),
        yes: t("common.yes"),
        no: t("common.no"),
        cancel: t("common.button_label.cancel"),
        select: t("common.select"),
        edit: t("common.button_label.edit"),
        delete: t("common.button_label.delete"),
        approve: t("common.approve"),
        city: t("dashboard.company_representatives.city"),
        cityPlaceholder: t("application_process.company_details.placeholder.city"),
        street: t("dashboard.company_representatives.street"),
        streetPlaceholder: t("application_process.company_details.placeholder.street"),
        emailInputErrorMessage: t("common.errors.emailInvalid"),
        emailInputLabel: t("dashboard.company_representatives.emailAddress"),
        emailInputPlaceholder: t("dashboard.company_representatives.emailAddress"),
        postcode: t("dashboard.company_representatives.postcode"),
        postcodePlaceholder: t("application_process.company_details.placeholder.postcode"),
        hrNumber: t("application_process.company_details.hrNumber"),
        hrNumberPlaceholder: t("application_process.company_details.placeholder.hrNumber"),
        houseNumber: t("dashboard.company_representatives.houseNumber"),
        houseNumberPlaceholder: t("application_process.company_details.placeholder.houseNumber"),
        countries: getCountryList(i18n.language),
        country: t("dashboard.company_representatives.country"),
        representativeCard: {
            address: t("dashboard.company_representatives.address"),
            addTin: t("common.addTin"),
            beneficialOwner: t("dashboard.company_representatives.beneficialOwner"),
            canSignAlone: t("dashboard.company_representatives.canSignAlone"),
            dateOfBirth: t("dashboard.company_representatives.dateOfBirth"),
            email: t("dashboard.company_representatives.emailAddress"),
            legalSignatory: t("dashboard.company_representatives.legalSignatory"),
            nationality: t("dashboard.company_representatives.nationality"),
            ownership: t("common.ownership"),
            phone: t("dashboard.company_representatives.phoneNumber"),
            tin: t("application_process.company_details.tin"),
            willingToSign: t("dashboard.company_representatives.willingToSign"),
            position: t("dashboard.company_representatives.position"),
            creditScore: t("dashboard.company_representatives.creditScore"),
            lastUpdated: t("dashboard.company_representatives.lastUpdated"),
        },
        representativeModal: {
            addRepresentative: t("dashboard.company_representatives.addCompanyRepresentative"),
            allFieldsRequired: t("common.errors.fieldRequired"),
            beneficialOwner: t("dashboard.company_representatives.beneficialOwner"),
            canSignAlone: t("dashboard.company_representatives.canSignAlone"),
            dateOfBirth: t("dashboard.company_representatives.dateOfBirth"),
            editRepresentative: t("dashboard.company_representatives.editCompanyRepresentative"),
            firstName: t("dashboard.company_representatives.firstName"),
            houseNumber: t("dashboard.company_representatives.houseNumber"),
            lastName: t("dashboard.company_representatives.lastName"),
            legalSignatory: t("dashboard.company_representatives.legalSignatory"),
            nationality: t("dashboard.company_representatives.nationality"),
            optional: t("common.canAddLater"),
            percentageOwnership: t("dashboard.company_representatives.percentageOwnership"),
            phoneNumber: t("dashboard.company_representatives.phoneNumber"),
            representativeNotContacted: t("dashboard.company_representatives.representativeContactDisclaimer"),
            street: t("dashboard.company_representatives.street"),
            taxNumber: t("application_process.company_details.tin"),
            tinPlaceholder: t("application_process.company_details.placeholder.tin"),
            city: t("dashboard.company_representatives.city"),
            country: t("dashboard.company_representatives.country"),
            willingToSign: t("dashboard.company_representatives.willingToSign"),
            position: t("dashboard.company_representatives.position"),
        },
        formErrors: {
            fieldRequired: t("common.errors.fieldRequired"),
            fieldInvalid: t("common.errors.fieldInvalid"),
            fieldMustBeBetweenMinMaxNumbers: (min, max) => t("common.errors.fieldMustBeBetweenMinMaxNumbers", { min, max }),
            fieldMustBeBetweenMinMaxChars: (min, max) => t("common.errors.fieldMustBeBetweenMinMaxChars", { min, max }),
            fieldMustBeBetweenMinMax: (min, max) => t("common.errors.fieldMustBeBetweenMinMax", { min, max }),
            fieldMustBeMinChars: (min) => t("common.errors.fieldMustBeMinChars", { min }),
            passwordShort: t("common.errors.passwordTooShort"),
            passwordNotComplex: t("common.errors.passwordNotComplex"),
            invalidPhoneNumber: t("common.errors.invalidPhoneNumber"),
            fieldContainsInvalidCharacters: t("common.errors.fieldContainsInvalidCharacters"),
            personalTinMustBeDifferentThanCompanyTin: t("common.errors.personalTinMustBeDifferentThanCompanyTin"),
            fieldMinLength: (min) => t("common.fieldMinLength", { min }),
            fieldMaxLength: (max) => t("common.fieldMaxLength", { max }),
            fieldMinValue: (min) => t("common.errors.fieldMinValue", { min }),
            fieldMaxValue: (max) => t("common.errors.fieldMaxValue", { max }),
        },
        object: {
            object: t("dashboard.objects.object"),
            manufacturer: t("dashboard.objects.manufacturer"),
            type: t("dashboard.objects.type"),
            yearBuilt: t("dashboard.objects.yearBuilt"),
            objectDescription: t("dashboard.objects.detailedDesc"),
            netPurchasePrice: t("dashboard.objects.netPurchasePrice"),
            downPayment: t("dashboard.objects.downPayment"),
            remainingValue: t("dashboard.objects.remainingValue"),
            numberOfObjects: t("dashboard.objects.numberOfObjects"),
            financingType: t("dashboard.objects.financingType"),
            rentalState: t("dashboard.objects.rentalObject"),
            isFixedInterest: t("dashboard.objects.fixedInterest"),
            companyName: t("application_process.company_details.companyName"),
            street: t("dashboard.company_representatives.street"),
            houseNumber: t("dashboard.company_representatives.houseNumber"),
            city: t("dashboard.company_representatives.city"),
            postcode: t("dashboard.company_representatives.postcode"),
            country: t("dashboard.company_representatives.country"),
            retailerCompanyId: t("dashboard.objects.retailerCompanyId"),
            retailerName: t("dashboard.objects.retailerCompanyName"),
            retailerAddress: t("dashboard.objects.retailerAddress"),
            expectedDeliveryDate: t("dashboard.objects.expectedDeliveryDate"),
            createOrderForm: "",
            errors: {
                required: t("common.required"),
                fieldMinLength5: t("common.fieldMinLength", { min: 5 }),
                postcodeInvalid: t("common.errors.postcodeInvalid"),
            },
            placeholders: {
                manufacturer: t("dashboard.objects.placeholder.manufacturer"),
                type: t("dashboard.objects.placeholder.type"),
                yearBuilt: t("dashboard.objects.placeholder.yearBuilt"),
                objectDescription: t("dashboard.objects.placeholder.objectDescription"),
                objectCompanyName: t("dashboard.objects.placeholder.objectCompanyName"),
                street: t("application_process.company_details.placeholder.street"),
                houseNumber: t("application_process.company_details.placeholder.houseNumber"),
                city: t("application_process.company_details.placeholder.city"),
                retailerCompanyId: t("dashboard.objects.placeholder.retailerCompanyId"),
            },
            options: {
                isFixedInterest: {
                    yes: t("common.yes"),
                    no: t("common.no"),
                },
                financingTypes: {
                    leasing: t("common.loan_purpose.leasing"),
                    hire_purchase: t("common.loan_purpose.hire_purchase"),
                    financing: t("common.loan_purpose.financing"),
                    sale_leaseback: t("common.loan_purpose.sale_leaseback"),
                    credit_line: t("common.loan_purpose.credit_line"),
                    other: t("common.loan_purpose.other"),
                },
                rentalStates: {
                    no: t("dashboard.objects.no"),
                    internal: t("dashboard.objects.internal"),
                    b2c: t("dashboard.objects.b2c"),
                },
            },
            sections: {
                retailer: t("dashboard.objects.retailer"),
            },
        },
        companySelectFormItem: {
            label: t("application_process.company_details.companyName"),
            placeholder: t("application_process.company_details.companyNamePlaceholder"),
            errorCompanyNameInvalid: t("common.errors.companyNameInvalid"),
            errorCompanyNameNotFound: t("application_process.company_details.companyNameNotFoundText"),
            errorCompanyNameNotFoundLinkText: t("application_process.company_details.companyNameNotFoundLink"),
            existing: t("application_form.company_select.existing"),
        },
        industryCodes: {
            industryCode: t("company.industry_code"),
            industryCodes: t("company.industry_codes"),
            description: t("company.description"),
            weight: t("company.weight"),
            lastUpdatedOn: (date) => (_jsx(Trans, Object.assign({ i18nKey: "company.industry_code.last_updated_on" }, { children: { date } }))),
            source: (source) => (_jsx(Trans, Object.assign({ i18nKey: "company.industry_code.source" }, { children: { source } }))),
            codeType: (type) => (_jsx(Trans, Object.assign({ i18nKey: "company.industry_code.code_type" }, { children: { type } }))),
            approvedBy: ({ approvedBy, approvedAt }) => (_jsx(Trans, Object.assign({ i18nKey: "application.company.industry_code.approved_by" }, { children: { approvedBy, approvedAt } }))),
            updatedManually: t("application.company.industry_code.updated_manually"),
            modalApproveTitle: t("application.company.industry_code.modal_approve_title"),
            modalApproveDescription: t("application.company.industry_code.modal_approve_description"),
        },
        industryCodesEditModal: {
            title: t("industry_codes.title"),
            update: t("industry_codes.update"),
            type: t("industry_codes.type"),
            codeEnum: {
                wz_2008: t("industry_codes.wz_2008"),
                noga_2008: t("industry_codes.noga_2008"),
            },
            industryCodeNumber: (index) => t("industry_codes.industry_code", { index }),
            code: t("industry_codes.code"),
            codeTooltip: t("industry_codes.code.tooltip"),
            codeError: t("industry_codes.code.error"),
            weight: t("industry_codes.weight"),
            optional: t("industry_codes.optional"),
            description: t("industry_codes.description"),
            addIndustryCode: t("industry_codes.add_code"),
            fieldRequired: t("industry_codes.field-required"),
        },
        scoring: {
            overview: {
                alyze: t("company.financials.overview.alyze"),
                alyzeRating: t("company.financials.overview.alyze-rating"),
                gridRate: t("company.financials.overview.grid-rate"),
            },
            issues: {
                issues: t("company.financials.overview.issues"),
                critical: t("company.financials.overview.critical"),
                nonCritical: t("company.financials.overview.non-critical"),
                formula: (formula) => t("scoring_warning.formula", { formula }),
                value: (value) => t("scoring_warning.value", { value }),
            },
            scores: {
                scores: t("company.financials.overview.scores"),
                pdScore: t("company.financials.overview.pd-score"),
                totalScore: t("company.financials.overview.total-score"),
                ratingScore: (year) => t("company.financials.overview.rating-score", { year }),
            },
            benchmarkResults: {
                score: t("company.financials.overview.title-score"),
                mark: t("company.financials.overview.title-mark"),
                noData: t("company.financials.no-data"),
            },
            financials: {
                revenue: t("company.financials.revenue"),
                activated_costs: t("company.financials.activated_costs"),
                inventory_increase: t("company.financials.inventory_increase"),
                other_income_regular: t("company.financials.other_income_regular"),
                cogs: t("company.financials.cogs"),
                other_cor: t("company.financials.other_cor"),
                cost_of_revenue: t("company.financials.cost_of_revenue"),
                gross_profit: t("company.financials.gross_profit"),
                sga: t("company.financials.sga"),
                depreciations: t("company.financials.depreciations"),
                other_opex_costs: t("company.financials.other_opex_costs"),
                opex: t("company.financials.opex"),
                operating_profit: t("company.financials.operating_profit"),
                ebitda: t("company.financials.ebitda"),
                other_income_net: t("company.financials.other_income_net"),
                other_finance_income: t("company.financials.other_finance_income"),
                other_finance_costs: t("company.financials.other_finance_costs"),
                interest_income: t("company.financials.interest_income"),
                interest_expense: t("company.financials.interest_expense"),
                ebt: t("company.financials.ebt"),
                taxes_paid: t("company.financials.taxes_paid"),
                nopat: t("company.financials.nopat"),
                tax_rate: t("company.financials.tax_rate"),
                net_income: t("company.financials.net_income"),
                fixed_costs: t("company.financials.fixed_costs"),
                cash: t("company.financials.cash"),
                short_term_investments: t("company.financials.short_term_investments"),
                accounts_receivable: t("company.financials.accounts_receivable"),
                inventories: t("company.financials.inventories"),
                inventory_net: t("company.financials.inventory_net"),
                prepaid_expenses: t("company.financials.prepaid_expenses"),
                other_current_assets: t("company.financials.other_current_assets"),
                loan_to_shareholders: t("company.financials.loan_to_shareholders"),
                loan_to_related_companies: t("company.financials.loan_to_related_companies"),
                property_equipment_net: t("company.financials.property_equipment_net"),
                intangible_assets_net: t("company.financials.intangible_assets_net"),
                self_made_intangible: t("company.financials.self_made_intangible"),
                goodwill: t("company.financials.goodwill"),
                long_term_investments: t("company.financials.long_term_investments"),
                investments_in_related_companies: t("company.financials.investments_in_related_companies"),
                other_non_current_assets: t("company.financials.other_non_current_assets"),
                deferred_tax_assets: t("company.financials.deferred_tax_assets"),
                current_assets_published: t("company.financials.current_assets_published"),
                non_current_assets_published: t("company.financials.non_current_assets_published"),
                total_assets_published: t("company.financials.total_assets_published"),
                equity_published: t("company.financials.equity_published"),
                current_assets: t("company.financials.current_assets"),
                non_current_assets: t("company.financials.non_current_assets"),
                total_assets: t("company.financials.total_assets"),
                current_portion_of_long_debt: t("company.financials.current_portion_of_long_debt"),
                accounts_payable: t("company.financials.accounts_payable"),
                accrued_expenses: t("company.financials.accrued_expenses"),
                income_taxes_payable: t("company.financials.income_taxes_payable"),
                other_current_liabilities: t("company.financials.other_current_liabilities"),
                current_liabilities_published: t("company.financials.current_liabilities_published"),
                current_liabilities: t("company.financials.current_liabilities"),
                long_term_debt: t("company.financials.long_term_debt"),
                underfunded_retirement_plans: t("company.financials.underfunded_retirement_plans"),
                deferred_tax_liabilities: t("company.financials.deferred_tax_liabilities"),
                other_long_term_liabilities: t("company.financials.other_long_term_liabilities"),
                non_current_liabilities_published: t("company.financials.non_current_liabilities_published"),
                total_liabilities_published: t("company.financials.total_liabilities_published"),
                loan_from_shareholders: t("company.financials.loan_from_shareholders"),
                non_current_liabilities: t("company.financials.non_current_liabilities"),
                total_liabilities: t("company.financials.total_liabilities"),
                equity: t("company.financials.equity"),
                preferred_stock: t("company.financials.preferred_stock"),
                shares_issued: t("company.financials.shares_issued"),
                paid_in_capital: t("company.financials.paid_in_capital"),
                retained_earnings: t("company.financials.retained_earnings"),
                accumulated_other_comprehensive_income: t("company.financials.accumulated_other_comprehensive_income"),
                price_per_share: t("company.financials.price_per_share"),
                total_debt: t("company.financials.total_debt"),
                net_debt: t("company.financials.net_debt"),
                capital_employed: t("company.financials.capital_employed"),
                total_invested_capital: t("company.financials.total_invested_capital"),
                net_fixed_assets: t("company.financials.net_fixed_assets"),
                working_capital: t("company.financials.working_capital"),
                net_working_capital: t("company.financials.net_working_capital"),
                risk_free_rate_of_return: t("company.financials.risk_free_rate_of_return"),
                market_rate_of_return: t("company.financials.market_rate_of_return"),
                classification_by_revenue: t("company.financials.classification_by_revenue"),
                beta: t("company.financials.beta"),
                balance_sheet_sum_check: t("company.financials.balance_sheet_sum_check"),
                debt_service: t("company.financials.debt_service"),
            },
            kpiData: {
                noData: t("company.financials.no-data"),
            },
        },
        timePassed: {
            weeksAgo: (weeks) => t("common.dates.week-ago_other", { count: weeks }),
            daysAgo: (days) => t("common.dates.day-ago_other", { count: days }),
            hoursAgo: (hours) => t("common.dates.hour-ago_other", { count: hours }),
            minutesAgo: (minutes) => t("common.dates.minute-ago_other", { count: minutes }),
            momentsAgo: t("common.dates.moments-ago"),
        },
        financialsTable: {
            statementContextMenu: {
                settings: "",
                delete: "",
            },
            emptyScreen: {
                title: t("financials.empty.no-financials-title"),
                subtitle: t("financials.empty.no-financials-subtitle"),
                addStatementBtn: t("financials.empty.add-statement"),
            },
            modal: {
                titleEdit: "",
                titleAddNew: "",
                statementType: "",
                annualStatement: "",
                interimStatement: "",
                statementAsOf: "",
            },
            origin: {
                manual: t("company.financials.manual"),
                ocr: t("company.financials.ocr"),
            },
            rows: financialTableRowsToTranslate.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { 
                // @ts-ignore dynamic keys
                [curr]: t(`company.financials.${curr}`) })), {}),
        },
        factoring: {
            add: {
                financials: t("factoring.add.financials"),
                invoices: t("factoring.add.invoices"),
                debitors: t("factoring.add.debitors"),
                risk_protection: t("factoring.add.risk"),
            },
            common: {
                /* eslint-disable @typescript-eslint/no-unsafe-return */
                in_land: (value) => t("factoring.common.in_land", { value }),
                abroad: (value) => t("factoring.common.abroad", { value }),
                /* eslint-enable @typescript-eslint/no-unsafe-return */
                in_land_text: t("factoring.edit.in_land"),
                abroad_text: t("factoring.edit.abroad"),
                comment: t("factoring.common.comment"),
                no_information: t("factoring.common.no_information"),
                change: t("common.button_label.change"),
            },
            financials: {
                company_portfolio: t("factoring.financials.company_portfolio"),
                annual_sales_previous_year: t("factoring.financials.annual_sales_previous_year"),
                planned_annual_sales_following_year: t("factoring.financials.planned_annual_sales_following_year"),
                planned_annual_sales_factoring: t("factoring.financials.planned_annual_sales_factoring"),
            },
            invoices: {
                number_of_outgoing_invoices: t("factoring.invoices.number_of_outgoing_invoices"),
                invoice_currencies: t("factoring.invoices.invoice_currencies"),
                max_invoice_value_previous_year: t("factoring.invoices.max_invoice_value_previous_year"),
                payment_deadline_for_outgoing_invoices: t("factoring.invoices.payment_deadline_for_outgoing_invoices"),
            },
            debitors: {
                number_of_debitors: t("factoring.debitors.number_of_debitors"),
                debitors: t("factoring.debitors.debitors"),
                debitors_title: `${t("dashboard.objects.factoring.info_card_debitor_details_title")}:`,
                form_title: {
                    company_name: t("application_process.company_details.companyName"),
                    company_country: t("company.country"),
                    annual_sales: t("factoring.debitor.annual_sales_previous_year"),
                },
            },
            risk: {
                receivables_default_amount_previous_year: t("factoring.risk.receivables_default_amount_previous_year"),
                receivables_default_amount_current_year: t("factoring.risk.receivables_default_amount_current_year"),
                are_receivables_assigned: t("factoring.risk.are_receivables_assigned"),
                has_commercial_credit_insurance: t("factoring.risk.has_commercial_credit_insurance"),
                already_has_factoring: t("factoring.risk.already_has_factoring"),
                centralized_settlement_entity: t("factoring.risk.centralized_settlement_entity"),
            },
            forms: {
                financials: {
                    company_portfolio: t("factoring.financials.company_portfolio"),
                    annual_sales_previous_year: t("factoring.financials.annual_sales_previous_year"),
                    planned_annual_sales_following_year: t("factoring.financials.planned_annual_sales_following_year"),
                    planned_annual_sales_factoring: t("factoring.financials.planned_annual_sales_factoring"),
                    errors: {
                        /* eslint-disable @typescript-eslint/no-unsafe-return */
                        more: (max) => t("factoring.financial.data.error_more", { max }),
                        less: (max) => t("factoring.financial.data.error_less", { max }),
                        /* eslint-enable @typescript-eslint/no-unsafe-return */
                    },
                },
                invoices: {
                    number_of_outgoing_invoices: t("factoring.invoices.number_of_outgoing_invoices"),
                    invoice_currencies: t("factoring.invoices.invoice_currencies"),
                    max_invoice_value_previous_year: t("factoring.invoices.max_invoice_value_previous_year"),
                    payment_deadline_for_outgoing_invoices: t("factoring.invoices.payment_deadline_for_outgoing_invoices"),
                },
                debitors: {
                    number_of_debitors: t("factoring.debitors.number_of_debitors"),
                    debitors: t("factoring.debitors.debitors"),
                    company_name: t("application_process.company_details.companyName"),
                    country: t("company.country"),
                    annual_sales_previous_year: t("factoring.financials.annual_sales_previous_year"),
                },
                risk: {
                    yes: t("common.yes"),
                    no: t("common.no"),
                    receivables_default_amount_previous_year: t("factoring.risk.receivables_default_amount_previous_year"),
                    receivables_default_amount_current_year: t("factoring.risk.receivables_default_amount_current_year"),
                    are_receivables_assigned: t("factoring.risk.are_receivables_assigned"),
                    has_commercial_credit_insurance: t("factoring.risk.has_commercial_credit_insurance"),
                    already_has_factoring: t("factoring.risk.already_has_factoring"),
                    centralized_settlement_entity: t("factoring.risk.centralized_settlement_entity"),
                },
            },
        },
        fincr: {
            critical: t("company.fincr.critical"),
            "non-critical": t("company.fincr.non-critical"),
            json: t("company.fincr.json"),
            xlsx: t("company.fincr.xlsx"),
            issues: t("company.fincr.issues"),
            download: t("company.fincr.download"),
            rescan: t("company.fincr.rescan"),
            scan: t("company.fincr.scanBtn"),
            "upload-documents": t("company.fincr.upload-documents"),
            empty: t("company.fincr.empty"),
            rescanModalTitle: t("company.fincr.modal-title"),
            rescanModalContent: t("company.fincr.modal-content"),
            interim: t("company.fincr.interim"),
            annual: t("company.fincr.annual"),
            issuesModalTitle: t("company.fincr.ocr-issues"),
            // @ts-ignore
            documentCategory: (cat) => t(`documents.category.${cat}.name`),
            // @ts-ignore
            documentType: (type) => t(`documents.type.${type}.name`),
        },
    }), [t, i18n.language]);
    return _jsx(TranslationsProviderUI, Object.assign({ translations: translationsUI }, { children: children }));
};
