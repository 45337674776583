import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import styled from "styled-components";
import MainHeader from "@ui/main-header/MainHeader";
import { usePageTitle } from "src/hooks/usePageTitle";
import AccountTab from "./tabs/profile/AccountTab";
const TabsGroup = styled(Tabs) `
	padding: 0 20px;

	.ant-tabs-nav-wrap {
		background: #fff;
	}
`;
const SettingsPage = () => {
    const { t } = useTranslation();
    usePageTitle(t("common.page_title.settings"));
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: t("common.label.settings"), withBorderBottom: false }), _jsx(TabsGroup, { items: [{ key: "settings", label: t("settings.account"), children: _jsx(AccountTab, {}) }] })] }));
};
export default SettingsPage;
