import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Empty, Modal, Spin, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { DataCard, DataCardDivider, DataCardItem } from "@ui/data-display/DataCard";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
const Wrapper = styled.div `
	margin: 92px 24px 24px;
`;
const PersonsListGrid = styled.div `
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(460px, auto));
`;
const CompanyPersonsPage = () => {
    var _a;
    const { t } = useTranslation();
    const { loadingGlobalCompanyData, globalCompanyData } = useCompany();
    const [selectedPerson, setSelectedPerson] = useState(null);
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryHeader, { title: t("company.menu.persons") }), _jsx(Spin, Object.assign({ spinning: loadingGlobalCompanyData }, { children: _jsx(Wrapper, { children: (globalCompanyData === null || globalCompanyData === void 0 ? void 0 : globalCompanyData.persons) ? (_jsx(PersonsListGrid, { children: (_a = globalCompanyData === null || globalCompanyData === void 0 ? void 0 : globalCompanyData.persons) === null || _a === void 0 ? void 0 : _a.map((person) => {
                            const { external_person_id, first_name, last_name, birthdate, company_relation_description, address, } = person;
                            return (_jsxs(DataCard, Object.assign({ title: company_relation_description, extra: _jsx(Button, Object.assign({ type: "link", onClick: () => setSelectedPerson(person) }, { children: t("company.person.roles.view_history") })) }, { children: [_jsx(DataCardItem, { label: t("company.person.name"), value: _jsx(Typography.Text, Object.assign({ strong: true }, { children: `${first_name} ${last_name}` })) }), _jsx(DataCardItem, { label: t("company.person.birthdate"), value: birthdate ? dayjs(birthdate).format(dayMonthYear) : "-" }), _jsx(DataCardItem, { label: t("company.person.position"), value: company_relation_description }), _jsx(DataCardDivider, {}), _jsx(DataCardItem, { label: t("company.person.address"), value: address === null || address === void 0 ? void 0 : address.formattedValue })] }), external_person_id));
                        }) })) : (_jsx(Empty, {})) }) })), selectedPerson && selectedPerson.roles && (_jsx(Modal, Object.assign({ open: true, title: selectedPerson.company_relation_description, onCancel: () => setSelectedPerson(null), footer: [] }, { children: selectedPerson.roles.reverse().map((role, idx) => {
                    var _a;
                    return (_jsxs(Fragment, { children: [_jsxs("div", { children: [_jsx(DataCardItem, { label: t("company.person.name"), value: role.role_name }), _jsx(DataCardItem, { label: t("company.person.role.type"), value: role.role_type }), _jsx(DataCardItem, { label: t("company.person.role.date"), value: role.role_date ? dayjs(role.role_date).format(dayMonthYear) : "-" })] }), idx !== (((_a = selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.roles) === null || _a === void 0 ? void 0 : _a.length) || 0) - 1 && _jsx(DataCardDivider, {})] }, idx));
                }) })))] }));
};
export default CompanyPersonsPage;
