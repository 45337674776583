import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { App } from "antd";
import { AccountManagerType, AllBanksLoanPurposes, ApplicationStatuses, ApplicationUpdateConstraintsAllowedFieldsEnum, EntitlementType, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { useIndicativeRate } from "@teylor-tools/hooks/useIndicativeRates";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { AssigneeField } from "../../../../components/AssigneeField";
import { Axios } from "../../../../utils/Axios";
import { CardEditKeys } from "../../ApplicationDrilldownPage";
import OverviewCard from "./OverviewCard";
import { NotAvailableJsx } from "./OverviewComponents";
import { getCustomPricing } from "./get-custom-pricing";
const LoanInformationCard = ({ application, setEditKey, editableFields, onUpdate }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { indicativeMonthlyRate, indicativeInterestRate } = useIndicativeRate();
    const { currency, decimalToPercent } = useFormatter();
    const [loading, setLoading] = useState(false);
    const { entitlements } = useSelector((state) => state.configState);
    const isLoanBullet = application.product.product_type === "loan_bullet";
    const showCustomPricing = !!(entitlements === null || entitlements === void 0 ? void 0 : entitlements.length) &&
        entitlements.includes(EntitlementType.CustomizedPricing) &&
        !isLoanBullet;
    const isIndicative = showCustomPricing
        ? !((_a = application.pricing) === null || _a === void 0 ? void 0 : _a.is_final_interest_rate) &&
            !isValidNumericValue((_b = application.pricing) === null || _b === void 0 ? void 0 : _b.external_interest_rate) &&
            application.status !== ApplicationStatuses.ExternalPricing
        : !((_c = application.pricing) === null || _c === void 0 ? void 0 : _c.is_final_interest_rate);
    const isFactoringApplication = application.allBanksLoanPurpose === AllBanksLoanPurposes.Factoring;
    const transactionFee = useMemo(() => {
        if (isValidNumericValue(application.pricing.transaction_fee)) {
            return currency(application.pricing.transaction_fee, {
                currency: application.currency,
            });
        }
        return _jsx(NotAvailableJsx, {});
    }, [application, currency]);
    const customPricing = useMemo(() => showCustomPricing
        ? getCustomPricing({
            application,
            isIndicative,
            setEditKey,
            transactionFee,
            t,
            indicativeInterestRate,
            currency,
            decimalToPercent,
        })
        : [], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCustomPricing, application, isIndicative]);
    const rate = useMemo(() => {
        if (isIndicative) {
            return {
                label: t("dashboard.loan_information.interestRate"),
                values: [`${indicativeInterestRate(application.pricing)} (${t("common.indicative")})`],
            };
        }
        const rateToDisplay = application.pricing.visible_rate_name;
        if (!rateToDisplay)
            return {
                label: t("dashboard.loan_information.interestRate"),
                values: [_jsx(NotAvailableJsx, {}, "ir")],
            };
        const rateValue = application.pricing[rateToDisplay];
        return {
            // @ts-ignore dynamic key
            label: t(`dashboard.loan_information.${rateToDisplay}`),
            values: isValidNumericValue(rateValue)
                ? [decimalToPercent({ percent: rateValue, showPercentSymbol: true })]
                : [_jsx(NotAvailableJsx, {}, "rate")],
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.pricing, isIndicative]);
    const monthlyPayment = useMemo(() => {
        if (isIndicative) {
            const rate = indicativeMonthlyRate(application.pricing, application.currency);
            return rate ? `${rate} (${t("common.indicative")})` : _jsx(NotAvailableJsx, {});
        }
        if (isValidNumericValue(application.pricing.monthly_payment)) {
            return currency(application.pricing.monthly_payment, { currency: application.currency });
        }
        return _jsx(NotAvailableJsx, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application.pricing, isIndicative]);
    const loanData = useMemo(() => [
        {
            label: t("dashboard.loan_information.amount"),
            values: [`${currency(Number(application.loanSize), { currency: application.currency })}`],
        },
        ...(isFactoringApplication
            ? [
                {
                    label: t("dashboard.loan_information.duration"),
                    values: [`${application.loanDuration} ${t("common.months")}`],
                },
            ]
            : []),
        {
            label: t("common.monthlyRate"),
            values: [monthlyPayment],
        },
        ...(showCustomPricing
            ? customPricing
            : [
                rate,
                ...(isLoanBullet
                    ? [
                        {
                            label: t("dashboard.loan_information.transactionFee"),
                            values: [transactionFee],
                        },
                    ]
                    : []),
            ]),
        {
            label: t("dashboard.overview.type"),
            values: [
                application.allBanksLoanPurpose
                    ? t(`common.loan_purpose.${application.allBanksLoanPurpose}`)
                    : "-",
            ],
        },
        {
            label: t("dashboard.loan_information.purpose"),
            values: [application.purposeDescription],
        },
        {
            label: t("dashboard.loan_information.account_manager"),
            values: [
                _jsx(AssigneeField, { name: application.account_manager_name || t("dashboard.account_manager.unassigned"), assigneeId: application.account_manager_id, email: application.account_manager_email, onChange: (id) => updateAccountManager(id), loading: loading, editable: application.account_manager_type !== AccountManagerType.Bouser &&
                        (entitlements === null || entitlements === void 0 ? void 0 : entitlements.includes(EntitlementType.WlPartnerApplicationAccountManagement)), withUnassignOption: true }, application.account_manager_id),
            ],
        },
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application]);
    const canUpdateLoanData = !!editableFields.find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.LoanSize ||
        f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.LoanDuration);
    const updateAccountManager = (id) => {
        setLoading(true);
        Axios.put(`/partner/applications/${application.applicationId}/account_manager`, {
            account_manager_id: id,
            account_manager_type: id ? AccountManagerType.Puser : undefined,
        })
            .then(() => {
            void message.success(t("common.saved"));
            void onUpdate();
        }, (error) => {
            void Axios.error(error);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    return (_jsx(OverviewCard, { data: loanData, cardTitle: t("dashboard.loan_information.loanInformation"), cardEditKey: CardEditKeys.LoanData, editHidden: isFactoringApplication || !canUpdateLoanData, setEditKey: setEditKey }));
};
export default LoanInformationCard;
