import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Space, Typography } from "antd";
import styled from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
const dateSettings = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
};
const TooltipWrapper = styled.div `
	background-color: white;
	border: 1px solid #ccc;
	padding: 5px;
`;
const ValueText = styled(Typography.Text) `
	color: ${(props) => (props.$isPositive ? props.theme.color_primary : props.theme.colorError)};
`;
export const CustomTooltip = ({ active, payload, label }) => {
    const { t } = useTranslation();
    const { currency, date } = useFormatter();
    if (active && payload && payload.length && label) {
        const { name, value } = payload[0];
        return (_jsx(TooltipWrapper, { children: _jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(Typography.Text, { children: t("financial-graph.date", { date: date(label, dateSettings) }) }), _jsxs(Space, { children: [_jsx(Typography.Text, { children: `${t(`financial-graph.${name}`)} :` }), _jsx(ValueText, Object.assign({ "$isPositive": value >= 0 }, { children: ` ${currency(value)}` }))] })] })) }));
    }
    return null;
};
