import { jsx as _jsx } from "react/jsx-runtime";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
import DocumentsTab from "src/pages/modules/Documents/DocumentsTab";
import { ALL_DOCUMENT_EDITABLE_FIELDS } from "src/pages/modules/Documents/documents.types";
import { Axios } from "src/utils/Axios";
const CompanyDocumentsPage = () => {
    const { company } = useCompany();
    return (_jsx(DocumentsTab, { apiBasePath: `sabre/partner/companies/${company.company_id}`, 
        // @ts-ignore some mismatch between types
        apiGetDocsRequest: () => Axios.get(`sabre/partner/companies/${company.company_id}/documents`), apiGetArchivePath: `partner/companies/${company.company_id}/files_archive`, allowUpload: true, allowDelete: (doc) => !("entity_kind" in doc && doc.entity_kind === "global_company"), editableFields: (doc) => "entity_kind" in doc && doc.entity_kind === "global_company"
            ? []
            : ALL_DOCUMENT_EDITABLE_FIELDS, enableToggleClientVisibility: (doc) => doc.entity_kind !== "global_company" }));
};
export default CompanyDocumentsPage;
