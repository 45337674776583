var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CloseCircleOutlined, DownOutlined, DownloadOutlined, LeftOutlined, WarningOutlined, } from "@ant-design/icons";
import { App, Button, Dropdown, Layout, Modal, Space, Spin, Typography } from "antd";
import { Content as AntdContent } from "antd/lib/layout/layout";
import styled from "styled-components";
import MainHeader from "@ui/main-header/MainHeader";
import { RoutePaths } from "src/Routes";
import { setDocumentCategories } from "src/store/actions/configAction";
import { Axios } from "src/utils/Axios";
import { PdfReportModal } from "./components/pdf-report-modal/PdfReportModal";
import CompanyOutlet from "./modules/CompanyOutlet";
import CompanyPortfolioDetailsMenu from "./modules/CompanyPortfolioDetailsMenu";
const Content = styled(AntdContent) `
	flex: 1 1 auto;
	height: 100%;
	overflow: auto;
`;
const StyledLoader = styled.div `
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;
const HeaderWrapper = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const WarningOutlinedIcon = styled(WarningOutlined) `
	color: ${({ theme }) => theme.colorError} !important;
	font-size: 48px;
`;
const MAX_RETRIES = 4;
const RETRY_DELAY = 5000;
const CompanyPortfolioDetailsLayout = () => {
    const { companyId } = useParams();
    const { message } = App.useApp();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loadingGlobalCompanyData, setLoadingGlobalCompanyData] = useState(true);
    const [company, setCompany] = useState();
    const [globalCompanyData, setGlobalCompanyData] = useState();
    const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
    const [isGeneratePdfModalVisible, setIsGeneratePdfModalVisible] = useState(false);
    const [financials, setFinancials] = useState([]);
    const [isFinancialsLoading, setIsFinancialsLoading] = useState(true);
    const [relationshipData, setRelationshipData] = useState({});
    const [isRelationshipLoading, setIsRelationshipLoading] = useState(true);
    const getDocumentCategories = () => Axios.get(`/partner/document_configurations/document_categories`).then(({ data }) => dispatch(setDocumentCategories(data)), (err) => Axios.error(err));
    const getGlobalCompanyData = useCallback((north_data_id) => {
        return Axios.get(`/sabre/partner/global/company`, {
            north_data_id,
        }).then(({ data }) => {
            setGlobalCompanyData(data);
        }, (err) => {
            void Axios.error(err);
        });
    }, []);
    const getPortfolioCompany = useCallback(() => {
        return Axios.get(`/sabre/partner/portfolio/${companyId}`);
    }, [companyId]);
    const getCompany = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const res = yield getPortfolioCompany();
            setCompany(res.data);
            setLoading(false);
            try {
                setLoadingGlobalCompanyData(true);
                yield getGlobalCompanyData(res.data.north_data_id);
                setLoadingGlobalCompanyData(false);
            }
            catch (err) {
                void Axios.error(err);
                setLoadingGlobalCompanyData(false);
            }
        }
        catch (err) {
            void Axios.error(err);
        }
        finally {
            setLoading(false);
        }
    }), [getPortfolioCompany, getGlobalCompanyData]);
    useEffect(() => {
        void getCompany();
    }, [getCompany]);
    const handleRemoveCompany = (companyId) => {
        if (!companyId)
            return;
        Axios.delete(`/sabre/partner/portfolio/${companyId}`).then(() => {
            void message.success(t("company.remove_from_portfolio.removed"));
            setIsRemoveModalVisible(false);
            navigate(RoutePaths.CompaniesPortfolio);
        }, (err) => {
            void Axios.error(err);
        });
    };
    const updateCompany = () => {
        // TODO implement when backend is ready
        return Promise.resolve();
    };
    useEffect(() => {
        setLoading(true);
        getCompany().finally(() => {
            setLoading(false);
        });
    }, [getCompany]);
    useEffect(() => {
        void getDocumentCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getFinancialData = useCallback((retryCount = 0) => {
        if (retryCount === 0) {
            setIsFinancialsLoading(true);
        }
        if (retryCount > MAX_RETRIES) {
            setIsFinancialsLoading(false);
            return;
        }
        void Axios.get(`/sabre/partner/companies/${company === null || company === void 0 ? void 0 : company.company_id}/financial_data`)
            .then(({ data }) => {
            if (data && data.financials.length) {
                setFinancials(data.financials);
                setIsFinancialsLoading(false);
            }
            else {
                setTimeout(() => getFinancialData(retryCount + 1), RETRY_DELAY);
            }
        })
            .catch((err) => {
            void Axios.error(err);
            setIsFinancialsLoading(false);
        });
    }, [company === null || company === void 0 ? void 0 : company.company_id]);
    useEffect(() => {
        (company === null || company === void 0 ? void 0 : company.company_id) && void getFinancialData();
    }, [company === null || company === void 0 ? void 0 : company.company_id, getFinancialData]);
    const getCompanyRelationshipData = useCallback(() => {
        if (!(company === null || company === void 0 ? void 0 : company.company_id))
            return;
        void Axios.get(`/sabre/partner/companies/${company === null || company === void 0 ? void 0 : company.company_id}/relation_graph`)
            .then(({ data }) => setRelationshipData(data))
            .catch((err) => {
            void Axios.error(err);
        })
            .finally(() => setIsRelationshipLoading(false));
    }, [company === null || company === void 0 ? void 0 : company.company_id]);
    useEffect(() => {
        void getCompanyRelationshipData();
    }, [getCompanyRelationshipData]);
    return (_jsxs(_Fragment, { children: [_jsxs(Layout, Object.assign({ style: { minHeight: "100vh" } }, { children: [_jsx(MainHeader, { title: _jsx(Spin, Object.assign({ spinning: loading }, { children: company && (_jsxs(HeaderWrapper, { children: [_jsx(LeftOutlined, { onClick: () => {
                                            navigate(RoutePaths.CompaniesPortfolio);
                                        } }), company.company_name] })) })), extra: company && (_jsx(Dropdown, Object.assign({ placement: "bottomRight", trigger: ["click"], menu: {
                                items: [
                                    {
                                        label: t("company.generate_pdf_report"),
                                        key: "pdf_report",
                                        icon: _jsx(DownloadOutlined, {}),
                                        onClick: () => setIsGeneratePdfModalVisible(true),
                                    },
                                    {
                                        label: t("company.remove_from_portfolio"),
                                        key: "delete",
                                        icon: _jsx(CloseCircleOutlined, {}),
                                        danger: true,
                                        onClick: () => setIsRemoveModalVisible(true),
                                    },
                                ],
                            } }, { children: _jsx(Button, Object.assign({ loading: loading }, { children: _jsxs(Space, { children: [t("company.actions"), _jsx(DownOutlined, {})] }) })) }))) }), _jsxs(Layout, Object.assign({ style: { flexDirection: "row" } }, { children: [company && _jsx(CompanyPortfolioDetailsMenu, { companyId: company.partner_portfolio_item_id }), _jsx(Content, Object.assign({ className: "application-layout-content" }, { children: company ? (_jsx(CompanyOutlet, { company: company, financials: financials, updateFinancials: () => {
                                        if (!isFinancialsLoading)
                                            getFinancialData();
                                    }, isFinancialsLoading: isFinancialsLoading, updateCompany: updateCompany, globalCompanyData: globalCompanyData, loadingGlobalCompanyData: loadingGlobalCompanyData, relationshipData: relationshipData, isRelationshipLoading: isRelationshipLoading })) : (_jsx(StyledLoader, { children: _jsx(Spin, { size: "large" }) })) }))] }))] })), isRemoveModalVisible && (_jsx(Modal, Object.assign({ title: t("company.remove_from_portfolio.title"), onOk: () => {
                    handleRemoveCompany(company === null || company === void 0 ? void 0 : company.partner_portfolio_item_id);
                }, onCancel: () => setIsRemoveModalVisible(false), okButtonProps: { danger: true }, okText: t("common.remove"), open: isRemoveModalVisible }, { children: _jsxs(Space, Object.assign({ direction: "vertical", align: "center", size: "large", style: { padding: "0 64px", textAlign: "center", marginBottom: 16 } }, { children: [_jsx(WarningOutlinedIcon, {}), _jsx(Typography.Text, Object.assign({ strong: true }, { children: t("company.remove_from_portfolio.are_you_sure") })), _jsx(Typography.Text, { children: t("company.remove_from_portfolio.text_1") }), _jsx(Typography.Text, { children: t("company.remove_from_portfolio.text_2") }), _jsx(Typography.Text, { children: t("company.remove_from_portfolio.text_3") })] })) }))), isGeneratePdfModalVisible && (company === null || company === void 0 ? void 0 : company.company_id) && (company === null || company === void 0 ? void 0 : company.company_name) && (_jsx(PdfReportModal, { isGeneratePdfModalVisible: isGeneratePdfModalVisible, setIsGeneratePdfModalVisible: setIsGeneratePdfModalVisible, companyId: company.company_id, companyName: company.company_name }))] }));
};
export default CompanyPortfolioDetailsLayout;
