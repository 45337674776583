import { useTranslation } from "react-i18next";
import { PendingActionType } from "@teylor-tools/Api";
import { CardEditKeys, TabPaneKeys } from "../../ApplicationDrilldownPage";
const usePendingItems = (rawPendingItems) => {
    const { t } = useTranslation();
    const pendingItemExists = (actionType) => rawPendingItems.some((action) => action.action === actionType);
    const generatePendingItems = (setEditKey, setActiveTabPane) => {
        const pendingItems = [];
        if (pendingItemExists(PendingActionType.BankNameIbanValid)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addBankInfo"),
                buttonText: t("common.button_label.add"),
                onClick: () => setEditKey(CardEditKeys.BankInfo),
            });
        }
        if (pendingItemExists(PendingActionType.CompanyTinSpecified)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addTaxIdNumber"),
                buttonText: t("common.button_label.add"),
                onClick: () => setEditKey(CardEditKeys.Tin),
            });
        }
        if (pendingItemExists(PendingActionType.SignatoriesSpecified) ||
            pendingItemExists(PendingActionType.SignatoriesSoleOrAtleast2)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addCompanyRepresentatives"),
                buttonText: t("common.button_label.add"),
                onClick: () => setActiveTabPane(TabPaneKeys.CompanyRepresentatives),
            });
        }
        // do not show SignatoriesMinimumDataCompleted if SignatoriesSpecified exists, they overlap
        if (pendingItemExists(PendingActionType.SignatoriesMinimumDataCompleted) &&
            !pendingItemExists(PendingActionType.SignatoriesSpecified)) {
            pendingItems.push({
                text: t("dashboard.pending_items.complete-representatives-data"),
                buttonText: t("common.button_label.complete"),
                onClick: () => setActiveTabPane(TabPaneKeys.CompanyRepresentatives),
            });
        }
        if (pendingItemExists(PendingActionType.DocumentAnnualStatementsUploaded) ||
            pendingItemExists(PendingActionType.DocumentBwaSusaUploaded) ||
            pendingItemExists(PendingActionType.AllRequiredDocumentsUploaded)) {
            pendingItems.push({
                text: t("dashboard.pending_items.uploadDocuments"),
                buttonText: t("common.button_label.upload"),
                onClick: () => setActiveTabPane(TabPaneKeys.Documents),
            });
        }
        if (pendingItemExists(PendingActionType.DocumentCurrentDebtRegisterUploaded)) {
            pendingItems.push({
                text: t("dashboard.pending_items.debtRegister"),
                buttonText: t("common.button_label.upload"),
                onClick: () => setActiveTabPane(TabPaneKeys.Documents),
            });
        }
        if (pendingItemExists(PendingActionType.ApplicationExternalPricingSpecified)) {
            pendingItems.push({
                text: t("dashboard.pending_items.setFinalInterestRate"),
                buttonText: t("common.button_label.set"),
                onClick: () => setEditKey(CardEditKeys.Pricing),
            });
        }
        if (pendingItemExists(PendingActionType.FinanceObjectsAdded)) {
            pendingItems.push({
                text: t("dashboard.pending_items.addObjects"),
                buttonText: t("common.button_label.add"),
                onClick: () => setActiveTabPane(TabPaneKeys.Objects),
            });
        }
        if (pendingItemExists(PendingActionType.RepresentativesDataCompleted)) {
            pendingItems.push({
                text: t("dashboard.pending_items.complete_representatives_data"),
                buttonText: t("common.button_label.edit"),
                onClick: () => setActiveTabPane(TabPaneKeys.CompanyRepresentatives),
            });
        }
        if (pendingItemExists(PendingActionType.FactoringDetailsAdded)) {
            pendingItems.push({
                text: t("dashboard.pending_items.factoring_edit"),
                buttonText: t("common.button_label.edit"),
                onClick: () => setActiveTabPane(TabPaneKeys.Factoring),
            });
        }
        return pendingItems;
    };
    return {
        generatePendingItems,
        pendingItemExists,
    };
};
export default usePendingItems;
