import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, List, Typography } from "antd";
import styled from "styled-components";
import usePendingItems from "./usePendingItems";
const ListItem = styled(List.Item) `
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
`;
const PendingItemsCard = ({ rawPendingItems, setEditKey, setActiveTabPane }) => {
    const { t } = useTranslation();
    const { generatePendingItems } = usePendingItems(rawPendingItems);
    const pendingItems = useMemo(() => generatePendingItems(setEditKey, setActiveTabPane), [generatePendingItems, setActiveTabPane, setEditKey]);
    if (!pendingItems.length)
        return _jsx(_Fragment, {});
    return (_jsx(List, { header: _jsx(Typography.Text, Object.assign({ strong: true }, { children: t("dashboard.pending_items.pendingItems") })), style: { background: "#FFF", borderColor: "rgba(0,0,0,0.06)" }, bordered: true, dataSource: pendingItems, renderItem: (item) => (_jsxs(ListItem, { children: [_jsx(Typography.Text, { children: item.text }), _jsx(Button, Object.assign({ type: "primary", ghost: true, onClick: item.onClick }, { children: item.buttonText }))] })) }));
};
export default PendingItemsCard;
