import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { App, Col, Form, Modal, Row, Select } from "antd";
import Textarea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import { AllBanksLoanPurposes, LoanPurpose } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import { Axios } from "src/utils/Axios";
import { LOAN_AMOUNT } from "src/utils/loanAmount";
import { LOAN_DURATION } from "src/utils/loanDuration";
const { Option } = Select;
const LoanDataModal = ({ application, modalProps, onSend, canUpdateAmount, canUpdateDuration, canUpdatePurpose, shouldValidateAmount, }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { t } = useTranslation();
    const isAkf = application.allBanksLoanPurpose === AllBanksLoanPurposes.PurposeAkfBike;
    const [sending, setSending] = useState(false);
    const { currency } = useFormatter();
    const { loanPurposes } = useSelector((state) => state.configState);
    const { loanDuration, loanSize, allBanksLoanPurpose: loanType } = application;
    const [selectedLoanPurpose, setSelectedLoanPurpose] = useState(LoanPurpose.Other);
    const [loanPurposeText, setLoanPurposeText] = useState(application.purposeDescription);
    const showLoanPurposeTextarea = selectedLoanPurpose === LoanPurpose.Other ||
        (loanPurposes.length === 1 && loanPurposes.includes(LoanPurpose.Other)) ||
        !loanPurposes.length;
    const initialValues = {
        loanDuration: Number(loanDuration),
        loanSize,
        purposeDescription: selectedLoanPurpose,
    };
    const handleSubmit = (values) => {
        setSending(true);
        const purposeDescription = selectedLoanPurpose === LoanPurpose.Other
            ? loanPurposeText
            : t(`application.purpose_option.${selectedLoanPurpose}`);
        const updateParameters = Axios.patch(`/partner/applications/${application.applicationId}/parameters`, {
            loan_amount: values.loanSize,
            loan_duration: values.loanDuration,
        });
        const updatePurpose = Axios.patch(`/partner/applications/${application.applicationId}`, {
            purposeDescription,
        });
        Promise.all([updateParameters, updatePurpose])
            .then(() => {
            onSend();
            void message.success(t("common.saved"));
        }, (error) => void Axios.error(error))
            .finally(() => setSending(false));
    };
    const validateAmount = (rule, value) => {
        if (value === null || !loanType) {
            return Promise.resolve();
        }
        const minLoanAmount = LOAN_AMOUNT.min[loanType];
        if (minLoanAmount && value < minLoanAmount) {
            return Promise.reject(`${t("common.errors.minFinancingAmount")}: ${currency(minLoanAmount, {
                currency: application.currency,
                showFractions: false,
            })}`);
        }
        const maxLoanAmount = LOAN_AMOUNT.max[loanType];
        if (maxLoanAmount && value > maxLoanAmount) {
            return Promise.reject(`${t("common.errors.maxFinancingAmount")}: ${currency(maxLoanAmount, {
                showFractions: false,
                currency: application.currency,
            })}`);
        }
        return Promise.resolve();
    };
    const validateLoanDuration = (rule, value) => {
        if (value === null || !loanType) {
            return Promise.resolve();
        }
        const minLoanDuration = LOAN_DURATION.min[loanType];
        if (minLoanDuration && value < minLoanDuration) {
            return Promise.reject(`${t("common.errors.minLoanDuration")} ${minLoanDuration} ${t("common.months")}`);
        }
        const maxLoanDuration = LOAN_DURATION.max[loanType];
        if (maxLoanDuration && value > maxLoanDuration) {
            return Promise.reject(`${t("common.errors.maxLoanDuration")} ${maxLoanDuration} ${t("common.months")}`);
        }
        return Promise.resolve();
    };
    return (_jsx(Modal, Object.assign({ okText: t("common.button_label.save"), onOk: () => form.submit(), okButtonProps: { loading: sending }, destroyOnClose: true }, modalProps, { width: 650 }, { children: _jsxs(Form, Object.assign({ form: form, layout: "vertical", initialValues: initialValues, onFinish: handleSubmit, preserve: false, style: { marginBottom: 24 } }, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: t("dashboard.loan_information.custom_fields_modal.title.edit_data") })), _jsxs(Row, Object.assign({ gutter: [32, 32] }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ name: "loanSize", label: t("application_process.loan_details.loanAmount"), style: { margin: 0 }, rules: [
                                    { required: true, message: t("common.errors.fieldRequired") },
                                    ...(shouldValidateAmount
                                        ? [
                                            {
                                                validator: validateAmount,
                                            },
                                        ]
                                        : []),
                                ] }, { children: _jsx(CurrencyInput, { disabled: !canUpdateAmount, style: { width: "100%" }, placeholder: t("application_process.loan_details.loanAmount.placeholder"), precision: isAkf ? 0 : undefined, currency: application.currency }) })) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ name: "loanDuration", label: t("application_process.loan_details.loanDuration"), style: { margin: 0 }, rules: [
                                    {
                                        validator: validateLoanDuration,
                                    },
                                    { required: true, message: t("common.errors.fieldRequired") },
                                ] }, { children: _jsx(NumberInputLocalized, { disabled: !canUpdateDuration, style: { width: "100%" }, controls: false, precision: 0, placeholder: t("application_process.loan_details.loanDuration.placeholder"), addonAfter: t("common.months").charAt(0).toUpperCase() + t("common.months").slice(1) }) })) })), _jsxs(Col, Object.assign({ span: 24 }, { children: [loanPurposes.length > 1 && (_jsx(Form.Item, Object.assign({ name: "purposeDescription", label: t("application_process.loan_details.detailedLoanPurpose") }, { children: _jsx(Select, Object.assign({ disabled: !canUpdatePurpose, onChange: (value) => setSelectedLoanPurpose(value) }, { children: loanPurposes.map((key) => (_jsx(Option, Object.assign({ value: key }, { children: t(`application.purpose_option.${key}`) }), key))) })) }))), showLoanPurposeTextarea && (_jsx(Textarea, { disabled: !canUpdatePurpose, style: { marginTop: 8 }, value: loanPurposeText, onChange: (e) => setLoanPurposeText(e.target.value), maxLength: 700, showCount: true, rows: 6, "data-cy": "purpose-desc-field" }))] }))] }))] })) })));
};
export default LoanDataModal;
