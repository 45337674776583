import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Select, Spin, Tooltip, Typography } from "antd";
import styled from "styled-components";
import { AccountManagerType } from "@teylor-tools/Api";
import Avatar from "@ui/data-display/Avatar";
import { Axios } from "src/utils/Axios";
const Wrapper = styled.div `
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 16px;
`;
const usePusers = () => {
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(true);
    useEffect(() => {
        void Axios.get(`partner/pusers`)
            .then(({ data }) => {
            setUsers(data.result || []);
        }, (err) => void Axios.error(err))
            .finally(() => setUsersLoading(false));
    }, []);
    return { users, usersLoading };
};
const filterSelectByIdAndLabel = (inputValue, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
option) => {
    const input = inputValue.toLowerCase();
    return (String(option === null || option === void 0 ? void 0 : option.label).toLowerCase().includes(input) ||
        String(option === null || option === void 0 ? void 0 : option.value).toLowerCase().includes(input));
};
const AssigneeWrapper = styled.div `
	display: grid;
	grid-template-columns: 24px auto;
	align-items: center;
	grid-gap: 8px;
	height: 30px;
`;
const NameWrapper = styled.div `
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	grid-gap: 16px;
`;
const EditButtons = ({ editMode, setEditMode, onSave }) => {
    if (editMode) {
        return (_jsxs(Wrapper, { children: [_jsx(Button, { type: "link", onClick: onSave, icon: _jsx(CheckOutlined, {}) }), _jsx(Button, { type: "link", onClick: () => setEditMode(false), icon: _jsx(CloseOutlined, {}) })] }));
    }
    return _jsx(Button, { type: "link", onClick: () => setEditMode(true), icon: _jsx(EditOutlined, {}) });
};
export const AssigneeField = ({ assigneeId = null, assigneeType, name, email, loading = false, onChange, withUnassignOption = false, editable = true, }) => {
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const { users, usersLoading } = usePusers();
    const [selectedUserId, setSelectedUserId] = useState(assigneeId);
    return (_jsxs(Wrapper, { children: [editMode && onChange ? (_jsxs(Select, Object.assign({ defaultValue: assigneeId, style: { width: "100%" }, loading: usersLoading || loading, disabled: usersLoading, filterOption: filterSelectByIdAndLabel, value: selectedUserId, onChange: (userId) => {
                    setSelectedUserId(userId);
                }, allowClear: true }, { children: [withUnassignOption && (_jsx(Select.Option, Object.assign({ value: null }, { children: _jsxs(AssigneeWrapper, { children: [_jsx(Avatar, { name: t("dashboard.account_manager.unassigned"), size: "small" }), _jsx(Typography.Text, Object.assign({ ellipsis: true }, { children: t("dashboard.account_manager.unassigned") }))] }) }))), assigneeType === AccountManagerType.Puser && (_jsx(Select.Option, Object.assign({ value: assigneeId }, { children: _jsxs(AssigneeWrapper, { children: [_jsx(Avatar, { name: name, size: "small" }), _jsx(NameWrapper, { children: _jsx(Typography.Text, Object.assign({ ellipsis: true }, { children: name })) })] }) }))), users.map(({ entity_id, name }) => (_jsx(Select.Option, Object.assign({ value: entity_id }, { children: _jsxs(AssigneeWrapper, { children: [_jsx(Avatar, { name: name || "", size: "small" }), _jsx(Typography.Text, Object.assign({ ellipsis: true }, { children: name }))] }) }), entity_id)))] }))) : (_jsx(Spin, Object.assign({ spinning: loading }, { children: _jsxs(AssigneeWrapper, { children: [_jsx(Avatar, { name: name, size: "small" }), _jsx(Tooltip, Object.assign({ mouseEnterDelay: 1, title: !!email && (_jsxs(_Fragment, { children: [_jsx("div", { children: name }), _jsx("div", { children: t("user_email", { email }) })] })) }, { children: assigneeType === AccountManagerType.Puser ? (_jsx(NameWrapper, { children: _jsx(Typography.Text, Object.assign({ ellipsis: true }, { children: name })) })) : (_jsx(Typography.Text, Object.assign({ ellipsis: true }, { children: name }))) }))] }) }))), editable && onChange && (_jsx(EditButtons, { editMode: editMode, setEditMode: setEditMode, onSave: () => {
                    if (selectedUserId !== assigneeId) {
                        onChange(selectedUserId || null);
                    }
                    setEditMode(false);
                } }))] }));
};
