var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Card, Typography, theme } from "antd";
import styled from "styled-components";
import { DocumentCategory, DocumentType } from "@teylor-tools/Api";
import { DOCUMENTS_CARD_MAX_WIDTH, DOCUMENTS_CARD_MIN_WIDTH, } from "@teylor-tools/utils/documents/documents.variables";
import DocumentRow from "src/pages/modules/Documents/DocumentRow";
import DocumentTypeWrapper from "src/pages/modules/Documents/DocumentTypeWrapper";
import { isDocumentInUpload, } from "src/pages/modules/Documents/documents.helpers";
const { useToken } = theme;
const { Text } = Typography;
const DocumentCategoryWrapper = styled(Card) `
	margin-bottom: 24px;
	min-width: ${DOCUMENTS_CARD_MIN_WIDTH}px;

	.ant-typography {
		word-break: keep-all;
	}

	max-width: ${DOCUMENTS_CARD_MAX_WIDTH}px;
`;
const CardTitle = styled.div `
	display: flex;
	gap: 16px;
`;
const DocumentCategoryCard = (_a) => {
    var { categoryData, showCategoryTitle = true, showTypeTitle = true } = _a, props = __rest(_a, ["categoryData", "showCategoryTitle", "showTypeTitle"]);
    const { t } = useTranslation();
    const { token } = useToken();
    return (_jsx(DocumentCategoryWrapper, Object.assign({ title: showCategoryTitle && (_jsx(CardTitle, { children: categoryData.categoryName === DocumentCategory.None ? (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ style: { color: token.colorPrimary } }, { children: t(`documents.category.${DocumentCategory.None}.name`) })), _jsx(Text, Object.assign({ style: { fontWeight: "normal" }, type: "secondary" }, { children: t("documents.description") }))] })) : (_jsx(Text, { children: t(`documents.category.${categoryData.categoryName}.name`) })) })), bodyStyle: { padding: 0 } }, { children: categoryData.types.map(({ typeName, docs }) => {
            if (!docs.length)
                return null;
            return (_jsx(DocumentTypeWrapper, Object.assign({ name: typeName, showName: typeName !== DocumentType.None && showTypeTitle }, { children: docs.map((doc) => (_jsx(DocumentRow, { doc: doc, apiBasePath: props.apiBasePath, updateDocuments: props.updateDocuments, editableFields: props.editableFields, allowDelete: props.allowDelete, enableToggleClientVisibility: props.enableToggleClientVisibility, removeDocFromUpload: props.removeDocFromUpload, updateDocumentInUpload: props.updateDocumentInUpload, docIdsInSelection: props.docIdsInSelection, handleSelectChange: props.handleSelectChange }, isDocumentInUpload(doc) ? doc.updateId : doc.document_id))) }), typeName));
        }) })));
};
export default DocumentCategoryCard;
