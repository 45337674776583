import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Empty, Typography } from "antd";
import dayjs from "dayjs";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import styled, { useTheme } from "styled-components";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { CustomTooltip } from "./CustomTooltip";
const ChartWrapper = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 24px;
	min-height: 400px;
`;
const StyledNoData = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 400px;
`;
export const CustomBarChart = ({ data, dataKey }) => {
    const { t } = useTranslation();
    const { compactNumber } = useFormatter();
    const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
    const chartRef = useRef(null);
    const theme = useTheme();
    useEffect(() => {
        const updateSize = () => {
            if (chartRef.current) {
                setChartSize({
                    width: chartRef.current.offsetWidth,
                    height: chartRef.current.offsetHeight,
                });
            }
        };
        setTimeout(updateSize, 100);
        window.addEventListener("resize", updateSize);
        return () => {
            window.removeEventListener("resize", updateSize);
        };
    }, [data]);
    if (!data || data.length === 0) {
        return (_jsx(StyledNoData, { children: _jsx(Empty, { description: _jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("financial-graph.no-data", { dataKey: t(`financial-graph.${dataKey}`) }) })) }) }));
    }
    return (_jsx(ChartWrapper, Object.assign({ ref: chartRef }, { children: _jsxs(BarChart, Object.assign({ width: chartSize.width, height: chartSize.height, data: data, margin: { right: 24 } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "date", tickFormatter: (tick) => {
                        const year = dayjs(tick).format("YY");
                        return `'${year}`;
                    } }), _jsx(YAxis, { tickFormatter: (tick) => compactNumber(tick) }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), _jsx(Bar, { fill: theme.color_primary, dataKey: dataKey, minPointSize: 10 })] })) })));
};
