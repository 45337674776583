import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from "react";
import { BaseEdge, EdgeLabelRenderer, Position, getStraightPath, useStore, } from "reactflow";
import { ActiveNodeContext } from "./InnerRelationshipGraph";
export const Edge = ({ id, source, target, markerEnd, style, label }) => {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    const activeNodeId = useContext(ActiveNodeContext);
    const isLabelVisible = activeNodeId && (source === activeNodeId || target === activeNodeId);
    if (!sourceNode || !targetNode) {
        console.error("One of the nodes is undefined:", { sourceNode, targetNode });
        return null;
    }
    const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);
    if (!sx || !sy || !tx || !ty) {
        return null;
    }
    const [edgePath, labelX, labelY] = getStraightPath({
        sourceX: sx,
        sourceY: sy,
        targetX: tx,
        targetY: ty,
    });
    return (_jsxs(_Fragment, { children: [_jsx(BaseEdge, { id: id, path: edgePath, markerEnd: markerEnd, style: style }), _jsx(EdgeLabelRenderer, { children: _jsx("div", Object.assign({ style: {
                        position: "absolute",
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        zIndex: 10,
                        margin: "0",
                        backgroundColor: "white",
                    }, className: "nodrag nopan" }, { children: isLabelVisible && label })) })] }));
};
function getEdgeParams(source, target) {
    const sourceIntersectionPoint = getNodeIntersection(source, target);
    const targetIntersectionPoint = getNodeIntersection(target, source);
    const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
    const targetPos = getEdgePosition(target, targetIntersectionPoint);
    return {
        sx: sourceIntersectionPoint.x,
        sy: sourceIntersectionPoint.y,
        tx: targetIntersectionPoint.x,
        ty: targetIntersectionPoint.y,
        sourcePos,
        targetPos,
    };
}
function getNodeIntersection(intersectionNode, targetNode) {
    // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
    const { width: intersectionNodeWidth, height: intersectionNodeHeight, positionAbsolute: intersectionNodePosition, } = intersectionNode;
    if (!intersectionNodeWidth ||
        !intersectionNodeHeight ||
        !intersectionNodePosition ||
        !targetNode.width ||
        !targetNode.height ||
        !targetNode.positionAbsolute) {
        // Handle the error accordingly, provide default values
        return { x: 0, y: 0 };
    }
    const targetPosition = targetNode.positionAbsolute;
    const w = intersectionNodeWidth / 2;
    const h = intersectionNodeHeight / 2;
    const x2 = intersectionNodePosition.x + w;
    const y2 = intersectionNodePosition.y + h;
    const x1 = targetPosition.x + targetNode.width / 2;
    const y1 = targetPosition.y + targetNode.height / 2;
    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w * (xx3 + yy3) + x2;
    const y = h * (-xx3 + yy3) + y2;
    return { x, y };
}
// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
    // Check if positionAbsolute is defined
    if (!node.positionAbsolute) {
        // Handle this case appropriately, possibly with a default value or an error
        throw new Error("Node position is not defined");
    }
    const nx = Math.round(node.positionAbsolute.x);
    const ny = Math.round(node.positionAbsolute.y);
    const px = Math.round(intersectionPoint.x);
    const py = Math.round(intersectionPoint.y);
    // Check if width and height are defined
    if (typeof node.width !== "number" || typeof node.height !== "number") {
        // Handle this case appropriately, possibly with a default value or an error
        throw new Error("Node width or height is not defined");
    }
    if (px <= nx + 1) {
        return Position.Left;
    }
    if (px >= nx + node.width - 1) {
        return Position.Right;
    }
    if (py <= ny + 1) {
        return Position.Top;
    }
    if (py >= ny + node.height - 1) {
        return Position.Bottom;
    }
    return Position.Top;
}
