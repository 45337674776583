export var ConfigAction;
(function (ConfigAction) {
    ConfigAction["SET_CONFIG"] = "SET_CONFIG";
    ConfigAction["SET_COUNTRY_LIST"] = "SET_COUNTRY_LIST";
    ConfigAction["SET_DOCUMENT_CATEGORIES"] = "SET_DOCUMENT_CATEGORIES";
})(ConfigAction || (ConfigAction = {}));
export const setConfig = (payload) => {
    return {
        type: ConfigAction.SET_CONFIG,
        payload,
    };
};
export const setCountryList = ({ preferredCountries, countries, }) => {
    return {
        type: ConfigAction.SET_COUNTRY_LIST,
        payload: {
            preferredCountries,
            countries,
        },
    };
};
export const setDocumentCategories = (documentCategories) => {
    return {
        type: ConfigAction.SET_DOCUMENT_CATEGORIES,
        payload: {
            documentCategories,
        },
    };
};
