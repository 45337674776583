var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Space, Spin, Typography } from "antd";
import styled from "styled-components";
import { Axios } from "src/utils/Axios";
const StyledPdfWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
`;
const CloseCircleOutlinedIcon = styled(CloseCircleOutlined) `
	color: rgba(0, 0, 0, 0.25);
	font-size: 62px;
	margin-bottom: 25px;
`;
const CheckCircleOutlinedIcon = styled(CheckCircleOutlined) `
	color: ${({ theme }) => theme.colorPrimary} !important;
	font-size: 62px;
	margin-bottom: 25px;
`;
const checkboxes = [
    { name: "company_information", disabled: true },
    { name: "include_events", disabled: false },
    { name: "include_persons", disabled: false },
];
const defaultState = checkboxes.reduce((acc, checkbox) => {
    acc[checkbox.name] = true;
    return acc;
}, {});
export const PdfReportModal = ({ companyId, companyName, isGeneratePdfModalVisible, setIsGeneratePdfModalVisible, }) => {
    const { t } = useTranslation();
    const [isPdfGenerated, setIsPdfGenerated] = useState(false);
    const [isPdfGeneratedError, setIsPdfGeneratedError] = useState(false);
    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const [checkboxState, setCheckboxState] = useState(defaultState);
    const buildUrl = useMemo(() => {
        const params = Object.entries(checkboxState)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
        return `/sabre/partner/companies/${companyId}/pdf_export?language=de&country=DE&${params}`;
    }, [checkboxState, companyId]);
    const handleGenerate = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsPdfLoading(true);
        try {
            yield Axios.downloadGeneratedFile(`${buildUrl}`, `${companyName}`);
            setIsPdfGenerated(true);
        }
        catch (e) {
            setIsPdfGeneratedError(true);
        }
        finally {
            setIsPdfLoading(false);
        }
    }), [buildUrl, companyName]);
    const handleClosePdfModal = useCallback(() => {
        setIsGeneratePdfModalVisible(false);
        setIsPdfGenerated(false);
        setIsPdfGeneratedError(false);
        setCheckboxState(defaultState);
    }, [setIsGeneratePdfModalVisible]);
    const handleCheckboxChange = useCallback((e) => {
        setCheckboxState(Object.assign(Object.assign({}, checkboxState), { [e.target.name]: e.target.checked }));
    }, [checkboxState]);
    const renderFooter = useMemo(() => {
        if (isPdfGenerated || isPdfGeneratedError) {
            return (_jsx(Button, Object.assign({ type: "primary", onClick: handleClosePdfModal }, { children: t("pdf_report.footer.close") }), "close"));
        }
        return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: handleClosePdfModal }, { children: t("pdf_report.footer.cancel") }), "cancel"), _jsx(Button, Object.assign({ type: "primary", loading: isPdfLoading, onClick: () => void handleGenerate() }, { children: t("pdf_report.footer.generate") }), "generate")] }));
    }, [isPdfGenerated, isPdfGeneratedError, handleClosePdfModal, t, isPdfLoading, handleGenerate]);
    const renderContent = useMemo(() => {
        if (isPdfGenerated) {
            return (_jsxs(StyledPdfWrapper, { children: [_jsx(CheckCircleOutlinedIcon, {}), _jsx(Typography.Text, { children: t("company.pdf_report_generated.text1") })] }));
        }
        else if (isPdfLoading) {
            return (_jsxs(StyledPdfWrapper, { children: [_jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 62, marginBottom: "25px" }, spin: true }) }), _jsx(Typography.Text, { children: t("company.pdf_report_loading.text1") }), _jsx(Typography.Text, { children: t("company.pdf_report_loading.text2") })] }));
        }
        else if (isPdfGeneratedError) {
            return (_jsxs(StyledPdfWrapper, { children: [_jsx(CloseCircleOutlinedIcon, {}), _jsx(Typography.Text, { children: t("company.pdf_report_error.text1") }), _jsx(Typography.Text, { children: t("company.pdf_report_error.text2") })] }));
        }
        return (_jsxs(Space, Object.assign({ direction: "vertical", size: "middle", style: { margin: "24px 0" } }, { children: [_jsx(Typography.Text, { children: t("company.pdf_report.title") }), checkboxes.map(({ name, disabled }) => (_jsx(Checkbox, Object.assign({ name: name, checked: checkboxState[name], disabled: disabled, onChange: handleCheckboxChange }, { children: t(`company.pdf_report_part.${name}`) }), name)))] })));
    }, [checkboxState, handleCheckboxChange, isPdfGenerated, isPdfGeneratedError, isPdfLoading, t]);
    return (_jsx(Modal, Object.assign({ open: isGeneratePdfModalVisible, title: t("company.generate_pdf_report.title"), onCancel: handleClosePdfModal, footer: renderFooter, styles: { body: { height: "275px" } } }, { children: renderContent })));
};
