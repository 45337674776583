var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, FileOutlined, } from "@ant-design/icons";
import { App, Checkbox, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Tag, Tooltip, Typography, theme, } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import { DocumentType, } from "@teylor-tools/Api";
import { dayMonthYear, rawDateFormat } from "@teylor-tools/utils/dateFormats";
import TimePassed from "@ui/time-passed/TimePassed";
import { handleDocumentUpload, isDocument, isDocumentInUpload, } from "src/pages/modules/Documents/documents.helpers";
import { DocumentEditableField, } from "src/pages/modules/Documents/documents.types";
import { Axios } from "src/utils/Axios";
const { Text } = Typography;
const DocumentRowWrapper = styled(Row) `
	padding: 20px 24px;
	margin: 0 !important;

	&:hover {
		background-color: #fafafa;
	}
`;
const DocumentCheckbox = styled(Checkbox) `
	& .ant-checkbox {
		top: 0;
	}
`;
const Actions = styled.div `
	display: flex;
	justify-content: flex-end;
`;
const NameInput = styled(Input) `
	min-width: 110px;
	max-width: 225px;
	display: inline-block;
	margin-right: -5px;
`;
const FileWithIcon = styled.div `
	display: flex;
	text-align: left !important;
	align-items: center;
	gap: 10px;
`;
const NameAndExtension = styled.div `
	display: flex;
	align-items: center;
`;
const DataText = styled(Text) `
	word-break: break-all !important;
`;
const CategorySelect = styled(Select) `
	min-width: 200px;
	margin-left: 16px;
`;
const getUploadStatus = (status, t) => {
    switch (status) {
        case "success":
            return _jsx(Text, Object.assign({ type: "success" }, { children: t("documents.status.success") }));
        case "error":
            return _jsx(Text, Object.assign({ type: "danger" }, { children: t("documents.status.error") }));
        case "uploading":
            return _jsx(Text, Object.assign({ type: "warning" }, { children: t("documents.status.uploading") }));
        case "waiting":
            return _jsx(Text, Object.assign({ disabled: true }, { children: t("documents.status.waiting") }));
    }
};
const DocumentRow = ({ doc, apiBasePath, updateDocuments, editableFields: getFieldsToEdit, allowDelete, enableToggleClientVisibility = () => true, removeDocFromUpload, updateDocumentInUpload, docIdsInSelection, handleSelectChange, }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [isInEditMode, setIsInEditMode] = useState(!doc.document_type || doc.document_type === DocumentType.None);
    const { documentCategories } = useSelector((state) => state.configState);
    // required for proper type checks
    const isUploadDoc = isDocumentInUpload(doc);
    const isDoc = isDocument(doc);
    const editableFields = getFieldsToEdit(doc);
    const id = isUploadDoc ? doc.updateId : doc.document_id;
    const isSelected = docIdsInSelection.includes(id);
    const [fileName, ext] = useMemo(() => {
        const ext = doc.document_name.substring(doc.document_name.lastIndexOf("."));
        const fileName = doc.document_name.substring(0, doc.document_name.lastIndexOf(ext));
        return [fileName, ext];
    }, [doc.document_name]);
    const showEditField = (field) => {
        if (isUploadDoc)
            return doc.status === "success";
        return (doc.document_type === DocumentType.None || (isInEditMode && editableFields.includes(field)));
    };
    const checkIfDateIsRequired = (v) => {
        var _a;
        const category = documentCategories.find((cat) => { var _a; return (_a = cat.document_types) === null || _a === void 0 ? void 0 : _a.find((type) => type.name === v); });
        const type = (_a = category === null || category === void 0 ? void 0 : category.document_types) === null || _a === void 0 ? void 0 : _a.find((type) => type.name === v);
        return !!(type === null || type === void 0 ? void 0 : type.is_as_of_date_required);
    };
    const [isDateRequired, setIsDateRequired] = useState(checkIfDateIsRequired(doc.document_type));
    const categoriesDropdownOptions = useMemo(() => documentCategories.map(({ name, document_types }) => ({
        // @ts-ignore dynamic categories
        label: t(`documents.category.${name}.name`),
        options: document_types === null || document_types === void 0 ? void 0 : document_types.map((type) => ({
            // @ts-ignore dynamic categories types names
            label: t(`documents.type.${type.name}.name`),
            value: type.name,
        })),
    })), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentCategories]);
    const updateDocument = (update) => Axios.patch(`${apiBasePath}/documents/${doc.document_id}`, update).then(() => updateDocuments(), (err) => void Axios.error(err));
    const handleRemove = () => {
        Axios.delete(`${apiBasePath}/documents/${doc.document_id}`).then(() => {
            removeDocFromUpload(id);
            updateDocuments();
        }, (error) => Axios.error(error));
    };
    const handleUpdateClientVisibility = (isVisible) => void updateDocument({ is_client_visible: isVisible });
    const handleDownload = () => {
        void Axios.get(`${apiBasePath}/documents/${doc.document_id}/download_url`).then(({ data }) => {
            const newTab = window.open(data.download_url, "_blank");
            if (newTab)
                newTab.focus();
        }, (err) => void Axios.error(err));
    };
    const handleUpdate = ({ name, type, date }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!type) {
            if (doc.document_type === DocumentType.None)
                return message.error(t("documents.category-error"));
            return;
        }
        yield updateDocument({
            document_name: (name || fileName) + ext,
            document_type: type,
            as_of_date: date === null || date === void 0 ? void 0 : date.format(rawDateFormat),
        });
        isUploadDoc && removeDocFromUpload(id);
        setIsInEditMode(false);
        updateDocuments();
    });
    const handleRetryUpload = (doc) => {
        void handleDocumentUpload(doc, apiBasePath, updateDocumentInUpload);
    };
    const handleCancel = (id, doc) => {
        setIsInEditMode(false);
        form.setFieldsValue({
            [id]: { name: fileName, type: doc.document_type },
        });
    };
    const renderActionBtns = () => {
        const downloadBtn = (_jsx(DownloadOutlined, { style: { fontSize: "16px", color: token.colorLink }, onClick: () => doc.document_id && handleDownload() }));
        const deleteBtn = (_jsx(Popconfirm, Object.assign({ title: t("documents.confirm-delete"), onConfirm: handleRemove, okText: t("common.delete"), cancelText: t("common.cancel"), icon: _jsx(ExclamationCircleOutlined, { style: { color: token.colorError } }) }, { children: _jsx(DeleteOutlined, { style: { fontSize: "16px", color: token.colorLink } }) })));
        const saveBtn = (_jsx(Text, Object.assign({ style: { color: token.colorPrimary, cursor: "pointer" }, onClick: form.submit }, { children: t("common.save") })));
        if (doc.document_type === DocumentType.None) {
            if (isUploadDoc) {
                if (doc.status === "waiting" || doc.status === "uploading")
                    return;
                if (doc.status === "error") {
                    return (_jsxs(_Fragment, { children: [allowDelete(doc) && _jsx(Col, { children: deleteBtn }), _jsx(Col, { children: _jsx(Text, Object.assign({ style: { color: token.colorPrimary, cursor: "pointer" }, onClick: () => handleRetryUpload(doc) }, { children: t("common.retry") })) })] }));
                }
            }
            return (_jsxs(_Fragment, { children: [allowDelete(doc) && _jsx(Col, { children: deleteBtn }), _jsx(Col, { children: downloadBtn }), _jsx(Col, { children: saveBtn })] }));
        }
        if (isInEditMode)
            return (_jsxs(_Fragment, { children: [_jsx(Col, { children: saveBtn }), _jsx(Col, { children: _jsx(Text, Object.assign({ style: { color: token.colorPrimary, cursor: "pointer" }, onClick: () => handleCancel(id, doc) }, { children: t("common.cancel") })) })] }));
        return (_jsxs(_Fragment, { children: [!!editableFields.length && (_jsx(Col, { children: _jsx(EditOutlined, { style: { fontSize: "16px", color: token.colorLink }, onClick: () => setIsInEditMode(true) }) })), allowDelete(doc) && _jsx(Col, { children: deleteBtn }), _jsx(Col, { children: downloadBtn }), isDoc && enableToggleClientVisibility(doc) && (_jsx(Col, { children: _jsx(Tooltip, Object.assign({ title: doc.is_client_visible
                            ? t("documents.visible-to-user")
                            : t("documents.not-visible-to-user") }, { children: doc.is_client_visible ? (_jsx(EyeOutlined, { style: { fontSize: "16px", color: token.colorError }, onClick: () => handleUpdateClientVisibility(false) })) : (_jsx(EyeInvisibleOutlined, { style: { fontSize: "16px", color: token.colorLink }, onClick: () => handleUpdateClientVisibility(true) })) })) }))] }));
    };
    const handleCategorySelection = (v) => {
        setIsDateRequired(checkIfDateIsRequired(v));
        void form.validateFields([DocumentEditableField.date]);
    };
    const initialValues = useMemo(() => ({
        [DocumentEditableField.name]: fileName,
        [DocumentEditableField.type]: doc.document_type === DocumentType.None ? undefined : doc.document_type,
        [DocumentEditableField.date]: doc.as_of_date ? dayjs(doc.as_of_date) : undefined,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (_jsx(Form, Object.assign({ form: form, style: { margin: 0 }, onFinish: (v) => void handleUpdate(v), initialValues: initialValues }, { children: _jsxs(DocumentRowWrapper, Object.assign({ gutter: 8 }, { children: [_jsx(Col, Object.assign({ span: 14 }, { children: _jsxs(Row, Object.assign({ gutter: [4, 4] }, { children: [_jsx(Col, { children: _jsx(DocumentCheckbox, Object.assign({ value: id, checked: isSelected, onChange: () => handleSelectChange(id), disabled: isUploadDoc && doc.status !== "success" }, { children: _jsxs(FileWithIcon, { children: [_jsx(FileOutlined, { style: { color: token.colorPrimary, marginTop: 4 } }), editableFields.includes(DocumentEditableField.name) && isInEditMode ? (_jsxs(NameAndExtension, { children: [_jsx(Form.Item, Object.assign({ name: DocumentEditableField.name, style: { margin: 0 }, rules: [{ required: true, message: t("common.required") }] }, { children: _jsx(NameInput, { size: "small" }) })), _jsx(Text, { children: ext })] })) : (_jsx(DataText, { children: doc.document_name }))] }) })) }), showEditField(DocumentEditableField.type) && (_jsx(Col, { children: _jsx(Form.Item, Object.assign({ name: DocumentEditableField.type, style: { margin: 0, marginRight: 16 } }, { children: _jsx(CategorySelect, { placeholder: t(`documents.category-placeholder`), size: "small", listHeight: 350, 
                                        // eslint-disable-next-line
                                        getPopupContainer: (trigger) => trigger.parentElement, optionFilterProp: "label", showSearch: true, options: categoriesDropdownOptions, onSelect: (v) => handleCategorySelection(v) }) })) })), _jsx(Col, { children: showEditField(DocumentEditableField.date) ? (_jsx(Form.Item, Object.assign({ name: DocumentEditableField.date, style: { margin: 0 }, rules: isDateRequired ? [{ required: true, message: t("common.required") }] : [] }, { children: _jsx(DatePicker, { size: "small", format: dayMonthYear, allowClear: doc.document_type === DocumentType.None }) }))) : (_jsx(_Fragment, { children: doc.as_of_date && _jsx(Tag, { children: dayjs(doc.as_of_date).format(dayMonthYear) }) })) })] })) })), _jsx(Col, Object.assign({ span: 4, style: { textAlign: "center" } }, { children: isUploadDoc ? getUploadStatus(doc.status, t) : _jsx(TimePassed, { date: doc.created_at }) })), _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(Actions, { children: _jsx(Row, Object.assign({ gutter: 20, wrap: false, align: "middle" }, { children: renderActionBtns() })) }) }))] })) })));
};
export default DocumentRow;
