import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import styled, { css } from "styled-components";
import { FinancialStatementType } from "@teylor-tools/Api";
import { DataCard } from "@ui/data-display/DataCard";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
import { CustomBarChart } from "./CustomBarChart";
const DataCardGraph = styled(DataCard) `
	${({ loading }) => !loading &&
    css `
			.ant-card-body {
				padding: 12px 0;
			}
		`}
`;
const checkValueAvailability = (value) => {
    if (!value)
        return "";
    return Math.abs(parseFloat(value)) > 0 ? value : "";
};
export const FinancialsGraph = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("assets");
    const { financials, isFinancialsLoading } = useCompany();
    const formattedData = useMemo(() => {
        return financials
            .filter((company) => company.statement_type === FinancialStatementType.AnnualStatement)
            .slice(0, 5)
            .reverse()
            .reduce((acc, financialData) => {
            var _a, _b, _c;
            const date = financialData.financials_date;
            const mapping = {
                revenue: checkValueAvailability((_a = financialData === null || financialData === void 0 ? void 0 : financialData.summary_data) === null || _a === void 0 ? void 0 : _a.revenue),
                profit: checkValueAvailability((_b = financialData === null || financialData === void 0 ? void 0 : financialData.summary_data) === null || _b === void 0 ? void 0 : _b.net_income),
                assets: checkValueAvailability((_c = financialData === null || financialData === void 0 ? void 0 : financialData.summary_data) === null || _c === void 0 ? void 0 : _c.total_assets_published),
            };
            const keys = ["revenue", "profit", "assets"];
            for (const key of keys) {
                const value = parseFloat(mapping[key]);
                if (!isNaN(value)) {
                    acc[key].push({ date, [key]: value });
                }
            }
            return acc;
        }, {
            revenue: [],
            profit: [],
            assets: [],
        });
    }, [financials]);
    const handleTabChange = (key) => {
        setActiveTab(key);
    };
    const tabItems = [
        {
            key: "assets",
            label: t("financial-graph.assets"),
            children: _jsx(CustomBarChart, { data: formattedData.assets, dataKey: "assets" }, activeTab),
        },
        {
            key: "revenue",
            label: t("financial-graph.revenue"),
            children: _jsx(CustomBarChart, { data: formattedData.revenue, dataKey: "revenue" }, activeTab),
        },
        {
            key: "profit",
            label: t("financial-graph.profit"),
            children: _jsx(CustomBarChart, { data: formattedData.profit, dataKey: "profit" }, activeTab),
        },
    ];
    return (_jsx(DataCardGraph, Object.assign({ loading: isFinancialsLoading }, { children: _jsx(Tabs, { items: tabItems, centered: true, onChange: handleTabChange, activeKey: activeTab }) })));
};
