var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Divider, Form, Modal, Row, Select, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { FinancialStatementType, } from "@teylor-tools/Api";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
import { Axios } from "src/utils/Axios";
export const CompanyScoringAddModal = ({ onClose, getCreditScores }) => {
    const { t } = useTranslation();
    const { company, financials, isFinancialsLoading } = useCompany();
    const [form] = Form.useForm();
    const scoring_config_id = Form.useWatch("scoring_config_id", form);
    const [loading, setLoading] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [scoringConfigs, setScoringConfigs] = useState();
    const getScoringConfig = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`partner/scorecards`).then(({ data }) => setScoringConfigs(data), (err) => {
            void Axios.error(err);
        });
    }), []);
    useEffect(() => {
        setLoading(true);
        getScoringConfig().finally(() => setLoading(false));
    }, [getScoringConfig]);
    const handleFinish = (values) => {
        setLoadingCreate(true);
        Axios.post(`/sabre/partner/companies/${company.company_id}/scores`, values)
            .then(() => {
            void getCreditScores();
            onClose();
        }, (err) => {
            void Axios.error(err);
        })
            .finally(() => setLoadingCreate(false));
    };
    const scorecards = scoringConfigs === null || scoringConfigs === void 0 ? void 0 : scoringConfigs.map(({ name, scoring_config_id }) => ({
        label: name,
        value: scoring_config_id,
    }));
    const financialPeriods = financials === null || financials === void 0 ? void 0 : financials.map(({ statement_type, financials_date, company_financial_data_id }) => {
        const dateFormat = statement_type === FinancialStatementType.InterimFinancials ? "YYYY.MM" : "YYYY";
        return {
            label: `${dayjs(financials_date).format(dateFormat)} - ${t(`company.financials.${statement_type}`)}`,
            value: company_financial_data_id,
        };
    });
    return (_jsx(Modal, Object.assign({ title: t("company.scoring.add_new_scoring"), open: true, onCancel: onClose, okText: t("common.add"), cancelText: t("common.cancel"), onOk: () => void form.validateFields().then(() => form.submit()), okButtonProps: {
            loading: loadingCreate,
            disabled: loading || isFinancialsLoading,
            htmlType: "submit",
        } }, { children: _jsx(Spin, Object.assign({ spinning: loading || isFinancialsLoading }, { children: _jsxs(Form, Object.assign({ form: form, onFinish: handleFinish, disabled: loading || isFinancialsLoading, layout: "vertical" }, { children: [_jsx(Form.Item, Object.assign({ name: "scoring_config_id", label: t("company.scoring.scorecard_to_use"), rules: [
                            {
                                required: true,
                                message: t("common.field_required"),
                            },
                        ] }, { children: _jsx(Select, { options: scorecards }) })), _jsx(Form.Item, Object.assign({ name: "financial_data_ids", label: t("company.scoring.financial_periods"), rules: [
                            {
                                required: true,
                                message: t("common.field_required"),
                            },
                        ] }, { children: _jsx(Select, { disabled: !scoring_config_id, mode: "multiple", options: financialPeriods }) })), _jsx(Divider, Object.assign({ orientation: "left" }, { children: _jsx(Typography.Text, { children: t("company.scoring.loan_data") }) })), _jsxs(Row, Object.assign({ gutter: [16, 16] }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ name: "loan_amount", label: t("company.scoring.loan_amount") }, { children: _jsx(CurrencyInput, { style: { width: "100%" } }) })) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Form.Item, Object.assign({ name: "loan_duration", label: t("company.scoring.duration") }, { children: _jsx(NumberInputLocalized, { style: { width: "100%" }, controls: false, addonAfter: t("common.months") }) })) }))] }))] })) })) })));
};
