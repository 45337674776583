import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { App, Button, Col, Form, Modal, Row, Space, Spin, Tooltip, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";
import { ApplicationStatuses, } from "@teylor-tools/Api";
import NoObjectsCard from "@ui/object-financing/NoObjectsCard";
import ObjectCard from "@ui/object-financing/ObjectCard";
import ObjectForm, { MAX_NUMBER_OF_OBJECTS, } from "@ui/object-financing/ObjectForm";
import { prepareObjectFormFromResponse, prepareRequestObjectFromForm, } from "@ui/object-financing/objects.utils";
import Alert from "src/components/Alert";
import Error from "src/components/Error";
import { Axios } from "src/utils/Axios";
const AddBtnWrapper = styled.div `
	display: flex;
	justify-content: end;
	margin-bottom: 32px;
`;
const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined) `
	color: ${(props) => props.theme.colorError};
	margin-top: 2px;
	font-size: 22px;
`;
const SpinWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	margin-top: calc(50vh - 200px);
`;
const OBJECT_FORM_EDIT = "object-form-edit";
const OBJECT_FORM_ADD = "object-form-add";
const ObjectsTab = ({ applicationStatus, applicationId, onUpdate, currency }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [objects, setObjects] = useState([]);
    const [formEdit] = useForm();
    const [formAdd] = useForm();
    const [objectToDelete, setObjectToDelete] = useState();
    const [showAddModal, setShowAddModal] = useState(false);
    const [isModalSendingData, setIsModalSendingData] = useState(false);
    const [objectToEdit, setObjectToEdit] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const { countries } = useSelector((state) => state.configState);
    const isEditable = [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(applicationStatus);
    const defaultCountry = useMemo(() => { var _a; return (_a = countries[0]) === null || _a === void 0 ? void 0 : _a.code; }, [countries]);
    const isAddObjectBtnDisabled = objects.length >= MAX_NUMBER_OF_OBJECTS;
    const getObjects = useCallback(() => {
        setLoading(true);
        void Axios.get(`/partner/applications/${applicationId}/finance_objects`)
            .then(({ data }) => setObjects(data))
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, [applicationId]);
    const getObjectIndex = (objToFind) => objects.findIndex((obj) => obj.finance_object_id === objToFind.finance_object_id);
    const onEdit = (obj) => {
        setObjectToEdit(obj);
        formEdit.setFieldsValue(prepareObjectFormFromResponse(obj));
    };
    const deleteObject = () => {
        setObjectToDelete(undefined);
        void Axios.delete(`/partner/applications/${applicationId}/finance_objects/${objectToDelete === null || objectToDelete === void 0 ? void 0 : objectToDelete.finance_object_id}`)
            .then(() => {
            void message.success(t("dashboard.objects.objectDeleteSuccess"));
            getObjects();
            onUpdate();
        })
            .catch((err) => Axios.error(err, t("common.errors.objectDeleteError")));
    };
    const editObject = (form) => {
        setIsModalSendingData(true);
        void Axios.put(`/partner/applications/${applicationId}/finance_objects/${objectToEdit === null || objectToEdit === void 0 ? void 0 : objectToEdit.finance_object_id}`, prepareRequestObjectFromForm(form))
            .then(() => {
            void message.success(t(`dashboard.objects.objectEditSuccess`));
            getObjects();
            setObjectToEdit(undefined);
            onUpdate();
        })
            .catch((err) => void Axios.error(err, t("common.errors.objectEditError")))
            .finally(() => setIsModalSendingData(false));
    };
    const addObject = (form) => {
        setIsModalSendingData(true);
        void Axios.post(`/partner/applications/${applicationId}/finance_objects`, prepareRequestObjectFromForm(form))
            .then(() => {
            void message.success(t("dashboard.objects.objectAddSuccess"));
            setShowAddModal(false);
            getObjects();
            onUpdate();
        })
            .catch((err) => void Axios.error(err, t("common.errors.errorAddingObject")))
            .finally(() => setIsModalSendingData(false));
    };
    useEffect(getObjects, [getObjects]);
    if (error) {
        return _jsx(Error, { error: error });
    }
    if (loading) {
        return (_jsx(SpinWrapper, { children: _jsx(Spin, {}) }));
    }
    return (_jsxs(_Fragment, { children: [isEditable && !!objects.length && (_jsx(AddBtnWrapper, { children: _jsx(Tooltip, Object.assign({ title: isAddObjectBtnDisabled
                        ? t("dashboard.objects.maxObjectsTooltip", { max: MAX_NUMBER_OF_OBJECTS })
                        : "" }, { children: _jsx(Button, Object.assign({ type: "primary", "data-cy": "add-objects-button", icon: _jsx(PlusOutlined, {}), disabled: isAddObjectBtnDisabled, onClick: () => setShowAddModal(true) }, { children: t("dashboard.objects.addObject") })) })) })), objects.length ? (_jsx(Row, Object.assign({ gutter: [24, 24] }, { children: objects.map((obj, index) => (_jsx(Col, Object.assign({ xs: 24, md: 12, xl: 8 }, { children: _jsx(ObjectCard, { object: obj, index: index, isEditable: isEditable, onEdit: onEdit, onDelete: setObjectToDelete, currency: currency }) }), obj.finance_object_id))) }))) : (_jsxs(_Fragment, { children: [_jsx(Alert, { message: t("common.messages.complete-section-alert"), type: "info", showIcon: true }), _jsx(NoObjectsCard, { onClick: () => setShowAddModal(true), translations: {
                            title: t("dashboard.objects.noObjects1"),
                            subtitle: t("dashboard.objects.noObjects2"),
                            addObject: t("dashboard.objects.addObject"),
                        } })] })), _jsx(Modal, Object.assign({ title: t("dashboard.objects.editObject"), visible: !!objectToEdit, onCancel: () => setObjectToEdit(undefined), cancelText: t("dashboard.documents.cancel"), okText: t("common.button_label.save"), okButtonProps: {
                    htmlType: "submit",
                    form: OBJECT_FORM_EDIT,
                    disabled: isModalSendingData,
                    loading: isModalSendingData,
                }, cancelButtonProps: { disabled: isModalSendingData }, width: 650, maskClosable: false, destroyOnClose: true }, { children: _jsx(Form, Object.assign({ form: formEdit, id: OBJECT_FORM_EDIT, layout: "vertical", onFinish: editObject, disabled: isModalSendingData, preserve: false, initialValues: { merchant_country: defaultCountry } }, { children: _jsx(ObjectForm, { countries: countries }) })) })), _jsx(Modal, Object.assign({ title: t("dashboard.objects.addObject"), visible: showAddModal, onCancel: () => setShowAddModal(false), cancelText: t("dashboard.documents.cancel"), okText: t("common.button_label.save"), okButtonProps: {
                    htmlType: "submit",
                    form: OBJECT_FORM_ADD,
                    disabled: isModalSendingData,
                    loading: isModalSendingData,
                }, cancelButtonProps: {
                    disabled: isModalSendingData,
                }, width: 650, maskClosable: false, destroyOnClose: true }, { children: _jsx(Form, Object.assign({ form: formAdd, id: OBJECT_FORM_ADD, layout: "vertical", onFinish: addObject, preserve: false, disabled: isModalSendingData, initialValues: { merchant_country: defaultCountry } }, { children: _jsx(ObjectForm, { countries: countries }) })) })), _jsx(Modal, Object.assign({ visible: !!objectToDelete, okText: t("common.button_label.delete"), okButtonProps: { danger: true }, onOk: deleteObject, onCancel: () => setObjectToDelete(undefined), destroyOnClose: true, closable: false, width: 400 }, { children: _jsxs(Space, Object.assign({ size: 16, align: "start" }, { children: [_jsx(ExclamationCircleOutlinedIcon, {}), _jsxs("div", { children: [_jsx(Typography.Title, Object.assign({ level: 5 }, { children: t("common.messages.areYouSure") })), _jsx(Typography.Text, { children: _jsx(Trans, Object.assign({ i18nKey: "dashboard.objects.deleteObjectModalTxt" }, { children: { idx: objectToDelete && getObjectIndex(objectToDelete) + 1 } })) })] })] })) }))] }));
};
export default ObjectsTab;
