import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import dayjs from "dayjs";
import { useTheme } from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import { CardEditKeys } from "../../ApplicationDrilldownPage";
import OverviewCard from "./OverviewCard";
import { EditIcon, NotAvailableJsx } from "./OverviewComponents";
const CustomerDataCard = ({ application, setEditKey }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const customerData = useMemo(() => [
        {
            label: t("dashboard.overview.clientId"),
            values: [application.companyId],
        },
        {
            label: t("dashboard.overview.founded"),
            values: [
                application.companyFoundationDate ? (dayjs(application.companyFoundationDate).format(dayMonthYear)) : (_jsx(NotAvailableJsx, {})),
            ],
        },
        {
            label: t("dashboard.overview.name"),
            values: [application.companyName],
        },
        {
            label: t("dashboard.company_information.legalForm"),
            values: [t(`common.company_types.${application.companyType}`)],
        },
        {
            label: t("dashboard.company_information.address"),
            values: [
                `${application.street} ${application.houseNumber}`,
                `${application.companyPostCode} ${application.companyLocation}`,
                application.companyCountry
                    ? getCountryList(i18n.language)[application.companyCountry]
                    : "",
            ],
        },
        {
            label: t("dashboard.company_information.hrNumber"),
            values: [application.hrNumber],
        },
        {
            label: t("dashboard.overview.tinNumber"),
            values: [
                application.taxIdNumber ? (_jsxs(_Fragment, { children: [application.taxIdNumber, [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(application.status) && (_jsx(Button, Object.assign({ type: "link", style: { padding: 0, height: "100%" }, onClick: () => setEditKey(CardEditKeys.Tin) }, { children: _jsx(EditIcon, { style: { color: theme.colorLink } }) })))] })) : (_jsx(Button, Object.assign({ type: "link", style: { padding: 0, height: "auto" }, onClick: () => setEditKey(CardEditKeys.Tin) }, { children: t("common.button_label.addTin") }))),
            ],
        },
        {
            label: t("dashboard.loan_information.purpose"),
            values: [application.businessPurpose],
        },
        {
            label: t("dashboard.company_representatives.mainContact"),
            values: [application.user_email || "-"],
        },
    ], 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application]);
    return (_jsx(OverviewCard, { data: customerData, cardTitle: t("dashboard.overview.customerInformation"), setEditKey: setEditKey }));
};
export default CustomerDataCard;
