import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import styled, { css } from "styled-components";
import { DataCard } from "@ui/data-display/DataCard";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
import { InnerRelationshipGraph } from "./InnerRelationshipGraph";
const ChartWrapper = styled.div `
	padding: 0;
	height: 400px;
`;
const DataCardGraph = styled(DataCard) `
	${({ loading }) => !loading &&
    css `
			.ant-card-body {
				padding: 0;
			}
		`}
`;
export const RelationshipGraph = () => {
    const { t } = useTranslation();
    const { relationshipData, isRelationshipLoading } = useCompany();
    const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
    const chartRef = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                setChartSize({
                    width: chartRef.current.offsetWidth,
                    height: chartRef.current.offsetHeight,
                });
            }
        };
        setTimeout(handleResize, 100);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [relationshipData]);
    return (_jsx(DataCardGraph, Object.assign({ loading: isRelationshipLoading, title: t("company.relationship-graph-title") }, { children: _jsx(ReactFlowProvider, { children: _jsx(ChartWrapper, Object.assign({ ref: chartRef }, { children: _jsx(InnerRelationshipGraph, { nodesData: relationshipData.nodes, edgesData: relationshipData.edges, containerWidth: chartSize.width, containerHeight: chartSize.height }) })) }) })));
};
