import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ScanOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import styled from "styled-components";
import { FeatureName } from "@teylor-tools/Api";
import { FinancialsEmpty } from "@ui/financials-table/FinancialsEmpty";
import FinancialsTable from "@ui/financials-table/FinancialsTable";
import SecondaryHeader from "@ui/secondary-header/SecondaryHeader";
import { useCompany } from "src/layouts/CompanyPortfolioDetailsLayout/CompanyTypes";
import { RoutePaths } from "../../../../Routes";
import "./CompanyFinancials.css";
const Wrapper = styled.div `
	display: flex;
	flex-direction: column;
`;
const ContentWrapper = styled.div `
	overflow: auto;
	height: calc(100vh - 145px);
`;
const SpinWrapper = styled.div `
	text-align: center;
	margin-top: 5%;
`;
const CompanyFinancials = () => {
    var _a;
    const { t } = useTranslation();
    const { financials, isFinancialsLoading, updateFinancials, company: { partner_portfolio_item_id }, } = useCompany();
    const navigate = useNavigate();
    const { features } = useSelector((state) => state.configState);
    const ocrEnabled = !!((_a = features.find((f) => f.name === FeatureName.Ocr)) === null || _a === void 0 ? void 0 : _a.is_active);
    useEffect(() => updateFinancials(), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (_jsxs(Wrapper, Object.assign({ className: "financials-wrapper" }, { children: [_jsx(SecondaryHeader, Object.assign({ title: t("company.menu.financials") }, { children: ocrEnabled && (_jsx(Button, Object.assign({ icon: _jsx(ScanOutlined, {}), onClick: () => navigate(generatePath(RoutePaths.CompanyPortfolioFincr, {
                        companyId: partner_portfolio_item_id,
                    })) }, { children: t("company.financials.fincr") }))) })), _jsx(ContentWrapper, { children: isFinancialsLoading ? (_jsx(SpinWrapper, { children: _jsx(Spin, {}) })) : financials.length ? (_jsx(FinancialsTable, { financials: financials, isEditing: false })) : (_jsx(FinancialsEmpty, {})) })] })));
};
export default CompanyFinancials;
