import { jsx as _jsx } from "react/jsx-runtime";
import { Spin } from "antd";
import styled from "styled-components";
const Wrapper = styled.div `
	background: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const FullPageSpinner = () => {
    return (_jsx(Wrapper, { children: _jsx(Spin, {}) }));
};
export default FullPageSpinner;
