import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppstoreOutlined, EuroCircleOutlined, FileSearchOutlined, PaperClipOutlined, UserOutlined, } from "@ant-design/icons";
import { FeatureName } from "@teylor-tools/Api";
import Menu from "@ui/menu/Menu";
import { RoutePaths } from "src/Routes";
const CompanyPortfolioDetailsMenu = ({ companyId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { features } = useSelector((state) => state.configState);
    const menuItems = useMemo(() => {
        var _a, _b;
        return [
            {
                icon: _jsx(AppstoreOutlined, {}),
                label: t("company.menu.overview"),
                key: generatePath(RoutePaths.CompanyPortfolioOverview, { companyId }),
            },
            {
                icon: _jsx(UserOutlined, {}),
                label: t("company.menu.persons"),
                key: generatePath(RoutePaths.CompanyPortfolioPersons, { companyId }),
            },
            {
                icon: _jsx(PaperClipOutlined, {}),
                label: t("company.menu.documents"),
                key: generatePath(RoutePaths.CompanyPortfolioDocuments, { companyId }),
            },
            ...(((_a = features.find((f) => f.name === FeatureName.PartnerSabreCompanyFinancials)) === null || _a === void 0 ? void 0 : _a.is_active)
                ? [
                    {
                        icon: _jsx(EuroCircleOutlined, {}),
                        label: t("company.menu.financials"),
                        key: generatePath(RoutePaths.CompanyPortfolioFinancials, { companyId }),
                    },
                ]
                : []),
            ...(((_b = features.find((f) => f.name === FeatureName.PartnerSabreCompanyScoring)) === null || _b === void 0 ? void 0 : _b.is_active)
                ? [
                    {
                        icon: _jsx(FileSearchOutlined, {}),
                        label: t("company.menu.scoring"),
                        key: generatePath(RoutePaths.CompanyPortfolioScoring, { companyId }),
                    },
                ]
                : []),
        ];
    }, [companyId, t, features]);
    return (_jsx(Menu, { selectedKeys: [location.pathname], multiple: true, mode: "inline", onClick: (menuItem) => navigate(menuItem.key), items: menuItems, isCollapsible: true, collapseText: t("menu.collapse") }));
};
export default CompanyPortfolioDetailsMenu;
