import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Timeline as AntdTimeline, Empty, Space, Typography } from "antd";
import { EventName } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { formatterFullDateTime } from "@teylor-tools/utils/dateFormats";
const ApplicationEvents = ({ applicationEvents }) => {
    const { t } = useTranslation();
    const { date } = useFormatter();
    if (!(applicationEvents === null || applicationEvents === void 0 ? void 0 : applicationEvents.length)) {
        return _jsx(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: t("common.no-data") });
    }
    return (_jsx(AntdTimeline, { items: applicationEvents.map(({ event_description, event_name, event_time, event_action, event_data }) => {
            const action = event_action ? ` - ${t(`logs.action.${event_action}`)}` : "";
            const eventName = event_name ? ` - ${t(`logs.event_name.${event_name}`)}` : "";
            return {
                children: (_jsxs(Space, Object.assign({ direction: "vertical" }, { children: [_jsx(Typography.Text, Object.assign({ strong: true }, { children: `${date(event_time, formatterFullDateTime)}${action}${eventName}` })), event_name === EventName.Status &&
                            event_data &&
                            "previous_status" in event_data &&
                            "status" in event_data && (_jsx(Typography.Text, { children: t(`logs.event_name.${event_name}.event_data`, {
                                from: t(`common.application_status.${event_data === null || event_data === void 0 ? void 0 : event_data.previous_status}`),
                                to: t(`common.application_status.${event_data === null || event_data === void 0 ? void 0 : event_data.status}`),
                            }) })), event_description && _jsx(Typography.Text, { children: event_description })] }))),
            };
        }) }));
};
export default ApplicationEvents;
