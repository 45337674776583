import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Typography } from "antd";
import DataItem from "../../../../components/shared-modules/data-item/DataItem";
import { StyledCard } from "./OverviewComponents";
const OverviewCard = ({ data, cardTitle, cardEditKey, editHidden = false, setEditKey }) => {
    const { t } = useTranslation();
    return (_jsx(Col, Object.assign({ xs: 24 }, { children: _jsx(StyledCard, Object.assign({ title: cardTitle, extra: !editHidden &&
                cardEditKey && (_jsx(Button, Object.assign({ type: "link", icon: _jsx(EditOutlined, {}), onClick: () => setEditKey(cardEditKey) }, { children: t("common.button_label.edit") }))) }, { children: data.length ? (data.map((item, index) => {
                return "type" in item ? (_jsx("br", {}, index)) : (_jsx(DataItem, { label: typeof item.label === "string" ? (_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: item.label + ":" }))) : (item.label), values: item.values, labelStyles: Object.assign({ display: "flex", justifyContent: "end", textAlign: "right" }, item.labelStyles), labelColProps: { span: 8 } }, `data-item-${index}`));
            })) : (_jsx(Typography.Text, Object.assign({ type: "secondary" }, { children: t("dashboard.overview.noInformationAdded") }))) })) })));
};
export default OverviewCard;
