var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Collapse, Form, Input, Popover, Radio, Row, Select, Space, Typography, } from "antd";
import styled from "styled-components";
import { AllBanksLoanPurposes, ApplicationStatuses, CountryCode, LoanPurpose, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import CustomField from "@ui/custom-field/CustomField";
import CityFormItem from "@ui/form/form-items/city-form-item/CityFormItem";
import CompanySelectFormItem from "@ui/form/form-items/company-select-form-item/CompanySelectFormItem";
import CountrySelectFormItem from "@ui/form/form-items/country-select-form-item/CountrySelectFormItem";
import HouseNumberFormItem from "@ui/form/form-items/house-number-form-item/HouseNumberFormItem";
import PostcodeFormItem from "@ui/form/form-items/postcode-form-item/PostcodeFormItem";
import StreetFormItem from "@ui/form/form-items/street-form-item/StreetFormItem";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import CurrencyInput from "@ui/form/inputs/currency-input/CurrencyInput";
import NumberInputLocalized from "@ui/form/inputs/number-input-localized/NumberInputLocalized";
import MainHeader from "@ui/main-header/MainHeader";
import { RoutePaths } from "src/Routes";
import Error from "src/components/Error";
import FullPageSpinner from "src/components/FullPageSpinner";
import { usePageTitle } from "src/hooks/usePageTitle";
import { akfFields, createAkfFields, isAkfApp } from "src/pages/apply/akf";
import { Axios } from "src/utils/Axios";
import { LOAN_AMOUNT } from "src/utils/loanAmount";
import { LOAN_DURATION } from "src/utils/loanDuration";
const { TextArea } = Input;
const { Option } = Select;
const { Text, Paragraph, Title } = Typography;
var Step;
(function (Step) {
    Step["CompanyDetails"] = "companyDetails";
    Step["LoanDetails"] = "loanDetails";
    Step["TermsAndConditions"] = "termsAndConditions";
})(Step || (Step = {}));
const stepsArray = Object.values(Step);
var FieldName;
(function (FieldName) {
    FieldName["companyName"] = "companyName";
    FieldName["street"] = "street";
    FieldName["houseNumber"] = "houseNumber";
    FieldName["companyLocation"] = "companyLocation";
    FieldName["companyPostCode"] = "companyPostCode";
    FieldName["taxIdNumber"] = "taxIdNumber";
    FieldName["businessPurpose"] = "businessPurpose";
    FieldName["purposeSelect"] = "purposeSelect";
    FieldName["purposeDescription"] = "purposeDescription";
    FieldName["companyType"] = "companyType";
    FieldName["companyCountry"] = "companyCountry";
    FieldName["allBanksLoanPurpose"] = "allBanksLoanPurpose";
    FieldName["loanSize"] = "loanSize";
    FieldName["loanDuration"] = "loanDuration";
    FieldName["companyCreditProviderId"] = "companyCreditProviderId";
    FieldName["annualSales"] = "annualSales";
    FieldName["internationalCustomers"] = "internationalCustomers";
})(FieldName || (FieldName = {}));
const fieldsToStepsMapping = {
    [Step.CompanyDetails]: [
        FieldName.companyName,
        FieldName.companyType,
        FieldName.street,
        FieldName.houseNumber,
        FieldName.companyLocation,
        FieldName.companyPostCode,
        FieldName.companyCountry,
        FieldName.taxIdNumber,
        FieldName.businessPurpose,
        FieldName.companyCreditProviderId,
    ],
    [Step.LoanDetails]: [
        FieldName.allBanksLoanPurpose,
        FieldName.loanSize,
        FieldName.loanDuration,
        FieldName.purposeSelect,
        FieldName.purposeDescription,
        FieldName.annualSales,
        FieldName.internationalCustomers,
        ...akfFields,
    ],
    [Step.TermsAndConditions]: [],
};
const ApplyPage = () => {
    const { t } = useTranslation();
    const [companyOptions, setCompanyOptions] = useState([]);
    const [form] = Form.useForm();
    const purposeSelect = Form.useWatch("purposeSelect", form);
    const navigate = useNavigate();
    const { currency, percentToDecimal } = useFormatter();
    const { loanTypes, loanPurposes, companyTypes, loaded: configLoaded, countries, customFields, defaultCurrency, } = useSelector((state) => state.configState);
    const isAkf = useMemo(() => isAkfApp(customFields), [customFields]);
    const [activeStep, setActiveStep] = useState(Step.CompanyDetails);
    const [loanType, setLoanType] = useState(isAkf ? AllBanksLoanPurposes.PurposeAkfBike : undefined);
    const [sending, setSending] = useState(false);
    const [rejected, setRejected] = useState(false);
    const akfFields = useMemo(() => (isAkf && customFields ? createAkfFields(customFields, t) : undefined), [customFields, isAkf, t]);
    const isFactoring = Form.useWatch("allBanksLoanPurpose", form) ===
        AllBanksLoanPurposes.Factoring;
    const defaultCountry = useMemo(() => { var _a; return (_a = countries[0]) === null || _a === void 0 ? void 0 : _a.code; }, [countries]);
    // On first render after refresh page Antd form initialized with undefined initialValue,
    // so after your value is set you need refresh the field.
    //https://github.com/ant-design/ant-design/issues/22372#issuecomment-602102164
    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCountry]);
    const requiredRule = { required: true, message: t("common.errors.fieldRequired") };
    usePageTitle(t("common.page_title.newApplication"));
    const createApplication = (values) => {
        var _a;
        setSending(true);
        const newApplication = Object.assign(Object.assign(Object.assign(Object.assign({ companyName: values.companyName, companyType: values.companyType, street: values.street, houseNumber: values.houseNumber, companyLocation: values.companyLocation, companyPostCode: values.companyPostCode, companyCountry: CountryCode[values.companyCountry], taxIdNumber: parseTin(values.taxIdNumber), businessPurpose: values.businessPurpose, allBanksLoanPurpose: values.allBanksLoanPurpose }, (isFactoring
            ? {
                annual_sales_previous_year: values.annualSales,
                has_international_customers: values.internationalCustomers,
            }
            : {
                loanSize: Number(values.loanSize),
                loanDuration: Number(values.loanDuration),
            })), { purposeDescription: values.purposeSelect && values.purposeSelect !== LoanPurpose.Other
                ? t(`application.purpose_option.${values.purposeSelect}`)
                : loanPurposes.length === 1 && !loanPurposes.includes(LoanPurpose.Other)
                    ? t(`application.purpose_option.${loanPurposes[0]}`)
                    : values.purposeDescription }), (isAkf && {
            allBanksLoanPurpose: AllBanksLoanPurposes.PurposeAkfBike,
            custom_fields: {
                is_public_tender: values.is_public_tender,
                employer_rate_subsidy: values.employer_rate_subsidy.toString(),
                employer_service_subsidy: (_a = values.employer_service_subsidy) === null || _a === void 0 ? void 0 : _a.toString(),
                max_bikes_per_employee: values.max_bikes_per_employee,
                price_limit_bike: values.price_limit_bike.toString(),
                number_of_employees: values.number_of_employees,
                group_id: values.group_id,
            },
            leasing: {
                leasing_factor: values.leasing_factor.toString(),
                service_rate: values.service_rate.toString(),
                remaining_value: values.remaining_value.toString(),
                insurance_rate: percentToDecimal(values.insurance_rate),
            },
        })), { companyCreditProviderId: values.companyCreditProviderId });
        Axios.post("/partner/applications", newApplication)
            .then((resp) => {
            if ([ApplicationStatuses.Rejected].includes(resp.data.application.status)) {
                setRejected(true);
            }
            else {
                navigate(generatePath(RoutePaths.ApplicationDrilldown, {
                    application_id: resp.data.application.applicationId,
                }));
            }
        })
            .catch((error) => {
            var _a;
            // TODO: On dev it still sends 500 error instead of created a rejected application.
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                setRejected(true);
            }
            else {
                void Axios.error(error, t("common.errors.sendData"));
            }
        })
            .finally(() => setSending(false));
    };
    const handleFetchCompanies = (searchQuery) => __awaiter(void 0, void 0, void 0, function* () {
        yield Axios.get(`company_search?name=${encodeURIComponent(searchQuery)}`)
            .then(({ data }) => {
            setCompanyOptions(data.companyNameSuggestions || []);
        })
            .catch((error) => Axios.error(error));
    });
    const restartApplication = () => {
        form.resetFields();
        setActiveStep(Step.CompanyDetails);
        setRejected(false);
    };
    const renderRejected = () => {
        return (_jsxs(RejectedWrapper, Object.assign({ direction: "vertical", size: 20 }, { children: [_jsx(Title, Object.assign({ level: 4 }, { children: t("common.messages.applicationRejectedHeader") })), _jsx(Paragraph, { children: t("common.messages.applicationRejectedLine1") }), _jsx(Paragraph, { children: t("common.messages.applicationRejectedLine2") }), _jsxs(RejectedButtons, Object.assign({ direction: "vertical", align: "center", size: 16 }, { children: [_jsx(Button, Object.assign({ type: "primary", size: "large", onClick: restartApplication }, { children: t("common.button_label.startNewApplication") })), _jsx(Button, Object.assign({ size: "large", "data-cy": "back-to-application-list", onClick: () => navigate(RoutePaths.Index) }, { children: t("common.button_label.backToApplicationsList") }))] }))] })));
    };
    const renderPanelHeader = (step) => {
        const index = Object.values(Step).indexOf(step);
        const isDone = index < Object.values(Step).indexOf(activeStep);
        return (_jsxs(Space, Object.assign({ size: 14 }, { children: [_jsx(StyledAvatar, Object.assign({ size: "small", "$active": step === activeStep, "$done": isDone }, { children: isDone ? _jsx(CheckOutlined, {}) : index + 1 })), _jsx(Text, { children: t(`application_process.${step}`) })] })));
    };
    const goToStep = (newIndex) => {
        const isForward = newIndex > Object.values(Step).indexOf(activeStep);
        const changeStep = () => setActiveStep(Object.values(Step)[newIndex]);
        if (isForward) {
            form
                .validateFields()
                .then(() => {
                changeStep();
            })
                .catch((error) => {
                /*
                We check if there are any errors in the current step,
                and only move to the next step if the current step is error free.
                Otherwise, we wouldn't be able to switch steps due to errors in the
                next step.
                This only happens if user goes to the previous step without filling
                all the data.
            */
                let errorsInCurrentStep = false;
                error.errorFields.forEach((errs) => {
                    if (Object.values(fieldsToStepsMapping[activeStep]).includes(errs.name[0])) {
                        errorsInCurrentStep = true;
                    }
                });
                if (!errorsInCurrentStep) {
                    changeStep();
                }
            });
        }
        else {
            changeStep();
        }
    };
    const setCompanyData = (company) => {
        const { street, houseNumber, companyPostCode, companyLocation, companyCountry, companyCreditProviderId, } = company;
        form.setFieldsValue({
            companyType: "",
            street,
            houseNumber,
            companyPostCode,
            companyLocation,
            companyCountry,
            companyCreditProviderId,
        });
    };
    const isPanelCollapsible = (step) => stepsArray.indexOf(activeStep) >= stepsArray.indexOf(step) ? undefined : "disabled";
    const renderPanel = useCallback((step, children) => {
        const index = Object.values(Step).indexOf(step);
        const isLastStep = index === Object.values(Step).length - 1;
        return (_jsxs(_Fragment, { children: [children, _jsxs(WrappedButtons, { children: [_jsx(Button, Object.assign({ onClick: index > 0 ? () => goToStep(index - 1) : undefined, style: { visibility: index === 0 ? "hidden" : "visible" } }, { children: t("common.button_label.back") })), _jsx(Button, Object.assign({ type: "primary", onClick: () => (isLastStep ? form.submit() : goToStep(index + 1)), loading: sending, disabled: sending, "data-cy": "next-button" }, { children: t(`common.button_label.${isLastStep ? "createApplication" : "next"}`) }))] })] }));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, sending]);
    const validateAmount = (rule, value) => {
        if (value === null || !loanType) {
            return Promise.resolve();
        }
        const minLoanAmount = LOAN_AMOUNT.min[loanType];
        if (minLoanAmount && value < minLoanAmount) {
            return Promise.reject(`${t("common.errors.minFinancingAmount")}: ${currency(minLoanAmount, {
                currency: defaultCurrency,
                showFractions: false,
            })}`);
        }
        const maxLoanAmount = LOAN_AMOUNT.max[loanType];
        if (maxLoanAmount && value > maxLoanAmount) {
            return Promise.reject(`${t("common.errors.maxFinancingAmount")}: ${currency(maxLoanAmount, {
                showFractions: false,
                currency: defaultCurrency,
            })}`);
        }
        return Promise.resolve();
    };
    const validateLoanDuration = (rule, value) => {
        if (value === null || !loanType) {
            return Promise.resolve();
        }
        const minLoanDuration = LOAN_DURATION.min[loanType];
        if (minLoanDuration && value < minLoanDuration) {
            return Promise.reject(`${t("common.errors.minLoanDuration")} ${minLoanDuration} ${t("common.months")}`);
        }
        const maxLoanDuration = LOAN_DURATION.max[loanType];
        if (maxLoanDuration && value > maxLoanDuration) {
            return Promise.reject(`${t("common.errors.maxLoanDuration")} ${maxLoanDuration} ${t("common.months")}`);
        }
        return Promise.resolve();
    };
    const onLoanTypeChange = (type) => {
        setLoanType(type);
        if (form.getFieldValue(FieldName.loanSize)) {
            void form.validateFields([FieldName.loanSize]);
        }
        if (form.getFieldValue(FieldName.loanDuration)) {
            void form.validateFields([FieldName.loanDuration]);
        }
        if (isFactoring) {
            form.setFieldsValue({
                [FieldName.loanSize]: 0,
                [FieldName.loanDuration]: 0,
                [FieldName.purposeSelect]: undefined,
                [FieldName.purposeDescription]: "",
            });
        }
        else {
            form.setFieldsValue({
                [FieldName.internationalCustomers]: undefined,
                [FieldName.annualSales]: undefined,
            });
        }
    };
    const showLoanPurposeTextarea = purposeSelect === LoanPurpose.Other ||
        (loanPurposes.length === 1 && loanPurposes.includes(LoanPurpose.Other)) ||
        !loanPurposes.length;
    if (!configLoaded)
        return _jsx(FullPageSpinner, {});
    if (configLoaded && (!(loanTypes === null || loanTypes === void 0 ? void 0 : loanTypes.length) || !(companyTypes === null || companyTypes === void 0 ? void 0 : companyTypes.length))) {
        return _jsx(Error, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(MainHeader, { title: _jsx(_Fragment, { children: _jsxs(Space, { children: [_jsxs(Typography.Text, Object.assign({ strong: true, style: { fontSize: 16 }, type: "secondary" }, { children: [t("application_process.applications"), " ", " /"] })), _jsx(Typography.Text, Object.assign({ strong: true, style: { fontSize: 24 } }, { children: t("application_process.newApplication") }))] }) }) }), _jsx(Wrapper, { children: _jsx(FormWrapper, { children: rejected ? (renderRejected()) : (_jsx(FormContent, { children: _jsx(Form, Object.assign({ form: form, layout: "vertical", onFinish: createApplication, initialValues: Object.assign(Object.assign({}, (isAkf && { [FieldName.loanDuration]: 36 })), { companyCountry: defaultCountry }) }, { children: _jsx(Collapse, { accordion: true, activeKey: activeStep, onChange: (key) => {
                                    !!key.length && setActiveStep(key[0]);
                                }, items: [
                                    {
                                        key: Step.CompanyDetails,
                                        label: renderPanelHeader(Step.CompanyDetails),
                                        collapsible: isPanelCollapsible(Step.CompanyDetails),
                                        children: renderPanel(Step.CompanyDetails, _jsxs(_Fragment, { children: [_jsx(CompanySelectFormItem, { name: FieldName.companyName, setCompanyData: setCompanyData, defaultCountry: defaultCountry, companies: companyOptions, handleFetch: handleFetchCompanies, clearCompanies: () => setCompanyOptions([]) }), _jsx(Form.Item, Object.assign({ name: "companyCreditProviderId", style: { display: "none" } }, { children: _jsx(Input, {}) })), _jsx(Form.Item, Object.assign({ name: FieldName.companyType, label: t("application_process.company_details.legalForm"), rules: [requiredRule] }, { children: _jsx(Select, { children: companyTypes === null || companyTypes === void 0 ? void 0 : companyTypes.map((key) => (_jsx(Option, Object.assign({ value: key }, { children: t(`common.company_types.${key}`) }), key))) }) })), _jsxs(_Fragment, { children: [_jsxs(Row, Object.assign({ gutter: 24 }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(StreetFormItem, { name: FieldName.street, required: true }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(HouseNumberFormItem, { name: FieldName.houseNumber, required: true }) }))] })), _jsxs(Row, Object.assign({ gutter: 24 }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(CityFormItem, { name: FieldName.companyLocation, required: true }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(PostcodeFormItem, { name: FieldName.companyPostCode, required: true }) }))] }))] }), _jsx(CountrySelectFormItem, { label: t("dashboard.company_representatives.country"), name: "companyCountry", rules: [requiredRule], countries: countries }), _jsx(TinFormItem, { name: FieldName.taxIdNumber, label: _jsxs(Space, Object.assign({ size: 4 }, { children: [_jsx(Text, { children: t("application_process.company_details.tin") }), _jsx(Text, Object.assign({ type: "secondary", style: { fontSize: 12 } }, { children: t("common.canAddLater") }))] })), inputProps: {
                                                        suffix: (_jsx(Popover, Object.assign({ content: t("application_process.company_details.tinInfo"), overlayClassName: "global-tooltip-overlay-style" }, { children: _jsx(InfoCircleOutlined, { className: "info-tooltip" }) }))),
                                                        placeholder: t("application_process.company_details.placeholder.tin"),
                                                    } }), _jsx(Form.Item, Object.assign({ name: FieldName.businessPurpose, label: t("application_process.company_details.companyPurpose"), rules: [
                                                        {
                                                            min: 5,
                                                            message: t("common.errors.fieldMustBeMinChars", {
                                                                min: 5,
                                                            }),
                                                        },
                                                        {
                                                            max: 2000000,
                                                            message: t("common.errors.fieldMustBeMaxChars", {
                                                                max: 2000000,
                                                            }),
                                                        },
                                                        requiredRule,
                                                    ] }, { children: _jsx(TextArea, { rows: 6, placeholder: t("application_process.company_details.placeholder.companyPurpose") }) }))] })),
                                    },
                                    {
                                        key: Step.LoanDetails,
                                        label: renderPanelHeader(Step.LoanDetails),
                                        collapsible: isPanelCollapsible(Step.LoanDetails),
                                        children: renderPanel(Step.LoanDetails, _jsxs(_Fragment, { children: [!isAkf && (_jsx(Form.Item, Object.assign({ name: FieldName.allBanksLoanPurpose, label: t("application_process.loan_details.loanType"), rules: [requiredRule] }, { children: _jsx(Select, Object.assign({ onChange: (key) => onLoanTypeChange(key) }, { children: loanTypes === null || loanTypes === void 0 ? void 0 : loanTypes.map((key) => (_jsx(Option, Object.assign({ value: key, "data-cy": "loan-type-option" }, { children: t(`common.loan_purpose.${key}`) }), key))) })) }))), !isFactoring && (_jsxs(Row, Object.assign({ gutter: 24, style: { marginBottom: 24 } }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Space, Object.assign({ direction: "vertical", size: 0, style: { width: "100%" } }, { children: _jsx(Form.Item, Object.assign({ name: FieldName.loanSize, label: t("application_process.loan_details.loanAmount"), style: { margin: 0 }, rules: [
                                                                        requiredRule,
                                                                        {
                                                                            validator: validateAmount,
                                                                        },
                                                                    ] }, { children: _jsx(CurrencyInput, { style: { width: "100%" }, placeholder: t("application_process.loan_details.loanAmount.placeholder"), precision: akfFields ? 0 : undefined, currency: defaultCurrency }) })) })) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Space, Object.assign({ direction: "vertical", size: 0, style: { width: "100%" } }, { children: _jsx(Form.Item, Object.assign({ name: FieldName.loanDuration, label: t("application_process.loan_details.loanDuration"), style: { margin: 0 }, rules: [
                                                                        {
                                                                            validator: validateLoanDuration,
                                                                        },
                                                                        requiredRule,
                                                                    ] }, { children: _jsx(NumberInputLocalized, { style: { width: "100%" }, controls: false, precision: 0, placeholder: t("application_process.loan_details.loanDuration.placeholder"), addonAfter: t("common.months").charAt(0).toUpperCase() +
                                                                            t("common.months").slice(1) }) })) })) }))] }))), isFactoring && (_jsxs(_Fragment, { children: [_jsx(Form.Item, Object.assign({ name: FieldName.annualSales, label: t("application_process.loan_details.annualSales"), required: true, rules: [
                                                                {
                                                                    validator: (_, value) => {
                                                                        return value && value > 0
                                                                            ? Promise.resolve()
                                                                            : Promise.reject(t("common.errors.fieldRequired"));
                                                                    },
                                                                },
                                                            ] }, { children: _jsx(CurrencyInput, { currency: defaultCurrency, style: { width: "100%" } }) })), _jsx(Form.Item, Object.assign({ name: FieldName.internationalCustomers, label: t("application_process.loan_details.internationalCustomers"), rules: [requiredRule], required: true }, { children: _jsxs(Radio.Group, { children: [_jsx(Radio, Object.assign({ value: true }, { children: t("common.yes") })), _jsx(Radio, Object.assign({ value: false }, { children: t("common.no") }))] }) }))] })), akfFields && (_jsx(_Fragment, { children: _jsx(Row, Object.assign({ gutter: 24 }, { children: [
                                                            akfFields.leasing_factor,
                                                            akfFields.service_rate,
                                                            akfFields.employer_rate_subsidy,
                                                            akfFields.employer_service_subsidy,
                                                            akfFields.max_bikes_per_employee,
                                                            akfFields.price_limit_bike,
                                                            akfFields.remaining_value,
                                                            akfFields.insurance_rate,
                                                            akfFields.number_of_employees,
                                                            akfFields.is_public_tender,
                                                            akfFields.group_id,
                                                        ].map((field, i) => (_jsx(Fragment, { children: field && (_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(CustomField, Object.assign({}, field, { defaultCurrency: defaultCurrency })) }))) }, i))) })) })), !isFactoring && loanPurposes.length > 1 && (_jsx(Form.Item, Object.assign({ name: FieldName.purposeSelect, label: t("application_process.loan_details.detailedLoanPurpose"), rules: [requiredRule] }, { children: _jsx(Select, { children: loanPurposes.map((key) => (_jsx(Option, Object.assign({ value: key }, { children: t(`application.purpose_option.${key}`) }), key))) }) }))), !isFactoring && showLoanPurposeTextarea && (_jsx(Form.Item, Object.assign({ name: FieldName.purposeDescription, label: loanPurposes.length > 1
                                                        ? undefined
                                                        : t("application_process.loan_details.detailedLoanPurpose"), initialValue: akfFields &&
                                                        "z.B. Preisgarantie, Laufzeit des Rahmenvertrags, Kündigung, sonstige abweichende Vereinbarungen oder Kommentare", rules: [
                                                        {
                                                            required: true,
                                                            min: 30,
                                                            message: t("common.errors.fieldMustBeMinChars", { min: 30 }),
                                                        },
                                                    ] }, { children: _jsx(TextArea, { maxLength: 700, showCount: true, rows: 6, placeholder: t("application_process.loan_details.placeholder.loanPurpose") }) })))] })),
                                    },
                                    {
                                        key: Step.TermsAndConditions,
                                        label: renderPanelHeader(Step.TermsAndConditions),
                                        collapsible: isPanelCollapsible(Step.TermsAndConditions),
                                        children: renderPanel(Step.TermsAndConditions, _jsxs(Space, Object.assign({ direction: "vertical", size: 20, style: { width: " 100%" } }, { children: [_jsx(Text, Object.assign({ strong: true }, { children: t("application_process.terms_conditions.agreeTermsAndConditions") })), _jsx(TextArea, { readOnly: true, value: t("application_process.terms_conditions.agreeTermsAndConditionsContent"), style: { height: 200 } })] }))),
                                    },
                                ] }) })) })) }) })] }));
};
const Wrapper = styled.div `
	background: ${({ theme }) => theme.background_light};
	padding: 16px 24px;
	height: 100%;
	overflow: auto;
`;
const RejectedWrapper = styled(Space) `
	width: 100%;
	max-width: 600px;
	padding: 40px;
	margin-top: 60px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	background: #fff;
`;
const RejectedButtons = styled(Space) `
	width: 100%;
	margin-top: 10px;
`;
const FormWrapper = styled.div `
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 60px 0 30px;
`;
const FormContent = styled.div `
	width: 100%;
	max-width: 600px;
	background: #fff;
`;
const StyledAvatar = styled(Avatar) `
	${({ theme, $active, $done }) => `
        color: ${$active ? "#FFF" : $done ? theme.colorPrimary : "rgba(0, 0, 0, 0.25)"};
        background: ${$active ? theme.colorPrimary : "#FFF"};
        border: ${$active ? "none" : `1px solid ${$done ? theme.colorPrimary : "rgba(0, 0, 0, 0.15)"}`};
    `}
`;
const WrappedButtons = styled.div `
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 48px;
`;
export default ApplyPage;
