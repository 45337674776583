import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";
import { App, Form, Modal, Space, Typography } from "antd";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import { Axios } from "src/utils/Axios";
const InviteUserModal = ({ applicationId, signatory, setSignatory, getRepresentatives, }) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [isEdit, setIsEdit] = useState(false);
    const [emailForm] = Form.useForm();
    const updateEmail = ({ email }) => {
        void Axios.patch(`/partner/applications/${applicationId}/signatories/${signatory.signatory_id}`, Object.assign(Object.assign({}, signatory), { email }))
            .then((response) => {
            setSignatory(response.data);
            void getRepresentatives();
            setIsEdit(false);
        })
            .catch((error) => {
            void Axios.error(error, t("common.errors.generic"));
        });
    };
    const sendInvite = useCallback(() => {
        Axios.post(`/partner/applications/${signatory.application_id}/signatories/${signatory.signatory_id}/invite`)
            .then(() => {
            setSignatory(undefined);
            void message.success(t("common.messages.inviteSent"));
            void getRepresentatives();
        })
            .catch((error) => {
            void Axios.error(error, t("common.errors.sendInvitationFailed"));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatory.email, signatory.application_id, signatory.signatory_id]);
    return (_jsxs(Modal, Object.assign({ visible: !!signatory, title: t("dashboard.company_representatives.inviteToTeylor"), okText: t("common.button_label.sendInvite"), okButtonProps: { disabled: isEdit }, onOk: sendInvite, onCancel: () => {
            setSignatory(undefined);
            setIsEdit(false);
        }, destroyOnClose: true }, { children: [_jsx("div", Object.assign({ style: { marginBottom: 12 } }, { children: _jsx(Typography.Text, { children: t("dashboard.company_representatives.inviteUserByEmail", {
                        name: `${signatory === null || signatory === void 0 ? void 0 : signatory.first_name} ${signatory === null || signatory === void 0 ? void 0 : signatory.last_name}`,
                    }) }) })), isEdit ? (_jsx(Form, Object.assign({ form: emailForm, onFinish: updateEmail, preserve: false }, { children: _jsx(EmailFormItem, { label: "", initialValue: signatory.email, rules: [
                        {
                            required: true,
                            message: t("common.errors.fieldRequired"),
                        },
                    ], inputProps: {
                        addonAfter: (_jsxs(Space, { children: [_jsx(CloseOutlined, { onClick: () => setIsEdit(false), style: {
                                        paddingRight: 8,
                                        borderRight: "1px solid rgba(0,0,0,0.3)",
                                    } }), _jsx(CheckOutlined, { onClick: () => emailForm.submit() })] })),
                        style: { marginTop: -8 },
                    } }) }))) : (_jsxs(Space, Object.assign({ align: "center" }, { children: [_jsx(MailOutlined, { style: { color: "rgba(0,0,0,.45)" } }), _jsx(Typography.Text, Object.assign({ strong: true }, { children: signatory.email })), _jsx(EditOutlined, { onClick: () => {
                            setIsEdit(true);
                        }, style: { marginLeft: 10 } })] })))] })));
};
export default InviteUserModal;
