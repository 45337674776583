import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Handle, Position } from "reactflow";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ActiveNodeContext } from "./InnerRelationshipGraph";
const NodeContainer = styled.div `
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const IconWrapper = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colorPrimary};
	color: white;
	width: 35px;
	height: 35px;
	z-index: 1;
`;
const NodeLabel = styled.div `
	position: absolute;
	top: 100%;
	white-space: nowrap;
	color: #333;
	font-size: 12px;
	text-align: center;
	background-color: ${({ $isLabelActive }) => ($isLabelActive ? "white" : "initial")};
	font-weight: ${({ $isLabelActive }) => ($isLabelActive ? "bold" : "normal")};
`;
const StyledUserIcon = styled(UserOutlined) `
	font-size: 14px;
	color: white;
	padding: 5px;
`;
const StyledShopIcon = styled(ShopOutlined) `
	font-size: 14px;
	color: white;
	padding: 5px;
`;
export const Node = (props) => {
    const activeNodeId = useContext(ActiveNodeContext);
    const isLabelActive = activeNodeId === props.id;
    return (_jsxs(NodeContainer, { children: [_jsx(Handle, { type: "target", position: Position.Top, style: { width: 0, height: 0, background: "none", opacity: 0 } }), _jsx(IconWrapper, { children: props.data.type === "person" ? _jsx(StyledUserIcon, {}) : _jsx(StyledShopIcon, {}) }), _jsx(NodeLabel, Object.assign({ "$isLabelActive": isLabelActive }, { children: props.data.label })), _jsx(Handle, { type: "source", position: Position.Bottom, style: { width: 0, height: 0, background: "none", opacity: 0 } })] }));
};
