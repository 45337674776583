import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationUpdateConstraintsAllowedFieldsEnum, } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { AssigneeField } from "../../../../components/AssigneeField";
import { AkfFieldName, getCustomFieldConfig } from "../../../apply/akf";
import { CardEditKeys } from "../../ApplicationDrilldownPage";
import OverviewCard from "./OverviewCard";
const AkfCards = ({ application, setEditKey, editableFields }) => {
    const { t } = useTranslation();
    const { customFields } = useSelector((state) => state.configState);
    const { currency, decimalToPercent, localizedNumber } = useFormatter();
    const canUpdateCustomFields = !!editableFields.find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.CustomFields);
    const canUpdateLoanData = canUpdateCustomFields ||
        !!editableFields.find((f) => f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.LoanSize ||
            f.field === ApplicationUpdateConstraintsAllowedFieldsEnum.LoanDuration);
    const leasingData = useMemo(() => {
        return [
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.leasing_amount"),
                values: [`${currency(Number(application.loanSize), { currency: application.currency })}`],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.leasing_duration"),
                values: [`${application.loanDuration} ${t("common.months")}`],
            },
            {
                label: t("dashboard.loan_information.purpose"),
                values: [application.purposeDescription],
            },
            {
                label: t("dashboard.loan_information.account_manager"),
                values: [
                    _jsx(AssigneeField, { name: application.account_manager_name || t("dashboard.account_manager.unassigned"), assigneeId: application.account_manager_id, email: application.account_manager_email, editable: false }, application.account_manager_id),
                ],
            },
            {
                label: t("dashboard.loan_information.status_owner"),
                values: [
                    _jsx(AssigneeField, { name: application.status_owner_name || t("dashboard.account_manager.unassigned"), assigneeId: application.status_owner_id, email: application.status_owner_email, editable: false }, application.status_owner_id),
                ],
            },
        ];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application]);
    const leasingDetailsData = useMemo(() => {
        const insuranceRateCustomField = getCustomFieldConfig(customFields, AkfFieldName.insurance_rate, "pricing");
        const remainingValueCustomField = getCustomFieldConfig(customFields, AkfFieldName.remaining_value, "pricing");
        const leasingFactorCustomField = getCustomFieldConfig(customFields, AkfFieldName.leasing_factor, "pricing");
        return [
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.leasing_factor"),
                values: [
                    isValidNumericValue(application.pricing.leasing_factor)
                        ? localizedNumber({
                            value: application.pricing.leasing_factor,
                            decimalPlaces: leasingFactorCustomField === null || leasingFactorCustomField === void 0 ? void 0 : leasingFactorCustomField.ui.precision,
                        })
                        : "-",
                ],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.service_rate"),
                values: [`${application.pricing.service_rate} ${t("common.per_month")}`],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.remaining_value"),
                values: [
                    (isValidNumericValue(application.pricing.remaining_value) &&
                        decimalToPercent({
                            percent: application.pricing.remaining_value,
                            decimalPlaces: remainingValueCustomField === null || remainingValueCustomField === void 0 ? void 0 : remainingValueCustomField.ui.precision,
                            showPercentSymbol: true,
                        })) ||
                        "-",
                ],
            },
            {
                label: t("dashboard.custom_fields.purpose_akf_bike.insurance_rate"),
                values: [
                    (isValidNumericValue(application.pricing.insurance_rate) &&
                        decimalToPercent({
                            percent: application.pricing.insurance_rate,
                            decimalPlaces: insuranceRateCustomField === null || insuranceRateCustomField === void 0 ? void 0 : insuranceRateCustomField.ui.precision,
                            showPercentSymbol: true,
                        })) ||
                        "-",
                ],
            },
        ];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application, customFields]);
    const akfCustomFields = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        return [
            ...(((_a = application.custom_fields_data) === null || _a === void 0 ? void 0 : _a.values)
                ? [
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.is_public_tender"),
                        values: [
                            ((_c = (_b = application.custom_fields_data) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.is_public_tender)
                                ? t("common.yes").toUpperCase()
                                : t("common.no").toUpperCase(),
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.employer_rate_subsidy"),
                        values: [
                            `${currency(Number((_e = (_d = application.custom_fields_data) === null || _d === void 0 ? void 0 : _d.values) === null || _e === void 0 ? void 0 : _e.employer_rate_subsidy), {
                                currency: application.currency,
                            })}`,
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.employer_service_subsidy"),
                        values: [
                            `${currency(Number((_g = (_f = application.custom_fields_data) === null || _f === void 0 ? void 0 : _f.values) === null || _g === void 0 ? void 0 : _g.employer_service_subsidy), { currency: application.currency })}`,
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.max_bikes_per_employee"),
                        values: [(_j = (_h = application.custom_fields_data) === null || _h === void 0 ? void 0 : _h.values) === null || _j === void 0 ? void 0 : _j.max_bikes_per_employee],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.price_limit_bike"),
                        values: [
                            `${currency(Number((_l = (_k = application.custom_fields_data) === null || _k === void 0 ? void 0 : _k.values) === null || _l === void 0 ? void 0 : _l.price_limit_bike), {
                                currency: application.currency,
                            })}`,
                        ],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.number_of_employees"),
                        values: [(_o = (_m = application.custom_fields_data) === null || _m === void 0 ? void 0 : _m.values) === null || _o === void 0 ? void 0 : _o.number_of_employees],
                    },
                    {
                        label: t("dashboard.purpose_akf_bike.crm_id"),
                        values: [application.crm_id],
                    },
                    {
                        label: t("dashboard.custom_fields.purpose_akf_bike.group_id"),
                        values: [(_q = (_p = application.custom_fields_data) === null || _p === void 0 ? void 0 : _p.values) === null || _q === void 0 ? void 0 : _q.group_id],
                    },
                ]
                : []),
        ];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application.custom_fields_data]);
    return (_jsxs(_Fragment, { children: [_jsx(OverviewCard, { data: leasingData, cardTitle: t("dashboard.overview.leasing_information"), cardEditKey: CardEditKeys.LoanData, editHidden: !canUpdateLoanData, setEditKey: setEditKey }), _jsx(OverviewCard, { data: leasingDetailsData, cardTitle: t("dashboard.overview.leasing_details"), cardEditKey: CardEditKeys.LeasingDetails, editHidden: !canUpdateCustomFields, setEditKey: setEditKey }), _jsx(OverviewCard, { data: akfCustomFields, cardTitle: t("dashboard.overview.additional_application_fields"), cardEditKey: CardEditKeys.CustomFields, editHidden: !canUpdateCustomFields, setEditKey: setEditKey })] }));
};
export default AkfCards;
