import { AllBanksLoanPurposes } from "@teylor-tools/Api";
export const LOAN_DURATION = {
    min: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 6,
        [AllBanksLoanPurposes.ObjectFinancing]: 6,
        [AllBanksLoanPurposes.Other]: 6,
        [AllBanksLoanPurposes.Whitelabel]: 12,
        [AllBanksLoanPurposes.PurposeAkfBike]: 36,
        [AllBanksLoanPurposes.Material]: 12,
        [AllBanksLoanPurposes.Project]: 12,
        [AllBanksLoanPurposes.WorkingCapital]: 12,
    },
    max: {
        [AllBanksLoanPurposes.GeneralLiquidity]: 120,
        [AllBanksLoanPurposes.ObjectFinancing]: 240,
        [AllBanksLoanPurposes.Other]: 240,
        [AllBanksLoanPurposes.Whitelabel]: 60,
        [AllBanksLoanPurposes.PurposeAkfBike]: 48,
        [AllBanksLoanPurposes.Material]: 60,
        [AllBanksLoanPurposes.Project]: 60,
        [AllBanksLoanPurposes.WorkingCapital]: 60,
    },
};
