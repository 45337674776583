import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, Typography } from "antd";
import styled from "styled-components";
import { ApplicationStatuses } from "@teylor-tools/Api";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";
import { CardEditKeys } from "../../ApplicationDrilldownPage";
import { EditIcon, NotAvailableJsx } from "./OverviewComponents";
const { Text } = Typography;
const SetMarkupText = styled(Text) `
	color: ${(props) => props.theme.color_primary};
`;
export const getCustomPricing = ({ application, isIndicative, setEditKey, transactionFee, t, indicativeInterestRate, currency, decimalToPercent, }) => {
    var _a, _b, _c;
    const baseInterestRate = () => {
        if (isIndicative) {
            const rate = indicativeInterestRate(application.pricing);
            return rate ? `${rate} (${t("common.indicative")})` : _jsx(NotAvailableJsx, {});
        }
        if (isValidNumericValue(application.pricing.interest_rate)) {
            return decimalToPercent({
                percent: application.pricing.interest_rate,
                showPercentSymbol: true,
            });
        }
        return _jsx(NotAvailableJsx, {});
    };
    const showMarkupLabelRed = !isValidNumericValue(application.pricing.external_interest_rate) &&
        application.status === ApplicationStatuses.ExternalPricing;
    const customPricing = [
        { type: "divider" },
        {
            label: t("dashboard.loan_information.baseInterestRate"),
            values: [baseInterestRate()],
        },
        {
            label: showMarkupLabelRed ? (_jsx(Text, Object.assign({ type: "danger" }, { children: t("dashboard.loan_information.interestRateMarkup") }))) : (t("dashboard.loan_information.interestRateMarkup")),
            values: [
                isValidNumericValue((_a = application.pricing) === null || _a === void 0 ? void 0 : _a.external_interest_rate) ? (_jsxs(_Fragment, { children: [decimalToPercent({
                            percent: application.pricing.external_interest_rate,
                            showPercentSymbol: true,
                        }), application.status === ApplicationStatuses.ExternalPricing && (_jsx(EditIcon, { onClick: () => setEditKey(CardEditKeys.Pricing) }))] })) : application.status === ApplicationStatuses.ExternalPricing ? (_jsx(Button, Object.assign({ type: "link", style: { padding: 0, height: "auto" }, onClick: () => setEditKey(CardEditKeys.Pricing) }, { children: _jsx(SetMarkupText, Object.assign({ underline: true }, { children: t("common.button_label.setInterestRateMarkup") })) }))) : (_jsx(NotAvailableJsx, {})),
            ],
        },
        {
            label: t("dashboard.loan_information.finalInterestRate"),
            values: [
                isValidNumericValue(application.pricing.external_interest_rate) &&
                    isValidNumericValue((_b = application.pricing) === null || _b === void 0 ? void 0 : _b.total_interest_rate) ? (decimalToPercent({
                    percent: application.pricing.total_interest_rate,
                    showPercentSymbol: true,
                })) : (_jsx(NotAvailableJsx, {})),
            ],
        },
        { type: "divider" },
        {
            label: t("dashboard.loan_information.baseTransactionFee"),
            values: [isIndicative ? _jsx(NotAvailableJsx, {}) : transactionFee],
        },
        {
            label: showMarkupLabelRed ? (_jsx(Text, Object.assign({ type: "danger" }, { children: t("dashboard.loan_information.transactionFeeMarkup") }))) : (t("dashboard.loan_information.transactionFeeMarkup")),
            values: [
                isValidNumericValue((_c = application.pricing) === null || _c === void 0 ? void 0 : _c.external_transaction_fee) ? (_jsxs(_Fragment, { children: [currency(application.pricing.external_transaction_fee, {
                            currency: application.currency,
                        }), application.status === ApplicationStatuses.ExternalPricing && (_jsx(EditIcon, { onClick: () => setEditKey(CardEditKeys.Pricing) }))] })) : application.status === ApplicationStatuses.ExternalPricing ? (_jsx(Button, Object.assign({ type: "link", style: { padding: 0, height: "auto" }, onClick: () => setEditKey(CardEditKeys.Pricing) }, { children: _jsx(SetMarkupText, Object.assign({ underline: true }, { children: t("common.button_label.setTransactionFeeMarkup") })) }))) : (_jsx(NotAvailableJsx, {})),
            ],
        },
        {
            label: t("dashboard.loan_information.finalTransactionFee"),
            values: [
                isValidNumericValue(application.pricing.external_transaction_fee) &&
                    isValidNumericValue(application.pricing.total_transaction_fee) ? (currency(application.pricing.total_transaction_fee, {
                    currency: application.currency,
                })) : (_jsx(NotAvailableJsx, {})),
            ],
        },
        { type: "divider" },
    ];
    return customPricing;
};
