import { Divider as AntdDivider, Typography } from "antd";
import styled from "styled-components";
const { Title } = Typography;
export const CompanyInfoWrapper = styled.div `
	margin-top: 50px;
`;
export const CompanyInfoHeader = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 24px;
`;
export const Divider = styled(AntdDivider) `
	margin: 18px 0;
`;
export const StyledTitle = styled(Title) `
	line-height: 28px;
	margin: 0;
`;
export const CompanyInfoContent = styled.div `
	margin-top: 24px;
`;
export const CompanyInfoTitle = styled(Title) `
	font-size: 14px !important;
`;
export const GridContainer = styled.div `
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 40px;
`;
export const StyledGridColumn = styled.div `
	border-bottom: ${({ $addDivider }) => ($addDivider ? "1px solid #e8e8e8" : "none")};
`;
export const StyledGridColumnContent = styled.div `
	margin: 0 24px;
`;
